import { styled } from '@mui/joy';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';

export const Content = styled(Stack)({
  maxWidth: '400px',
  alignItems: 'center',
  textAlign: 'center',
});

export const TextWrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));

export const Title = styled(Typography)({
  whiteSpace: 'nowrap',
});
