import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import { AssignRoleRequestPayload, Permission } from '@/features/roles/types';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

// TODO rename to permissions api
export const rolesApi = createApi({
  reducerPath: 'rolesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/roles`,
  }),
  tagTypes: ['Roles', 'Role'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => {
    return {
      getRolePermissionsById: builder.query<Permission[], string>({
        query: id => ({
          url: `/${id}/permissions`,
        }),
      }),
      getRoleAvailablePermissionsById: builder.query<Permission[], string>({
        query: id => ({
          url: `/${id}/permissions/available`,
        }),
      }),
      assignRoleById: builder.mutation<void, AssignRoleRequestPayload>({
        query: ({ id, employeeIds }) => ({
          url: `/${id}/assign`,
          method: 'POST',
          data: { organizationId: id, employeeIds },
        }),
      }),
      suspendRoleById: builder.mutation<void, AssignRoleRequestPayload>({
        query: ({ id, employeeIds }) => ({
          url: `/${id}/suspend`,
          method: 'POST',
          data: { organizationId: id, employeeIds },
        }),
      }),
    };
  },
});

export const {
  useGetRolePermissionsByIdQuery,
  useGetRoleAvailablePermissionsByIdQuery,
  useAssignRoleByIdMutation,
  useSuspendRoleByIdMutation,
} = rolesApi;
