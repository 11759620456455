import { useEffect, useRef } from 'react';

import { useAuth as useOIDCAuth } from 'react-oidc-context';

import { useLazyGetUserByIdQuery } from '@/features/auth/authApi';

const useAuth = () => {
  const ref = useRef(false);
  const oidc = useOIDCAuth();
  const { user: session, signoutRedirect } = oidc;

  const userId = session?.profile?.sub;

  const [fetch, { data, error }] = useLazyGetUserByIdQuery();

  useEffect(() => {
    if (!ref.current && userId) {
      fetch(userId);
      ref.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  if (error) {
    signoutRedirect();
  }

  return {
    oidc,
    session,
    user: {
      ...data,
      fullName: [data?.lastName, data?.firstName, data?.middleName]
        .filter(Boolean)
        .join(' '),
    },
    logout: async () => {
      await signoutRedirect({
        state: window.location.pathname,
      });
    },
  };
};

export default useAuth;
