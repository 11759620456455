import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import {
  GasMeteringNodeActualExpenseIndicator,
  MeteringDeviceActualExpenseIndicator,
  MeteringPointActualExpenseCalculationModel,
  MeteringPointActualExpenseCalculationResultModel,
  MeteringPointActualExpenseCreateModel,
  MeteringPointActualExpenseIndicatorQueryModel,
  MeteringPointActualExpenseUpdateModel,
} from './types';

export const counterpartyActualExpenseIndicatorApi = createApi({
  reducerPath: 'counterpartyActualExpenseIndicatorApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/counterparty-actual-expense-indicators`,
  }),
  tagTypes: ['MeteringPointActualExpenseIndicators'],
  endpoints: builder => ({
    actualExpenseIndicators: builder.query<
      GasMeteringNodeActualExpenseIndicator[],
      MeteringPointActualExpenseIndicatorQueryModel
    >({
      query: data => ({
        url: '/query',
        method: 'POST',
        data,
      }),
      providesTags: () => ['MeteringPointActualExpenseIndicators'],
    }),
    actualExpenseIndicatorsCalculateResult: builder.mutation<
      MeteringPointActualExpenseCalculationResultModel,
      MeteringPointActualExpenseCalculationModel
    >({
      query: data => ({
        url: '/calculate-result',
        method: 'POST',
        data,
      }),
    }),
    actualExpenseIndicatorsCreateIndicator: builder.mutation<
      MeteringDeviceActualExpenseIndicator,
      MeteringPointActualExpenseCreateModel
    >({
      query: data => ({
        url: '/',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['MeteringPointActualExpenseIndicators'],
    }),
    actualExpenseIndicatorsUpdateIndicator: builder.mutation<
      MeteringDeviceActualExpenseIndicator,
      MeteringPointActualExpenseUpdateModel
    >({
      query: ({ id, ...data }) => ({
        url: `/${id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['MeteringPointActualExpenseIndicators'],
    }),
  }),
});

export const {
  useActualExpenseIndicatorsQuery,
  useActualExpenseIndicatorsCalculateResultMutation,
  useActualExpenseIndicatorsCreateIndicatorMutation,
  useActualExpenseIndicatorsUpdateIndicatorMutation,
} = counterpartyActualExpenseIndicatorApi;
