import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { Adjustment, MeteringPointAdjustment } from './types';

export const meteringPointAdjustmentApi = createApi({
  reducerPath: 'meteringPointAdjustmentApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/metering-point-adjustments`,
  }),
  tagTypes: ['MeteringPointAdjustment', 'Adjustment'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getMeteringPointAdjustment: builder.query<MeteringPointAdjustment, any>({
      query: data => ({
        url: '/query',
        method: 'POST',
        data,
      }),
      providesTags: () => ['Adjustment', 'MeteringPointAdjustment'],
    }),
    getMeteringPointAdjustmentById: builder.query<
      MeteringPointAdjustment,
      string
    >({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: () => ['Adjustment', 'MeteringPointAdjustment'],
    }),
    createMeteringPointAdjustment: builder.mutation<
      Adjustment,
      MeteringPointAdjustment
    >({
      query: data => ({
        url: '/',
        method: 'POST',
        data: data,
      }),
      invalidatesTags: result => [
        'MeteringPointAdjustment',
        { type: 'Adjustment', id: result.id },
      ],
    }),
    updateMeteringPointAdjustmentById: builder.mutation<Adjustment, Adjustment>(
      {
        query: data => ({
          url: `/${data.id}`,
          method: 'PUT',
          data: data,
        }),
        invalidatesTags: result => [
          'MeteringPointAdjustment',
          { type: 'Adjustment', id: result.id },
        ],
      },
    ),
    deleteMeteringPointAdjustmentById: builder.mutation<Adjustment, string>({
      query: id => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: result => [
        'MeteringPointAdjustment',
        { type: 'Adjustment', id: result.id },
      ],
    }),
  }),
});

export const {
  useGetMeteringPointAdjustmentQuery,
  useCreateMeteringPointAdjustmentMutation,
  useGetMeteringPointAdjustmentByIdQuery,
  useUpdateMeteringPointAdjustmentByIdMutation,
  useDeleteMeteringPointAdjustmentByIdMutation,
} = meteringPointAdjustmentApi;
