import { createContext, useContext } from 'react';

import { UserPermissionType } from '@components/Permissions/types';

type PermissionsContextType = {
  hasPermission: (permissionName: UserPermissionType) => boolean;
  permissions: string[];
};

export const PermissionsContext = createContext<PermissionsContextType | null>(
  null,
);

export const usePermissionsContext = () => {
  const context = useContext(PermissionsContext);
  if (!context) {
    throw new Error(
      'usePermissionsContext must be used within a PermissionsContext',
    );
  }
  return context;
};
