import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';
import { omit } from 'lodash-es';

import {
  CACHE_EXPIRATION_TIME,
  CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
} from '@/features/constants';
import {
  Organization,
  OrganizationBaseGridify,
  OrganizationInput,
  OrganizationReadModelGDN,
  ReferenceBook,
} from '@/features/organizations/types';
import { extendWithRoles } from '@/features/roles/utils';
import { FiltersGrid, Pagination } from '@/features/types';
import {
  infiniteQueryOptions,
  normalizeInfiniteQueryData,
} from '@/features/utils';
import normalizePayload from '@/forms/utils/normalizePayload';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

export const normalizeOrganizationPayloadBeforeMutation = (
  values: Organization,
) => {
  // @ts-ignore
  const payload: Partial<OrganizationInput> = omit(values, [
    'type',
    'classifier',
    'economicSector',
    'representative',
    'region',
  ]);
  payload.typeId = values.type.id;
  payload.classifierId = values.classifier?.id || null;
  payload.economicSectorId = values.economicSector?.id || null;

  // @ts-ignore
  delete payload.address;

  payload.representative = {
    departmentId: values.representative.departmentId,
    positionId: values.representative.positionId,
    firstName: values.representative.firstName,
    middleName: values.representative.middleName,
    lastName: values.representative.lastName,
    email: values.representative.email,
    phoneNumber: values.representative.phoneNumber,
    startDate: values.representative.startDate,
    endDate: values.representative.endDate,
  };

  if (values.representative.id) {
    payload.representative.id = values.representative.id;
  }

  // @ts-ignore
  payload.settlementId = values?.settlement?.id;
  // @ts-ignore
  payload.streetName = values?.street?.name || payload.streetName;
  // @ts-ignore
  payload.regionId = values?.settlement?.regionId || values.region?.id;

  return normalizePayload(payload);
};
const normalizeOrganizationResponse = (data: Organization) => {
  if (data?.certification.code === null) {
    data.certification.code = '';
  }
  if (data?.certification.date === null) {
    data.certification.date = '';
  }
  if (data?.representative?.email === null) {
    data.representative.email = '';
  }
  if (data?.representative?.phoneNumber === null) {
    data.representative.phoneNumber = '';
  }
  if (data?.details.logo === null) {
    data.details.logo = '';
  }
  return data;
};

interface ReferenceBooksRes {
  organizationTypes: ReferenceBook[];
  organizationClassifiers: ReferenceBook[];
  economicSectors: ReferenceBook[];
  departments: ReferenceBook[];
  employeePositions: ReferenceBook[];
  streetTypes: ReferenceBook[];
}

const api = createApi({
  reducerPath: 'organizationsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/organizations`,
  }),
  tagTypes: ['Organizations', 'Organization'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => {
    return {
      getReferenceBooks: builder.query<ReferenceBooksRes, void>({
        keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
        query: () => ({
          url: '/reference-books',
        }),
      }),
      getOrganizations: builder.infiniteQuery<
        OrganizationBaseGridify[],
        FiltersGrid,
        Pagination
      >({
        infiniteQueryOptions,
        query: ({ queryArg, pageParam }) => ({
          url: `/query`,
          params: { ...queryArg, ...pageParam },
        }),
        providesTags: () => ['Organizations'],
      }),
      getOrganizationsGdn: builder.query<OrganizationReadModelGDN[], void>({
        query: () => ({
          url: `/query/gdn`,
        }),
        providesTags: () => ['Organizations'],
      }),
      getOrganizationById: builder.query<Organization, string>({
        query: id => ({
          url: `/${id}`,
        }),
        transformResponse: normalizeOrganizationResponse,
        providesTags: result => [{ type: 'Organization', id: result?.id }],
      }),
      createOrganization: builder.mutation<Organization, Organization>({
        query: data => ({
          url: `/`,
          method: 'POST',
          data: normalizeOrganizationPayloadBeforeMutation(data),
        }),
        invalidatesTags: result => [
          'Organizations',
          { type: 'Organization', id: result.id },
        ],
      }),
      updateOrganizationById: builder.mutation<Organization, Organization>({
        query: data => ({
          url: `/${data.id}`,
          method: 'PUT',
          data: normalizeOrganizationPayloadBeforeMutation(data),
        }),
        invalidatesTags: result => [
          'Organizations',
          { type: 'Organization', id: result.id },
        ],
      }),
      deleteOrganizationById: builder.mutation<Organization, string>({
        query: id => ({
          url: `/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: (_, __, id) => [
          'Organizations',
          { type: 'Organization', id },
        ],
      }),
    };
  },
});

export const organizationsApi = extendWithRoles(api as any);

export const {
  useGetReferenceBooksQuery,
  useGetOrganizationsGdnQuery,
  useGetOrganizationByIdQuery,
  useCreateOrganizationMutation,
  useUpdateOrganizationByIdMutation,
} = api;

export const useGetOrganizationsInfiniteQuery = normalizeInfiniteQueryData(
  api.useGetOrganizationsInfiniteQuery,
);
