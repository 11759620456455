import { ElementType, ReactNode } from 'react';

import { usePageContext } from '@/providers/PageProvider';
import cutTitle from '@/utils/cutTitle';

import ArrowBack from '@mui/icons-material/ArrowBack';
import { Skeleton, Tooltip } from '@mui/joy';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';

import CopyText from '@components/ui/CopyText';

import HomeIcon from '@assets/home2-filled.svg?react';

import {
  Container,
  StyledPageTitle,
  StyledSkeleton,
  StyledSubtitle,
  SubtitleDivider,
  SvgIcon,
  Title,
} from './styled';

interface PageTitleProps {
  icon?: ElementType<any, keyof React.JSX.IntrinsicElements>;
  title: string | ReactNode;
  copyLabel?: string;
  copyText?: string;
  onArrowBack?: () => void;
  status?: ReactNode;
  subtitle?: string[];
}

const MAX_TITLE_LENGTH = 47;

const PageTitle = ({
  icon = HomeIcon,
  title,
  copyText,
  copyLabel,
  onArrowBack,
  status = null,
  subtitle,
}: PageTitleProps) => {
  const { loading } = usePageContext();
  const truncatTitle =
    typeof title === 'string' ? cutTitle(title, MAX_TITLE_LENGTH) : title;
  const isTitleTruncated =
    typeof title === 'string' && title.length > MAX_TITLE_LENGTH;
  return (
    <StyledPageTitle>
      <Container>
        {onArrowBack ? (
          <IconButton onClick={onArrowBack}>
            <ArrowBack
              sx={{
                color: theme => theme.palette.neutral[500],
              }}
            />
          </IconButton>
        ) : null}
        {icon ? (
          <Skeleton
            loading={!title && loading}
            variant='rectangular'
            sx={{ width: '24px', height: '24px', borderRadius: '4px' }}
          >
            <SvgIcon width={24} height={24} as={icon} />
          </Skeleton>
        ) : null}
        <Tooltip title={isTitleTruncated ? title : ''}>
          <Title>
            <Skeleton
              variant='text'
              loading={!title && loading}
              level='title-lg'
              sx={{ width: '392px', borderRadius: '20px' }}
            >
              {truncatTitle}
            </Skeleton>
          </Title>
        </Tooltip>

        {!loading && status}
        {!loading && !!copyText && (
          <CopyText label={copyLabel} value={copyText} />
        )}
      </Container>
      {!!subtitle &&
        (loading ? (
          <StyledSkeleton
            variant='text'
            level='body-xs'
            sx={{
              ml: 5,
              width: '95px',
              borderRadius: '20px',
            }}
          />
        ) : (
          <StyledSubtitle
            divider={
              <SubtitleDivider orientation='vertical' sx={{ height: '18px' }} />
            }
          >
            {subtitle?.map(
              (s, index) =>
                s && (
                  <Typography key={index} level='body-xs' fontWeight={500}>
                    {s}
                  </Typography>
                ),
            )}
          </StyledSubtitle>
        ))}
    </StyledPageTitle>
  );
};

export default PageTitle;
