import {
  Conditions,
  LogicalOperators,
} from '@/utils/buildFilterQuery/constants';
import {
  GridifySerialize,
  GridifyStrategy,
} from '@/utils/buildFilterQuery/types';
import { shouldSerializeArrayLikeFilterItem } from '@/utils/buildFilterQuery/utils';

export const isDateFilter: GridifyStrategy =
  shouldSerializeArrayLikeFilterItem('date');

type DateFilter = {
  date: string;
  gridifyType: 'date';
};

const formatDate = (date: DateFilter, isoString = false) => {
  if (!isoString) {
    return date.date;
  }
  const dateObject = new Date(date.date);
  dateObject.setHours(0, 0, 0, 0);
  return dateObject.toISOString();
};

const iValidDate = ({ date }: DateFilter) => {
  return date !== 'Invalid Date';
};

export const serializeDate: GridifySerialize = (
  filterId,
  filterItem,
  { isoString = false } = {},
) => {
  const [fromDate, toDate] = filterItem;
  const filter = [];

  if (fromDate?.date && iValidDate(fromDate)) {
    filter.push(
      `${filterId}${Conditions.GreaterThanOrEqual}${formatDate(
        fromDate,
        isoString,
      )}`,
    );
  }
  if (toDate?.date && iValidDate(toDate)) {
    filter.push(
      `${filterId}${Conditions.LessThanOrEqual}${formatDate(toDate, isoString)}`,
    );
  }

  return filter.join(LogicalOperators.AND);
};
