import { useRef, useState } from 'react';

import useAuth from '@/hooks/useAuth';
import useClickOutside from '@/hooks/useClickOutside';
import useConfirmDialog from '@/hooks/useConfirmPrompt';
import { useOrganizationContext } from '@/providers/OrganizationProvider';

import Button from '@mui/joy/Button';
import Chip from '@mui/joy/Chip';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';

import {
  Avatar,
  Box,
  Icon,
  LargeAvatar,
  OrgItem,
  Paper,
  Wrapper,
} from '@components/Header/components/UserPanel/styled';

const ProfileDropdown = () => {
  const ref = useRef(null);
  const auth = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const { isMainOffice } = useOrganizationContext();

  const { confirm, ConfirmDialog } = useConfirmDialog();

  const abbr = `${auth?.user?.lastName?.[0] || ''}${auth?.user?.firstName?.[0] || ''}`;

  useClickOutside(ref, () => setIsOpen(false));

  return (
    <>
      <Wrapper ref={ref}>
        <Button
          variant='plain'
          color='neutral'
          slots={{ root: Avatar }}
          sx={{ cursor: 'pointer', ml: 1 }}
          onClick={() => setIsOpen(v => !v)}
        >
          {abbr}
        </Button>
        {isOpen && (
          <Paper sx={{ gap: theme => theme.spacing(1) }}>
            <Box direction='row'>
              <LargeAvatar>{abbr}</LargeAvatar>
              <Stack>
                <Typography
                  level='body-sm'
                  fontWeight={600}
                  textColor='neutral.700'
                >
                  {auth.user.fullName}
                </Typography>
                <Typography
                  level='body-xs'
                  fontWeight={300}
                  textColor='neutral.700'
                >
                  {auth.user.email}
                </Typography>
                {isMainOffice && (
                  <Chip
                    color='primary'
                    variant='solid'
                    size='sm'
                    sx={{ mt: 1 }}
                  >
                    Головній офіс
                  </Chip>
                )}
              </Stack>
            </Box>
            <OrgItem
              variant='plain'
              color='neutral'
              onClick={() =>
                confirm(
                  () => {
                    setIsOpen(false);
                    auth.logout();
                  },
                  { submit: 'Вийти', cancel: 'Скасувати' },
                )
              }
            >
              <Icon />
              Вийти
            </OrgItem>
          </Paper>
        )}
      </Wrapper>
      <ConfirmDialog
        title='Ви впевненні, що хочете вийти з GASBI?'
        hideSubtitle
      />
    </>
  );
};

export default ProfileDropdown;
