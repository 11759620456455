import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import {
  ActGrid,
  DocumentsAndActsSlimReadModel,
} from '@/features/balanceActs/types';
import {
  CACHE_EXPIRATION_TIME,
  CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
} from '@/features/constants';
import { MeteringPointStatusHistory } from '@/features/meteringPoint/types';
import {
  infiniteQueryOptions,
  normalizeInfiniteQueryData,
} from '@/features/utils';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { FiltersGrid, Pagination } from '../types';
import {
  CommercialMeteringPoint,
  CommercialMeteringPointReferenceBooks,
  CommercialMeteringPointRequestPayload,
  CommercialMeteringPointViewModel,
  ConnectedPowerPayload,
} from './types';

export const commercialMeteringPointsApi = createApi({
  reducerPath: 'commercialMeteringPointsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/commercial-metering-points`,
  }),
  tagTypes: [
    'CommercialMeteringPoints',
    'CommercialMeteringPoint',
    'CommercialMeteringPointReferenceBooks',
  ],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => {
    return {
      getCommercialMeteringPoints: builder.query<
        CommercialMeteringPointViewModel[],
        FiltersGrid
      >({
        query: params => ({
          url: `/query`,
          params,
        }),
        providesTags: () => ['CommercialMeteringPoints'],
      }),
      getCommercialMeteringPointsI: builder.infiniteQuery<
        CommercialMeteringPointViewModel[],
        FiltersGrid,
        Pagination
      >({
        infiniteQueryOptions,
        query: ({ queryArg, pageParam }) => ({
          url: `/query`,
          params: { ...queryArg, ...pageParam },
        }),
        providesTags: () => ['CommercialMeteringPoints'],
      }),
      getCommercialMeteringPointById: builder.query<
        CommercialMeteringPoint,
        string
      >({
        query: id => ({
          url: `/${id}`,
        }),
        providesTags: (_, __, id) => [{ type: 'CommercialMeteringPoint', id }],
      }),
      createCommercialMeteringPoint: builder.mutation<
        CommercialMeteringPoint,
        CommercialMeteringPointRequestPayload
      >({
        query: data => ({
          url: ``,
          method: 'POST',
          data,
        }),
        invalidatesTags: result => [
          'CommercialMeteringPoints',
          { type: 'CommercialMeteringPoint', id: result.id },
        ],
      }),
      updateCommercialMeteringPoint: builder.mutation<
        CommercialMeteringPoint,
        CommercialMeteringPointRequestPayload
      >({
        query: data => ({
          url: `/${data.id}`,
          method: 'PUT',
          data,
        }),
        invalidatesTags: result => [
          'CommercialMeteringPoints',
          { type: 'CommercialMeteringPoint', id: result.id },
        ],
      }),
      updateCommercialMeteringPointConnectedPower: builder.mutation<
        void,
        ConnectedPowerPayload
      >({
        query: data => ({
          url: `/${data.id}/connected-power`,
          method: 'PUT',
          data,
        }),
        invalidatesTags: (_, __, data) => [
          'CommercialMeteringPoints',
          { type: 'CommercialMeteringPoint', id: data.id },
        ],
      }),
      deleteCommercialMeteringPoint: builder.mutation<
        CommercialMeteringPoint,
        string
      >({
        query: id => ({
          url: `/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: (_, __, id) => [
          'CommercialMeteringPoints',
          { type: 'CommercialMeteringPoint', id },
        ],
      }),
      getCommercialMeteringPointByIdStatusHistory: builder.query<
        MeteringPointStatusHistory[],
        string
      >({
        query: id => ({
          url: `/${id}/status-history`,
        }),
      }),
      getCommercialMeteringPointReferenceBooks: builder.query<
        CommercialMeteringPointReferenceBooks,
        void
      >({
        query: () => ({
          url: `/reference-books`,
        }),
        providesTags: () => ['CommercialMeteringPointReferenceBooks'],
        keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
      }),
      getCommercialMeteringPointDocumentsAndActs: builder.query<
        DocumentsAndActsSlimReadModel[],
        { id: string }
      >({
        query: ({ id }) => ({
          url: `/${id}/documents-and-acts/query`,
          method: 'POST',
          data: {},
        }),
        providesTags: (_, __, { id }) => [
          { type: 'CommercialMeteringPoint', id },
          'CommercialMeteringPoints',
        ],
      }),
      getCommercialMeteringPointDocumentsAndActsGridify: builder.query<
        ActGrid[],
        FiltersGrid
      >({
        query: params => ({
          url: `/documents-and-acts/query`,
          params,
        }),
        providesTags: () => ['CommercialMeteringPoints'],
      }),
      getCommercialMeteringPointDocumentsAndActsGridifyI: builder.infiniteQuery<
        ActGrid[],
        FiltersGrid,
        Pagination
      >({
        infiniteQueryOptions,
        query: ({ queryArg, pageParam }) => ({
          url: `/documents-and-acts/query`,
          params: { ...queryArg, ...pageParam },
        }),
        providesTags: () => ['CommercialMeteringPoints'],
      }),
    };
  },
});

export const {
  useGetCommercialMeteringPointsQuery,
  useLazyGetCommercialMeteringPointsQuery,
  useGetCommercialMeteringPointByIdQuery,
  useCreateCommercialMeteringPointMutation,
  useUpdateCommercialMeteringPointMutation,
  useDeleteCommercialMeteringPointMutation,
  useGetCommercialMeteringPointByIdStatusHistoryQuery,
  useUpdateCommercialMeteringPointConnectedPowerMutation,
  useGetCommercialMeteringPointReferenceBooksQuery,
  useGetCommercialMeteringPointDocumentsAndActsQuery,
  useGetCommercialMeteringPointDocumentsAndActsGridifyQuery,
  useLazyGetCommercialMeteringPointDocumentsAndActsGridifyQuery,
} = commercialMeteringPointsApi;

export const useGetCommercialMeteringPointsInfiniteQuery =
  normalizeInfiniteQueryData(
    commercialMeteringPointsApi.useGetCommercialMeteringPointsIInfiniteQuery,
  );

export const useGetCommercialMeteringPointDocumentsAndActsGridifyInfiniteQuery =
  normalizeInfiniteQueryData(
    commercialMeteringPointsApi.useGetCommercialMeteringPointDocumentsAndActsGridifyIInfiniteQuery,
  );
