import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import {
  SectorBridge,
  SectorBridgeFilters,
  SectorBridgeRequestPayload,
} from '@/features/sectorBridges/types';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

export const sectorBridgesApi = createApi({
  reducerPath: 'sectorBridgesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/sector-bridges`,
  }),
  tagTypes: ['SectorBridges', 'SectorBridge'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getSectorBridges: builder.query<SectorBridge[], SectorBridgeFilters | void>(
      {
        query: filters => ({
          url: `/query`,
          method: 'POST',
          data: filters || {},
        }),
        providesTags: () => ['SectorBridges'],
      },
    ),
    getSectorBridgeById: builder.query<SectorBridge, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: (_, __, id) => [{ type: 'SectorBridge', id }],
    }),
    createSectorBridge: builder.mutation<
      SectorBridge,
      SectorBridgeRequestPayload
    >({
      query: data => ({
        url: ``,
        method: 'POST',
        data,
      }),
      invalidatesTags: result => [
        'SectorBridges',
        { type: 'SectorBridge', id: result.id },
      ],
    }),
    updateSectorBridgeById: builder.mutation<
      SectorBridge,
      SectorBridgeRequestPayload
    >({
      query: data => ({
        url: `/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: result => [
        'SectorBridges',
        { type: 'SectorBridge', id: result.id },
      ],
    }),
    deleteSectorBridgeById: builder.mutation<SectorBridge, string>({
      query: id => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, id) => [
        'SectorBridges',
        { type: 'SectorBridge', id },
      ],
    }),
  }),
});

export const {
  useGetSectorBridgesQuery,
  useGetSectorBridgeByIdQuery,
  useLazyGetSectorBridgeByIdQuery,
  useCreateSectorBridgeMutation,
  useUpdateSectorBridgeByIdMutation,
  useDeleteSectorBridgeByIdMutation,
} = sectorBridgesApi;
