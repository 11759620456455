import { GridColDef } from '@mui/x-data-grid';

export const addDefaultValueGetter = (columns: GridColDef[]): GridColDef[] => {
  return columns.map(column => {
    const { valueGetter, renderCell, valueFormatter } = column;

    if (!valueGetter && !renderCell && !valueFormatter) {
      return {
        ...column,
        valueGetter: params => params || '-',
      };
    }

    return column;
  });
};
