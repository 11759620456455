import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME_REFERENCE_BOOK } from '@/features/constants';
import { ReferenceBook } from '@/features/organizations/types';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

export const sectorBridgeStatusesApi = createApi({
  reducerPath: 'sectorBridgeStatusesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/sector-bridge-statuses`,
  }),
  tagTypes: ['sectorBridgeStatuses', 'sectorBridgeStatus'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
  endpoints: builder => ({
    getSectorBridgeStatuses: builder.query<ReferenceBook[], void>({
      query: () => ({
        url: ``,
      }),
      providesTags: () => ['sectorBridgeStatuses'],
    }),
  }),
});

export const { useGetSectorBridgeStatusesQuery } = sectorBridgeStatusesApi;
