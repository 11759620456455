import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME_REFERENCE_BOOK } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { MeteringPointProjectReadModel } from './types';

export const meteringPointProjectsApi = createApi({
  reducerPath: 'meteringPointProjectsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/metering-point-projects`,
  }),
  keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
  tagTypes: ['MeteringPointProjects'],
  endpoints: builder => ({
    getMeteringPointProject: builder.query<
      MeteringPointProjectReadModel,
      string
    >({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: () => ['MeteringPointProjects'],
    }),
    createMeteringPointProject: builder.mutation<string, FormData>({
      query: data => ({
        url: '',
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data,
      }),
      invalidatesTags: () => ['MeteringPointProjects'],
    }),
    updateMeteringPointProject: builder.mutation<string, FormData>({
      query: data => ({
        url: `/${data.get('id')}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: () => ['MeteringPointProjects'],
    }),
  }),
});

export const {
  useGetMeteringPointProjectQuery,
  useCreateMeteringPointProjectMutation,
  useUpdateMeteringPointProjectMutation,
} = meteringPointProjectsApi;
