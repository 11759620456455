import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import {
  EquipmentType,
  EquipmentTypeFilters,
  EquipmentTypeRequestBody,
} from './types';

export const equipmentTypeApi = createApi({
  reducerPath: 'equipmentTypeApi',
  baseQuery: axiosBaseQuery({
    baseUrl: Config.VITE_API_URL + '/equipment-types',
  }),
  tagTypes: ['EquipmentType', 'EquipmentTypes'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getEquipmentTypes: builder.query<EquipmentType[], void>({
      query: () => ({
        url: '/',
      }),
      providesTags: () => ['EquipmentType', 'EquipmentTypes'],
    }),
    getEquipmentTypesWithFilters: builder.query<
      EquipmentType[],
      EquipmentTypeFilters
    >({
      query: data => {
        return {
          url: '/query',
          params: data || {},
        };
      },
      providesTags: () => ['EquipmentType', 'EquipmentTypes'],
    }),
    getEquipmentTypeById: builder.query<EquipmentType, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: () => ['EquipmentType'],
    }),
    createEquipmentType: builder.mutation<
      EquipmentType,
      EquipmentTypeRequestBody
    >({
      query: data => ({
        url: '/',
        method: 'POST',
        data,
      }),
      invalidatesTags: result => [
        'EquipmentType',
        { type: 'EquipmentType', id: result.id },
      ],
    }),
    updateEquipmentType: builder.mutation<
      EquipmentType,
      EquipmentTypeRequestBody
    >({
      query: data => ({
        url: `/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: result => [
        'EquipmentType',
        { type: 'EquipmentType', id: result.id },
      ],
    }),
  }),
});

export const {
  useGetEquipmentTypesQuery,
  useGetEquipmentTypesWithFiltersQuery,
} = equipmentTypeApi;
