import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME_REFERENCE_BOOK } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { FiltersGrid } from '../types';
import { MeteringDeviceType } from './types';

export const meteringDeviceTypeApi = createApi({
  reducerPath: 'meteringDeviceTypeApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/metering-device-types`,
  }),
  tagTypes: ['MeteringDeviceType'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
  endpoints: builder => ({
    getMeteringDeviceType: builder.query<MeteringDeviceType[], FiltersGrid>({
      query: (params = {}) => {
        return {
          url: `/query`,
          params,
        };
      },
    }),
    getMeteringDeviceTypeById: builder.query<MeteringDeviceType, string>({
      query: id => ({
        url: `/${id}`,
      }),
    }),
  }),
});

export const {
  useGetMeteringDeviceTypeQuery,
  useGetMeteringDeviceTypeByIdQuery,
} = meteringDeviceTypeApi;
