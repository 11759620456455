import {
  Conditions,
  LogicalOperators,
} from '@/utils/buildFilterQuery/constants';
import {
  GridifySerialize,
  GridifyStrategy,
} from '@/utils/buildFilterQuery/types';

import { ComparisonType } from '@components/Filter/FilterValueComparison';

const SerializeComparisonOperations: Record<
  ComparisonType,
  Conditions | 'Range'
> = {
  [ComparisonType.Less]: Conditions.LessThan,
  [ComparisonType.Equal]: Conditions.Equal,
  [ComparisonType.Greater]: Conditions.GreaterThan,
  [ComparisonType.Range]: 'Range',
};

export const isComparisonFilter: GridifyStrategy = filterItem =>
  filterItem.gridifyType === 'comparison';

export const serializeComparison: GridifySerialize = (filterId, filterItem) => {
  if (SerializeComparisonOperations[filterItem.operation] === 'Range') {
    const { min, max } = filterItem.range;

    return [
      `${filterId}${Conditions.GreaterThan}${min}`,
      `${filterId}${Conditions.LessThan}${max}`,
    ].join(LogicalOperators.AND);
  }

  return `${filterId}${SerializeComparisonOperations[filterItem.operation]}${filterItem.value}`;
};
