import { FC, ReactNode } from 'react';

import { styled } from '@mui/joy';
import Stack from '@mui/joy/Stack';

export const Box = styled(Stack)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'white',
  zIndex: 99,
}));

interface OverlayProps {
  children: ReactNode;
}

export const Overlay: FC<OverlayProps> = ({ children }) => {
  return <Box>{children}</Box>;
};
