import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';

import {
  OrganizationBaseGridify,
  OrganizationTypeId,
} from '@/features/organizations/types';
import OrganizationManager from '@/utils/organizationManager';

export const CONTEXT_ORGANIZATION_ID_CACHE_KEY =
  'CONTEXT_ORGANIZATION_ID_CACHE_KEY';

interface OrganizationContextState {
  organization: OrganizationBaseGridify;
}

export type OrganizationContextType = OrganizationContextState & {
  isMainOffice: boolean;
  changeOrganization: (v: string) => void;
  checkMainOffice: (v: OrganizationBaseGridify) => boolean;
};

const defaultValuesOrganizationContext: OrganizationContextType = {
  organization: undefined,
  changeOrganization: () => {},
  checkMainOffice: () => false,
  isMainOffice: false,
};

const OrganizationContext = createContext(defaultValuesOrganizationContext);

export const useOrganizationContext = () => useContext(OrganizationContext);

export const checkMainOffice = (org: OrganizationBaseGridify) =>
  org.typeId === OrganizationTypeId.MainOffice;

export const OrganizationProvider = ({
  value,
  children,
}: PropsWithChildren & { value?: OrganizationBaseGridify }) => {
  useEffect(() => {
    OrganizationManager.addListener(orgId => {
      localStorage.setItem(CONTEXT_ORGANIZATION_ID_CACHE_KEY, orgId);
    });
  }, []);

  useEffect(() => {
    if (OrganizationManager.getState() !== value.id) {
      OrganizationManager.setState(value.id);
    }
  }, [value.id]);

  const changeOrganization = useCallback((id: string) => {
    location.href = `/${id}`;
  }, []);

  const isMainOffice = useMemo(() => checkMainOffice(value), [value]);

  return (
    <OrganizationContext.Provider
      value={{
        organization: value,
        isMainOffice,
        changeOrganization,
        checkMainOffice,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};
