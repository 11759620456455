import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import {
  Employee,
  EmployeeByRoleGridify,
  EmployeeGridify,
  EmployeeRequestPayload,
} from '@/features/employees/types';
import { FiltersGrid, Pagination } from '@/features/types';
import {
  infiniteQueryOptions,
  normalizeInfiniteQueryData,
} from '@/features/utils';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

const normalizeEmployeeResponse = (data: Employee) => {
  if (data?.email === null) {
    data.email = '';
  }
  if (data?.phoneNumber === null) {
    data.phoneNumber = '';
  }
  if (data?.startDate === null) {
    data.startDate = '';
  }
  if (data?.endDate === null) {
    data.endDate = '';
  }
  return data;
};

export const employeesApi = createApi({
  reducerPath: 'employeesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/employees`,
  }),
  tagTypes: ['Employees', 'Employee', 'EmployeesByRole'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => {
    return {
      getEmployees: builder.query<
        EmployeeGridify[],
        FiltersGrid & {
          roleId?: string;
          organizationId?: string;
          areMembersOfStructuralSubdivisions?: boolean;
          actDate?: string;
        }
      >({
        query: params => ({
          url: `/query`,
          params,
        }),
        providesTags: () => ['Employees'],
      }),
      getEmployeesI: builder.infiniteQuery<
        EmployeeGridify[],
        FiltersGrid & {
          roleId?: string;
          organizationId?: string;
          areMembersOfStructuralSubdivisions?: boolean;
          actDate?: string;
        },
        Pagination
      >({
        infiniteQueryOptions,
        query: ({ queryArg, pageParam }) => ({
          url: `/query`,
          params: { ...queryArg, ...pageParam },
        }),
        providesTags: () => ['Employees'],
      }),
      getEmployeesByRole: builder.query<
        EmployeeByRoleGridify[],
        FiltersGrid & {
          organizationId: string;
          roleId?: string;
          name?: string;
        }
      >({
        query: params => ({
          url: `/by-role`,
          params,
        }),
        providesTags: () => ['EmployeesByRole'],
      }),
      getEmployeesAccessibleByOrganization: builder.query<
        EmployeeGridify[],
        FiltersGrid & {
          organizationId: string;
          name?: string;
        }
      >({
        query: params => ({
          url: `/accessible/for-organization`,
          params,
        }),
        providesTags: () => ['Employees'],
      }),
      getEmployeeById: builder.query<Employee, string>({
        query: id => ({
          url: `/${id}`,
        }),
        transformResponse: normalizeEmployeeResponse,
        providesTags: (_, __, id) => [{ type: 'Employee', id }],
      }),
      createEmployee: builder.mutation<Employee, EmployeeRequestPayload>({
        query: data => ({
          url: ``,
          method: 'POST',
          data,
        }),
        invalidatesTags: result => [
          'Employees',
          { type: 'Employee', id: result.id },
        ],
      }),
      updateEmployeeById: builder.mutation<Employee, EmployeeRequestPayload>({
        query: data => ({
          url: `/${data.id}`,
          method: 'PUT',
          data,
        }),
        invalidatesTags: result => [
          'Employees',
          { type: 'Employee', id: result.id },
        ],
      }),
      deleteEmployeeById: builder.mutation<Employee, string>({
        query: id => ({
          url: `/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: (_, __, id) => ['Employees', { type: 'Employee', id }],
      }),
      activateEmployeeById: builder.mutation<Employee, string>({
        query: id => ({
          url: `/${id}/send-activation-request`,
          method: 'POST',
        }),
        invalidatesTags: (_, __, id) => ['Employees', { type: 'Employee', id }],
      }),
      resetEmployeePasswordById: builder.mutation<Employee, string>({
        query: id => ({
          url: `/${id}/remove-password`,
          method: 'POST',
        }),
        invalidatesTags: (_, __, id) => ['Employees', { type: 'Employee', id }],
      }),
      deactivateEmployeeById: builder.mutation<Employee, string>({
        query: id => ({
          url: `/${id}/deactivate`,
          method: 'POST',
        }),
        invalidatesTags: (_, __, id) => ['Employees', { type: 'Employee', id }],
      }),
    };
  },
});

export const {
  useGetEmployeesQuery,
  useGetEmployeesByRoleQuery,
  useGetEmployeesAccessibleByOrganizationQuery,
  useGetEmployeeByIdQuery,
  useCreateEmployeeMutation,
  useUpdateEmployeeByIdMutation,
  useActivateEmployeeByIdMutation,
  useResetEmployeePasswordByIdMutation,
  useDeactivateEmployeeByIdMutation,
} = employeesApi;

export const useGetEmployeesInfiniteQuery = normalizeInfiniteQueryData(
  employeesApi.useGetEmployeesIInfiniteQuery,
);
