import { FC, PropsWithChildren, useMemo } from 'react';

import { usePermissionsContext } from '@/providers/PermissionProvider/hooks/usePermissionsContext';

import { Tooltip } from '@mui/joy';

import { UserPermissionType } from '@components/Permissions/types';

type RequirePermissionProps = {
  name: UserPermissionType | UserPermissionType[];
  hide?: boolean;
  skip?: boolean;
} & PropsWithChildren;

export const RequirePermission: FC<RequirePermissionProps> = ({
  name,
  hide = false,
  children,
  skip = false,
}) => {
  const { hasPermission } = usePermissionsContext();
  const isAllowed = useMemo(() => {
    if (skip) {
      return true;
    }

    if (Array.isArray(name)) {
      return name.some(n => hasPermission(n));
    }

    return hasPermission(name);
  }, [name, hasPermission, skip]);

  if (!skip && !isAllowed) {
    if (hide) {
      return null;
    }

    return (
      <Tooltip
        title='У вас немає прав на редагування цієї інформації. Зверніться до адміністратора'
        arrow
        sx={{
          maxWidth: '200px',
        }}
      >
        <span style={{ maxWidth: 'fit-content' }}>
          <div
            style={{
              pointerEvents: 'none',
              opacity: 0.3,
            }}
          >
            {children}
          </div>
        </span>
      </Tooltip>
    );
  }

  return <>{children}</>;
};
