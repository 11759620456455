import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import {
  MeteringPointOwnDemand,
  MeteringPointOwnDemandPayload,
  OwnDemand,
} from './types';

export const meteringPointOwnDemandApi = createApi({
  reducerPath: 'meteringPointOwnDemandApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/metering-point-own-demands`,
  }),
  tagTypes: ['MeteringPointOwnDemand', 'OwnDemand'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getMeteringPointOwnDemands: builder.query<
      MeteringPointOwnDemand,
      MeteringPointOwnDemandPayload
    >({
      query: data => ({
        url: '/query',
        method: 'POST',
        data,
      }),
      providesTags: () => ['OwnDemand', 'MeteringPointOwnDemand'],
    }),
    getMeteringPointOwnDemandById: builder.query<
      MeteringPointOwnDemand,
      string
    >({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: () => ['OwnDemand', 'MeteringPointOwnDemand'],
    }),
    createMeteringPointOwnDemand: builder.mutation<
      OwnDemand,
      MeteringPointOwnDemand
    >({
      query: data => ({
        url: '/',
        method: 'POST',
        data: data,
      }),
      invalidatesTags: result => [
        'MeteringPointOwnDemand',
        { type: 'OwnDemand', id: result.id },
      ],
    }),
    updateMeteringPointOwnDemandById: builder.mutation<OwnDemand, OwnDemand>({
      query: data => ({
        url: `/${data.id}`,
        method: 'PUT',
        data: data,
      }),
      invalidatesTags: result => [
        'MeteringPointOwnDemand',
        { type: 'OwnDemand', id: result.id },
      ],
    }),
    deleteMeteringPointOwnDemandById: builder.mutation<OwnDemand, string>({
      query: id => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: result => [
        'MeteringPointOwnDemand',
        { type: 'OwnDemand', id: result.id },
      ],
    }),
  }),
});

export const {
  useGetMeteringPointOwnDemandsQuery,
  useCreateMeteringPointOwnDemandMutation,
  useGetMeteringPointOwnDemandByIdQuery,
  useUpdateMeteringPointOwnDemandByIdMutation,
  useDeleteMeteringPointOwnDemandByIdMutation,
} = meteringPointOwnDemandApi;
