import { cleanMeteringPointActsStore } from '@/features/actsAndDocuments/actsAndDocumentsSlice';

import { cleanFilterStore } from '@components/Filter/useFilterStore';
import { cleanSearchTextStore } from '@components/Filter/useSearchTextFilterStore';

export const cleanAllStorages = () => {
  cleanFilterStore();
  cleanSearchTextStore();
  cleanMeteringPointActsStore();
  cleanMeteringPointActsStore();
};
