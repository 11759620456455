import { useMemo } from 'react';

import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { find } from 'lodash';

import { useGetCommercialMeteringPointOwnershipsQuery } from '@/features/commercialMeteringPointOwnerships/commercialMeteringPointOwnershipsApi';

dayjs.extend(isSameOrAfter);

export const useGetCounterpartAndContractByActDate = ({
  currentActDate,
  commercialMeteringPointId,
}) => {
  const { data: commercialMeteringPointOwnerships } =
    useGetCommercialMeteringPointOwnershipsQuery(
      { commercialMeteringPointId },
      { skip: !commercialMeteringPointId },
    );

  return useMemo(() => {
    if (!commercialMeteringPointOwnerships || !currentActDate) {
      return { contract: null, counterparty: null };
    }

    const ownership = find(commercialMeteringPointOwnerships, ({ startDate }) =>
      dayjs(currentActDate).isSameOrAfter(dayjs(startDate), 'day'),
    );

    return ownership
      ? {
          contract: ownership.contract,
          counterparty: ownership.counterparty,
        }
      : { contract: null, counterparty: null };
  }, [commercialMeteringPointOwnerships, currentActDate]);
};
