import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME_REFERENCE_BOOK } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { FiltersGrid } from '../types';
import { MeterThroughputs } from './types';

export const meterThroughputsApi = createApi({
  reducerPath: 'meterThroughputsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/meter-throughputs`,
  }),
  keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
  endpoints: builder => ({
    getMeterThroughputs: builder.query<MeterThroughputs[], FiltersGrid>({
      query: params => {
        return {
          url: `/query`,
          params,
        };
      },
    }),
  }),
});

export const { useGetMeterThroughputsQuery } = meterThroughputsApi;
