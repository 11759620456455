import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME_REFERENCE_BOOK } from '@/features/constants';
import { ReferenceBook } from '@/features/organizations/types';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

export const sectorBridgeActionsApi = createApi({
  reducerPath: 'sectorBridgeActionsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/sector-bridge-actions`,
  }),
  tagTypes: ['sectorBridgeActions', 'sectorBridgeAction'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
  endpoints: builder => ({
    getSectorBridgeActions: builder.query<ReferenceBook[], void>({
      query: () => ({
        url: ``,
      }),
      providesTags: () => ['sectorBridgeAction'],
    }),
  }),
});

export const { useGetSectorBridgeActionsQuery } = sectorBridgeActionsApi;
