import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';
import { omit } from 'lodash-es';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import {
  infiniteQueryOptions,
  normalizeInfiniteQueryData,
} from '@/features/utils';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { FiltersGrid, Pagination } from '../types';
import { Counterpart, CounterpartInput, CounterpartyViewModel } from './types';

const normalizeCounterpartPayloadBeforeMutation = (values: Counterpart) => {
  const payload: Partial<CounterpartInput> = omit(values, [
    'classifier',
    'economicSector',
    'legalAddress',
    'actualAddress',
  ]);
  payload.certificateNumber = values.certificateNumber || null;
  payload.mainCounterpartyId = values.hasSubdivisions
    ? null
    : values.mainCounterparty?.id || null;
  payload.classifierId = values.classifier.id;
  payload.economicSectorId = values.economicSector.id;
  payload.isResident = !values.nonResident;
  payload.contactPersons = values.contactPersons.map(person => ({
    id: person?.id,
    firstName: person.firstName,
    middleName: person.middleName,
    lastName: person.lastName,
    positionId: person.position.id,
    email: person?.email,
    phoneNumber: person.phoneNumber,
    isActive: true,
  }));

  // @ts-ignore
  payload.actualRegionId = values.actualAddress?.settlement?.regionId;
  payload.actualSettlementId = values.actualAddress?.settlement?.id;
  payload.actualStreetName = values.actualAddress?.street?.name;
  payload.actualBuildingNumber = values.actualAddress?.buildingNumber;
  payload.actualOfficeNumber = values.actualAddress?.officeNumber;

  // @ts-ignore
  payload.legalRegionId = values.legalAddress?.settlement?.regionId;
  payload.legalSettlementId = values.legalAddress?.settlement?.id;
  payload.legalStreetName = values.legalAddress?.street?.name;
  payload.legalBuildingNumber = values.legalAddress?.buildingNumber;
  payload.legalOfficeNumber = values.legalAddress?.officeNumber;

  return payload;
};

export const counterpartiesApi = createApi({
  reducerPath: 'counterpartsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/counterparties`,
  }),
  tagTypes: ['Counterparts', 'Counterpart'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => {
    return {
      getCounterparts: builder.query<CounterpartyViewModel[], FiltersGrid>({
        query: params => ({
          url: `/query`,
          params,
        }),
        providesTags: () => ['Counterparts'],
      }),
      getCounterpartsI: builder.infiniteQuery<
        CounterpartyViewModel[],
        FiltersGrid,
        Pagination
      >({
        infiniteQueryOptions,
        query: ({ queryArg, pageParam }) => ({
          url: `/query`,
          params: { ...queryArg, ...pageParam },
        }),
        providesTags: () => ['Counterparts'],
      }),
      getCounterpartById: builder.query<Counterpart, string>({
        query: id => ({
          url: `/${id}`,
        }),
        providesTags: (_, __, id) => [{ type: 'Counterpart', id }],
      }),
      createCounterpart: builder.mutation<string, Counterpart>({
        query: data => ({
          url: `/`,
          method: 'POST',
          data: normalizeCounterpartPayloadBeforeMutation(data),
        }),
        invalidatesTags: result => [
          'Counterparts',
          { type: 'Counterpart', id: result },
        ],
      }),
      updateCounterpartById: builder.mutation<string, Counterpart>({
        query: data => ({
          url: `/${data.id}`,
          method: 'PUT',
          data: normalizeCounterpartPayloadBeforeMutation(data),
        }),
        invalidatesTags: result => [
          'Counterparts',
          { type: 'Counterpart', id: result },
        ],
      }),
    };
  },
});

export const {
  useGetCounterpartsQuery,
  useCreateCounterpartMutation,
  useGetCounterpartByIdQuery,
  useUpdateCounterpartByIdMutation,
} = counterpartiesApi;

export const useGetCounterpartsIInfiniteQuery = normalizeInfiniteQueryData(
  counterpartiesApi.useGetCounterpartsIInfiniteQuery,
);
