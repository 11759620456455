import { FC } from 'react';

import SortAscIcon from '@/assets/sort-asc.svg?react';
import SortDescIcon from '@/assets/sort-desc.svg?react';

import { styled } from '@mui/joy';

import { SvgPlaceholder } from './GridStyled';

const CustomSortIcon = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

type SortIconDirection = 'asc' | 'desc';

const getSortIcon = (direction?: SortIconDirection) => {
  switch (direction) {
    case 'asc':
      return <SortAscIcon />;
    case 'desc':
      return <SortDescIcon />;
    default:
      return <SvgPlaceholder />;
  }
};

type ColumnHeaderSortIconProps = {
  direction?: SortIconDirection;
};
export const ColumnHeaderSortIcon: FC<ColumnHeaderSortIconProps> = ({
  direction,
}) => <CustomSortIcon>{getSortIcon(direction)}</CustomSortIcon>;
