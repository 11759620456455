import { useCallback, useEffect, useRef, useState } from 'react';

import { Stack, Typography } from '@mui/joy';
import { Tooltip } from '@mui/material';

const HeaderWithUnits = ({ text, unit }) => {
  const textRef = useRef<HTMLDivElement | null>(null);
  const [isOverflow, setIsOverflow] = useState(false);

  const checkOverflow = useCallback((element: HTMLDivElement | null) => {
    return element && element.scrollWidth > element.clientWidth;
  }, []);

  useEffect(() => {
    setIsOverflow(checkOverflow(textRef.current));
  }, [text, unit, checkOverflow]);

  return (
    <Tooltip title={`${text}, ${unit}`} disableHoverListener={!isOverflow}>
      <Stack direction='row' alignItems='baseline' sx={{ maxWidth: '100%' }}>
        <Typography
          ref={textRef}
          fontWeight={500}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {text}
        </Typography>
        <Typography fontWeight={600}>,</Typography>
        <Typography
          level='body-xs'
          sx={{
            color: 'neutral.600',
          }}
        >
          &nbsp;{unit}
        </Typography>
      </Stack>
    </Tooltip>
  );
};

export default HeaderWithUnits;
