import { createApi } from '@reduxjs/toolkit/query/react';

import {
  Permission,
  Role,
  RoleCreatePayload,
  RoleType,
  RoleUpdatePayload,
  RootRoleId,
} from '@/features/roles/types';

const roleTypeMap: Record<RootRoleId, RoleType> = {
  [RootRoleId.Administrator]: RoleType.Administrator,
  [RootRoleId.Manager]: RoleType.Manager,
  [RootRoleId.User]: RoleType.User,
};

export const getRoleType = (roleId: string) =>
  roleTypeMap[roleId] ?? RoleType.Custom;

const roleLabels: Record<RootRoleId, string> = {
  [RootRoleId.Administrator]: 'Адміністратор',
  [RootRoleId.Manager]: 'Менеджер',
  [RootRoleId.User]: 'Користувач',
};

export const getRoleLabel = (roleId: string) => roleLabels[roleId] ?? '';

export const isRootRole = (roleId?: string) =>
  Object.values<string>(RootRoleId).includes(roleId);

export const extendWithRoles = (api: ReturnType<typeof createApi>): any => {
  return api
    .enhanceEndpoints({
      addTagTypes: ['Roles', 'Role'],
    })
    .injectEndpoints({
      endpoints: builder => ({
        getOrganizationRolesSystem: builder.query<Role[], void>({
          query: () => ({
            url: `/roles/system`,
          }),
        }),
        getOrganizationRoles: builder.query<
          Role[],
          { id: string; search: string }
        >({
          query: ({ id, search = '' }) => ({
            url: `/${id}/roles/query`,
            method: 'POST',
            data: { search },
          }),
          providesTags: () => ['Roles'],
        }),
        getOrganizationRoleById: builder.query<
          Role,
          { id: string; roleId: string }
        >({
          query: ({ id, roleId }) => ({
            url: `/${id}/roles/${roleId}`,
          }),
          providesTags: (result: Role) => [{ type: 'Role', id: result?.id }],
        }),
        createOrganizationRole: builder.mutation<Role, RoleCreatePayload>({
          query: data => ({
            url: `/${data.organizationId}/roles`,
            method: 'POST',
            data,
          }),
          invalidatesTags: result => ['Roles', { type: 'Role', id: result.id }],
        }),
        updateOrganizationRoleById: builder.mutation<Role, RoleUpdatePayload>({
          query: data => ({
            url: `/${data.organizationId}/roles/${data.id}`,
            method: 'PUT',
            data,
          }),
          invalidatesTags: result => ['Roles', { type: 'Role', id: result.id }],
        }),
        getOrganizationPermissionsAvailable: builder.query<Permission, string>({
          query: id => ({
            url: `/${id}/permissions/available`,
          }),
        }),
        assignRole: builder.mutation<
          void,
          {
            id: string;
            organizationId: string;
            applyToAllEmployees?: boolean;
            employeeIds: string[];
          }
        >({
          query: ({ id, ...data }) => ({
            url: `/roles/${id}/assign`,
            method: 'POST',
            data,
          }),
        }),
        suspendRole: builder.mutation<
          void,
          {
            id: string;
            organizationId: string;
            applyToAllEmployees?: boolean;
            employeeIds: string[];
          }
        >({
          query: ({ id, ...data }) => ({
            url: `/roles/${id}/suspend`,
            method: 'POST',
            data,
          }),
        }),
      }),
    });
};
