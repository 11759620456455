import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { TechnicalCondition } from './types';

export const technicalConditionsApi = createApi({
  reducerPath: 'technicalConditionsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/technical-conditions-acts`,
  }),
  tagTypes: ['TechnicalCondition', 'TechnicalConditions'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getTechnicalConditions: builder.query<TechnicalCondition[], void>({
      query: () => ({
        url: '/query',
      }),
      providesTags: () => ['TechnicalCondition', 'TechnicalConditions'],
    }),
    getTechnicalConditionById: builder.query<TechnicalCondition, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: () => ['TechnicalCondition', 'TechnicalConditions'],
    }),
    createTechnicalCondition: builder.mutation<TechnicalCondition, FormData>({
      query: data => ({
        url: '/',
        method: 'POST',
        data: data,
      }),
      invalidatesTags: result => [
        'TechnicalCondition',
        { type: 'TechnicalCondition', id: result.id },
      ],
    }),
    updateTechnicalConditionById: builder.mutation<
      TechnicalCondition,
      FormData
    >({
      query: data => ({
        url: `/${data.get('id')}`,
        method: 'PUT',
        data: data,
      }),
      invalidatesTags: result => [
        'TechnicalCondition',
        { type: 'TechnicalCondition', id: result.id },
      ],
    }),
  }),
});

export const {
  useCreateTechnicalConditionMutation,
  useGetTechnicalConditionByIdQuery,
  useUpdateTechnicalConditionByIdMutation,
  useGetTechnicalConditionsQuery,
} = technicalConditionsApi;
