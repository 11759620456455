import { styled } from '@mui/material';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiIconButton from '@mui/material/IconButton';

export const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  margin: 0,
  padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
  height: '56px',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  paddingLeft: '24px',
  marginRight: '50px',
}));

export const IconButton = styled(MuiIconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.neutral['500'],
}));

export const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
}));
