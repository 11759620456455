import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import {
  infiniteQueryOptions,
  normalizeInfiniteQueryData,
} from '@/features/utils';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { FiltersGrid, Pagination } from '../types';
import {
  CommercialMeteringPointCodeRequestViewModel,
  CounterpartRequest,
} from './types';

export const counterpartRequestsApi = createApi({
  reducerPath: 'counterpartRequestsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/commercial-metering-point-code-requests`,
  }),
  tagTypes: ['CounterpartRequest', 'CounterpartRequests'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getCounterpartRequests: builder.query<
      CommercialMeteringPointCodeRequestViewModel[],
      FiltersGrid
    >({
      query: params => ({
        url: '/query',
        params,
      }),
      providesTags: () => ['CounterpartRequest', 'CounterpartRequests'],
    }),
    getCounterpartRequestsI: builder.infiniteQuery<
      CommercialMeteringPointCodeRequestViewModel[],
      FiltersGrid,
      Pagination
    >({
      infiniteQueryOptions,
      query: ({ queryArg, pageParam }) => ({
        url: '/query',
        params: { ...queryArg, ...pageParam },
      }),
      providesTags: () => ['CounterpartRequest', 'CounterpartRequests'],
    }),
    getCounterpartRequestById: builder.query<CounterpartRequest, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: () => ['CounterpartRequest', 'CounterpartRequests'],
    }),
    createCounterpartRequest: builder.mutation<
      CounterpartRequest,
      CounterpartRequest
    >({
      query: data => ({
        url: '/',
        method: 'POST',
        data: data,
      }),
      invalidatesTags: result => [
        'CounterpartRequest',
        { type: 'CounterpartRequest', id: result.id },
      ],
    }),
    updateCounterpartRequestById: builder.mutation<
      CounterpartRequest,
      CounterpartRequest
    >({
      query: data => ({
        url: `/${data.id}`,
        method: 'PUT',
        data: data,
      }),
      invalidatesTags: result => [
        'CounterpartRequest',
        { type: 'CounterpartRequest', id: result.id },
      ],
    }),
  }),
});

export const {
  useGetCounterpartRequestsQuery,
  useLazyGetCounterpartRequestsQuery,
  useCreateCounterpartRequestMutation,
  useGetCounterpartRequestByIdQuery,
  useUpdateCounterpartRequestByIdMutation,
} = counterpartRequestsApi;

export const useGetCounterpartRequestsIInfiniteQuery =
  normalizeInfiniteQueryData(
    counterpartRequestsApi.useGetCounterpartRequestsIInfiniteQuery,
  );
