import * as React from 'react';

import { NumericFormat, NumericFormatProps } from 'react-number-format';

type CustomProps = {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  min?: number;
  max?: number;
  decimalScale?: number;
};

export const NumericFormatCustom = React.forwardRef<
  NumericFormatProps,
  CustomProps
>(function NumericFormatCustom(props, ref) {
  const { onChange, min, max, name, decimalScale, ...other } = props;

  const isAllowed = ({ floatValue }) => {
    if (!!min && floatValue < min) {
      return false;
    }
    if (!!max && floatValue > max) {
      return false;
    }
    return true;
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === '-' && min >= 0) {
      event.preventDefault();
    }
  };

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: name,
            value: values.value,
          },
        });
      }}
      decimalSeparator=','
      isAllowed={isAllowed}
      decimalScale={decimalScale}
      valueIsNumericString
      onKeyDown={handleKeyDown}
    />
  );
});
