import {
  Conditions,
  LogicalOperators,
} from '@/utils/buildFilterQuery/constants';
import {
  GridifySerialize,
  GridifyStrategy,
} from '@/utils/buildFilterQuery/types';
import { shouldSerializeArrayLikeFilterItem } from '@/utils/buildFilterQuery/utils';

export const isRadioIsCommonFilter: GridifyStrategy =
  shouldSerializeArrayLikeFilterItem('radioIsCommon');

export const serializeRadioIsCommon: GridifySerialize = (
  filterId,
  filterItem,
) => {
  const hasNullValue = !!filterItem.find(item => item.value === null);
  if (hasNullValue) {
    return null;
  }

  return `${filterItem.map(item => `${filterId}${item.value ? Conditions.NotEqual : Conditions.Equal}null`).join(LogicalOperators.OR)}`;
};
