import { TIME_FORMAT, formatDate } from '@/forms/utils/formatDate';

import { StyledDataChip } from '@components/ActsAndDocumentsForm';

import ClickOutlined from '@assets/ClickOutlined.svg?react';

export const TimeChip = ({ startTime }) => (
  <StyledDataChip level='body-sm'>
    <ClickOutlined width='24px' height='24px' />
    {formatDate(startTime, TIME_FORMAT)}
  </StyledDataChip>
);
