import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { UserPermissionType } from '@components/Permissions/types';

export const permissionsApi = createApi({
  reducerPath: 'permissionsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: Config.VITE_API_URL + '/users',
  }),
  tagTypes: ['UserPermissions'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getUserPermissions: builder.query<UserPermissionType[], void>({
      query: () => ({
        url: `/permissions`,
      }),
      providesTags: ['UserPermissions'],
    }),
  }),
});

export const { useGetUserPermissionsQuery } = permissionsApi;
