import { useCallback } from 'react';

import { usePreserveBackgroundNavigate } from '@/hooks/usePreserveBackgroundNavigate';

export enum StepNavigationDirection {
  prev = -1,
  next = 1,
}

type UseStepNavigationProps<Steps> = {
  steps: Steps;
  stepViews: Record<keyof Steps, string>;
  currentStep: keyof Steps;
  setCurrentStep: (currentStep: keyof Steps) => void;
};

export const useStepNavigation = <Steps,>({
  steps,
  stepViews,
  currentStep,
  setCurrentStep,
}: UseStepNavigationProps<Steps>) => {
  const navigate = usePreserveBackgroundNavigate();

  return useCallback(
    (direction: StepNavigationDirection) => {
      const allSteps = Object.values(steps);
      const currentIndex = allSteps.indexOf(currentStep);

      if (~currentIndex) {
        const nextStep = allSteps[currentIndex + direction];
        setCurrentStep(nextStep);
        navigate(stepViews[nextStep]);
      }
    },
    [currentStep, navigate, setCurrentStep, stepViews, steps],
  );
};
