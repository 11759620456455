import React, { useEffect, useState } from 'react';

import useDebounce from '@/hooks/useDebounce';
import { GridifyFilterType } from '@/utils/buildFilterQuery/types';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ButtonGroup } from '@mui/joy';
import Input from '@mui/joy/Input';
import Option from '@mui/joy/Option';
import Select, { selectClasses } from '@mui/joy/Select';
import Stack from '@mui/joy/Stack';

import { NumericFormatCustom } from '@components/FormFields/InputNumericField/NumericFormatCustom';

interface FilterValueComparison {
  placeholder?: string;
  filterData?: any;
  onSelect?: any;
  setCleanUp?: any;
}

export enum ComparisonType {
  Equal = '0', // "="
  Less = '1', // "<"
  Greater = '2', // ">"
  Range = '3',
}

const ComparisonSymbol = {
  [ComparisonType.Less]: '<',
  [ComparisonType.Equal]: '=',
  [ComparisonType.Greater]: '>',
  [ComparisonType.Range]: '(a,b)',
};

const comparisonOptions = [
  { id: ComparisonType.Less, symbol: ComparisonSymbol[ComparisonType.Less] },
  { id: ComparisonType.Equal, symbol: ComparisonSymbol[ComparisonType.Equal] },
  {
    id: ComparisonType.Greater,
    symbol: ComparisonSymbol[ComparisonType.Greater],
  },
  {
    id: ComparisonType.Range,
    symbol: ComparisonSymbol[ComparisonType.Range],
  },
];

const slotProps = {
  input: {
    component: NumericFormatCustom,
    decimalScale: 2,
    // eslint-disable-next-line no-loss-of-precision
    min: -99999999999999.99,
    // eslint-disable-next-line no-loss-of-precision
    max: 99999999999999.99,
  },
};

const gridifyType: GridifyFilterType = 'comparison';

export const FilterValueComparison: React.FC<FilterValueComparison> = ({
  filterData,
  onSelect,
  setCleanUp,
}) => {
  const { filterId, placeholder } = filterData;

  const [operation, setOperation] = useState<ComparisonType>(
    ComparisonType.Equal,
  );
  const [value, setValue] = useState<string>('');
  const [min, setMin] = useState<string>('');

  const debouncedValue = useDebounce(value ?? null);
  const debouncedMin = useDebounce(min ?? null);

  useEffect(() => {
    let result = null;

    if (operation === ComparisonType.Range) {
      if (debouncedMin && debouncedValue) {
        result = {
          operation: +operation,
          range: { min: Number(debouncedMin), max: Number(debouncedValue) },
          gridifyType,
        };
      }
    } else {
      if (debouncedValue) {
        result = {
          operation: +operation,
          value: Number(debouncedValue),
          gridifyType,
        };
      }
    }

    onSelect?.(filterId, result, 'comparison');
  }, [filterId, onSelect, operation, debouncedValue, debouncedMin]);

  useEffect(() => {
    setCleanUp(() => {
      setValue('');
      setMin('');
      setOperation(ComparisonType.Equal);
    });
  }, [setCleanUp]);

  return (
    <Stack direction='row'>
      <ButtonGroup
        variant='outlined'
        sx={{
          width: '100%',
          '--ButtonGroup-separatorSize': '0.5px',
        }}
      >
        <Select
          sx={{
            padding: '0px 5px',
            margin: '0px',
            flex: 1.1,
            borderRight: 'none',
            borderRadius: ' 8px 0 0 8px ',
            '& .css-1hr8yhh-JoySelect-button': {
              display: 'block',
            },
            '&.Mui-expanded': {
              border: `1px solid var(--joy-palette-focusVisible);`,
            },
            [`& .${selectClasses.indicator}`]: {
              [`&.${selectClasses.expanded}`]: {
                transform: 'rotate(-180deg)',
              },
            },
          }}
          indicator={
            <ArrowDropDownIcon
              fontSize='small'
              sx={{ color: theme => theme.palette.neutral['500'] }}
            />
          }
          value={operation}
          variant='outlined'
          onChange={(_, value) => setOperation(value as ComparisonType)}
          slotProps={{
            listbox: {
              variant: 'plain',
              sx: {
                '--List-padding': '0px',
              },
            },
            button: {
              component: 'div',
              sx: {
                justifyContent: 'center',
                alignItems: 'center',
              },
            },
          }}
        >
          {comparisonOptions.map(option => (
            <Option key={option.id} value={option.id}>
              {option.symbol}
            </Option>
          ))}
        </Select>

        {operation === ComparisonType.Range && (
          <Input
            placeholder='Від'
            value={min}
            variant='outlined'
            onChange={event => setMin(event.target.value)}
            sx={{
              pl: 1,
              flex: 2,
            }}
            slotProps={slotProps}
          />
        )}
        <Input
          placeholder={operation === ComparisonType.Range ? 'До' : placeholder}
          value={value}
          onChange={event => setValue(event.target.value)}
          sx={{
            pl: 1,
            flex: 2,
            borderLeft: 'none',
            borderRadius: '8px',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
          variant='outlined'
          slotProps={slotProps}
        />
      </ButtonGroup>
    </Stack>
  );
};

export default FilterValueComparison;
