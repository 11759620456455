import { styled } from '@mui/joy';
import AppBar from '@mui/material/AppBar';

import UserPanel from './components/UserPanel';

export const HeaderWrapper = styled(AppBar)(({ theme }) => ({
  position: 'sticky',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingInline: theme.spacing(2),
  gap: theme.spacing(1),
  height: '56px',
  background: theme.palette.common.white,
  zIndex: 100,
  boxShadow: `0px 6px 12px -2px ${theme.palette.neutral[300]}`,
}));

const Header = () => {
  return (
    <HeaderWrapper>
      <UserPanel />
    </HeaderWrapper>
  );
};

export default Header;
