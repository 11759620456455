import { styled } from '@mui/joy';
import Stack from '@mui/joy/Stack';

export const StyledDateSection = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  flexWrap: 'nowrap',
  gap: theme.spacing(2),
  '& > div': {
    flexGrow: 1,
    width: '100%',
  },
}));
