import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME_REFERENCE_BOOK } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { ExportOperationalExpensesRequestBody } from './types';

export const reportsApi = createApi({
  reducerPath: 'reportsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/reports`,
  }),
  keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
  endpoints: builder => ({
    exportOperationalExpenses: builder.mutation<
      string,
      ExportOperationalExpensesRequestBody
    >({
      query: data => ({
        url: `/operational-expense/form2/export`,
        method: 'POST',
        data,
      }),
    }),
  }),
});

export const { useExportOperationalExpensesMutation } = reportsApi;
