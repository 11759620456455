import { lazy } from 'react';

import { RouteObject } from 'react-router-dom';

import { BalanceActFormStep } from './index';

const Information = lazy(() => import('./views/Information'));
const PipelineSections = lazy(() => import('./views/PipelineSections'));

export const BalanceActFormPaths: Record<
  keyof typeof BalanceActFormStep,
  string
> = {
  Information: '',
  PipelineSections: 'pipelineSections',
};

export const commercialMeteringPointBalanceActFormRoutes: RouteObject[] = [
  {
    element: <Information />,
    path: BalanceActFormPaths.Information,
    index: true,
  },
  {
    element: <PipelineSections />,
    path: BalanceActFormPaths.PipelineSections,
  },
];
