import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import {
  ActualIncomeDocumentReadModelSlim,
  DailyIncomesByDate,
  DailyIncomesByDateParams,
  MeteringPointActualIncomeAggregatedReadModel,
  MeteringPointActualIncomeQueryModel,
  MeteringPointActualIncomeValidateModel,
  MeteringPointActualIncomesDocument,
  MeteringPointActualIncomesDocumentsParams,
  MeteringPointDailyActualIncomeCreateModel,
  MeteringPointDailyActualIncomeQueryModel,
  MeteringPointDailyActualIncomeReadModel,
  MeteringPointDailyActualIncomeUpdateModel,
  MeteringPointDailyIncomes,
  MeteringPointsActualIncomeQueryModel,
  SaveDailyIncomesPayload,
  UpdateDailyIncomesPayload,
  VerifyResponse,
} from './types';

export const meteringPointActualIncomeApi = createApi({
  reducerPath: 'meteringPointActualIncomeApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/metering-point-actual-incomes`,
  }),
  tagTypes: ['Document', 'MeteringPoint', 'DailyIncomes', 'DailyIncome'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    queryByMeteringPoints: builder.query<
      ActualIncomeDocumentReadModelSlim[],
      MeteringPointsActualIncomeQueryModel
    >({
      query: model => ({
        url: '/query',
        method: 'POST',
        data: model,
      }),
    }),
    queryByMeteringPoint: builder.query<
      MeteringPointActualIncomeAggregatedReadModel,
      MeteringPointActualIncomeQueryModel
    >({
      query: model => ({
        url: '/metering-points/query',
        method: 'POST',
        data: model,
      }),
      providesTags: () => ['MeteringPoint'],
    }),
    getMeteringPointDailyActualIncome: builder.query<
      MeteringPointDailyActualIncomeReadModel,
      MeteringPointDailyActualIncomeQueryModel
    >({
      query: params => ({
        url: '/metering-points/daily-income',
        params,
      }),
    }),
    addMeteringPointDailyActualIncome: builder.mutation<
      string,
      MeteringPointDailyActualIncomeCreateModel
    >({
      query: params => ({
        url: '/metering-points/daily-income',
        method: 'POST',
        params,
      }),
    }),
    updateMeteringPointDailyActualIncome: builder.mutation<
      void,
      MeteringPointDailyActualIncomeUpdateModel
    >({
      query: ({ id, ...params }) => ({
        url: `/metering-points/daily-income/${id}`,
        method: 'PUT',
        params,
      }),
    }),
    validateActualIncome: builder.mutation<
      void,
      MeteringPointActualIncomeValidateModel
    >({
      query: data => ({
        url: '/validate',
        method: 'POST',
        data,
      }),
    }),
    getActualIncomeDocument: builder.query<
      MeteringPointActualIncomesDocument,
      MeteringPointActualIncomesDocumentsParams
    >({
      query: params => ({
        url: '/documents',
        method: 'GET',
        params,
      }),
      providesTags: ['Document'],
    }),
    getMeteringPointDailyIncomes: builder.query<
      MeteringPointDailyIncomes,
      MeteringPointActualIncomeQueryModel
    >({
      query: data => ({
        url: '/metering-points/query',
        method: 'POST',
        data,
      }),
      providesTags: ['DailyIncomes'],
    }),
    verifyActualIncome: builder.mutation<VerifyResponse, string>({
      query: id => ({
        url: `/documents/${id}/verify`,
        method: 'PUT',
      }),
      invalidatesTags: ['Document'],
    }),
    getDailyIncomesByDate: builder.query<
      DailyIncomesByDate,
      DailyIncomesByDateParams
    >({
      query: params => ({
        url: '/metering-points/daily-incomes',
        params,
      }),
      providesTags: ['DailyIncome'],
    }),
    saveDailyIncomes: builder.mutation<string, SaveDailyIncomesPayload>({
      query: data => ({
        url: '/metering-points/daily-incomes',
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'Document',
        'MeteringPoint',
        'DailyIncomes',
        'DailyIncome',
      ],
    }),
    updateDailyIncomes: builder.mutation<string, UpdateDailyIncomesPayload>({
      query: data => ({
        url: `/metering-points/daily-incomes/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: [
        'Document',
        'MeteringPoint',
        'DailyIncomes',
        'DailyIncome',
      ],
    }),
    fillByOperationalIncomes: builder.mutation<
      void,
      MeteringPointActualIncomeQueryModel
    >({
      query: data => ({
        url: `/metering-points/daily-incomes/fill-by-operational-incomes`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'Document',
        'MeteringPoint',
        'DailyIncomes',
        'DailyIncome',
      ],
    }),
    cleanDailyIncomes: builder.mutation<
      void,
      MeteringPointActualIncomeQueryModel
    >({
      query: data => ({
        url: `/metering-points/daily-incomes/clean`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'Document',
        'MeteringPoint',
        'DailyIncomes',
        'DailyIncome',
      ],
    }),
  }),
});

export const {
  useQueryByMeteringPointsQuery,
  useQueryByMeteringPointQuery,
  useGetMeteringPointDailyActualIncomeQuery,
  useAddMeteringPointDailyActualIncomeMutation,
  useUpdateMeteringPointDailyActualIncomeMutation,
  useValidateActualIncomeMutation,
  useGetActualIncomeDocumentQuery,
  useGetMeteringPointDailyIncomesQuery,
  useFillByOperationalIncomesMutation,
  useCleanDailyIncomesMutation,
  useVerifyActualIncomeMutation,
  useGetDailyIncomesByDateQuery,
  useSaveDailyIncomesMutation,
  useUpdateDailyIncomesMutation,
} = meteringPointActualIncomeApi;
