import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME_REFERENCE_BOOK } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { GasPipelineSectionType } from './types';

export const gasPipelineSectionTypesApi = createApi({
  reducerPath: 'gasPipelineSectionTypesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/gas-pipeline-section-types`,
  }),
  keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
  endpoints: builder => ({
    getGasPipelineSectionTypes: builder.query<GasPipelineSectionType[], void>({
      query: () => ({
        url: '/query',
        method: 'POST',
        data: {},
      }),
    }),
  }),
});

export const { useGetGasPipelineSectionTypesQuery } =
  gasPipelineSectionTypesApi;
