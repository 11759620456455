import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import {
  infiniteQueryOptions,
  normalizeInfiniteQueryData,
} from '@/features/utils';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { FiltersGrid, Pagination } from '../types';
import {
  GasDistributionStation,
  GasDistributionStationRequestBody,
  GasDistributionStationViewModel,
} from './types';

export const gasDistributionStationsApi = createApi({
  reducerPath: 'stationsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: Config.VITE_API_URL,
  }),
  tagTypes: ['GasDistributionStation', 'GasDistributionStations'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getGasDistributionStations: builder.query<
      GasDistributionStationViewModel[],
      FiltersGrid
    >({
      query: params => ({
        url: '/gas-distribution-stations/query',
        params,
      }),
      providesTags: () => ['GasDistributionStations', 'GasDistributionStation'],
    }),
    getGasDistributionStationsI: builder.infiniteQuery<
      GasDistributionStationViewModel[],
      FiltersGrid,
      Pagination
    >({
      infiniteQueryOptions,
      query: ({ queryArg, pageParam }) => ({
        url: '/gas-distribution-stations/query',
        params: { ...queryArg, ...pageParam },
      }),
      providesTags: () => ['GasDistributionStations', 'GasDistributionStation'],
    }),
    createGasDistributionStations: builder.mutation<
      GasDistributionStation,
      GasDistributionStationRequestBody
    >({
      query: data => ({
        url: '/gas-distribution-stations',
        method: 'POST',
        data,
      }),
      invalidatesTags: result => [
        'GasDistributionStation',
        { type: 'GasDistributionStation', id: result.id },
      ],
    }),
    updateGasDistributionStations: builder.mutation<
      GasDistributionStation,
      GasDistributionStationRequestBody
    >({
      query: data => ({
        url: `/gas-distribution-stations/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: result => [
        'GasDistributionStation',
        { type: 'GasDistributionStation', id: result.id },
      ],
    }),
    getGasDistributionStationById: builder.query<
      GasDistributionStation,
      string
    >({
      query: id => ({
        url: `/gas-distribution-stations/${id}`,
      }),
      providesTags: ['GasDistributionStations', 'GasDistributionStation'],
    }),
  }),
});

export const {
  useGetGasDistributionStationsQuery,
  useLazyGetGasDistributionStationsQuery,
  useLazyGetGasDistributionStationByIdQuery,
  useGetGasDistributionStationByIdQuery,
  useUpdateGasDistributionStationsMutation,
  useCreateGasDistributionStationsMutation,
} = gasDistributionStationsApi;

export const useGetGasDistributionStationsInfiniteQuery =
  normalizeInfiniteQueryData(
    gasDistributionStationsApi.useGetGasDistributionStationsIInfiniteQuery,
  );
