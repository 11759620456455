import { useCallback } from 'react';

import { styled } from '@mui/joy';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';

import { useActsAndDocumentsFormContext } from '@components/ActsAndDocumentsForm';

export const StyledPaddingContainer = styled(Box)`
  padding-right: ${({ theme }) => theme.spacing(2)};
  padding-left: ${({ theme }) => theme.spacing(2)};
`;

export const sx = {
  mt: 'auto',
  paddingTop: theme => theme.spacing(1),
  paddingBottom: theme => theme.spacing(1),
  borderTop: theme => `1px solid ${theme.palette.neutral[300]}`,
  display: 'flex',
  gap: theme => theme.spacing(2),
  justifyContent: 'flex-end',
};

export const ActFormFooter = () => {
  const {
    reset,
    isLastStep,
    moveToNextStep,
    isFirstStep,
    moveToPrevStep,
    validateStep,
    currentStep,
    submit,
    isSubmitting,
  } = useActsAndDocumentsFormContext();

  const nextStepTitle = isLastStep ? 'Готово' : 'Далі';

  const nextStepAction = isLastStep ? submit : moveToNextStep;

  const nextStepHandler = useCallback(async () => {
    const isValid = await validateStep(currentStep);
    if (isValid) {
      nextStepAction();
    }
  }, [currentStep, nextStepAction, validateStep]);

  return (
    <StyledPaddingContainer sx={sx}>
      <Button sx={{ mr: 'auto' }} variant='plain' onClick={() => reset()}>
        Скасувати
      </Button>
      {!isFirstStep && (
        <Button variant='plain' onClick={() => moveToPrevStep()}>
          Назад
        </Button>
      )}
      <Button disabled={isSubmitting} onClick={() => nextStepHandler()}>
        {nextStepTitle}
      </Button>
    </StyledPaddingContainer>
  );
};
