import {
  UserPermissionKeyType,
  UserPermissionType,
} from '@components/Permissions/types';

export const UserPermissions: Partial<
  Record<UserPermissionKeyType, UserPermissionType>
> = {
  // 1 Фактичний облік
  // 1.1 Фактична витрата
  ActualAccountingActualExpenseWrite: 'actualaccounting.actualexpense.write',
  ActualAccountingActualExpenseDelete: 'actualaccounting.actualexpense.delete',
  ActualAccountingActualExpenseRead: 'actualaccounting.actualexpense.read', // Done
  // 1.2 Фактичне надходження
  ActualAccountingActualIncomeWrite: 'actualaccounting.actualincome.write',
  ActualAccountingActualIncomeDelete: 'actualaccounting.actualincome.delete',
  ActualAccountingActualIncomeRead: 'actualaccounting.actualincome.read', // Done
  // 1.3 Річна замовлена потужність
  ActualAccountingAnnualOrderedCapacityWrite:
    'actualaccounting.annualorderedcapacity.write',
  ActualAccountingAnnualOrderedCapacityDelete:
    'actualaccounting.annualorderedcapacity.delete',
  ActualAccountingAnnualOrderedCapacityRead:
    'actualaccounting.annualorderedcapacity.read', // Done
  // 1.4 Нитка ГРС фізико хімічні показники (ФХП)
  ActualAccountingGasThreadPhysicoChemicalIndicatorWrite:
    'actualaccounting.gasthreadphysicochemicalindicator.write',
  ActualAccountingGasThreadPhysicoChemicalIndicatorDelete:
    'actualaccounting.gasthreadphysicochemicalindicator.delete',
  ActualAccountingGasThreadPhysicoChemicalIndicatorRead:
    'actualaccounting.gasthreadphysicochemicalindicator.read', // Done

  // 2 Адміністрування
  // 2.1 Співробітники
  AdministrationEmployeeManage: 'administration.employee.manage', // Done
  AdministrationEmployeeRead: 'administration.employee.read', // Done
  // 2.2 Організації
  AdministrationOrganizationManage: 'administration.organization.manage', // Done
  AdministrationOrganizationRead: 'administration.organization.read', // Done
  // 2.3 ReferenceBook
  AdministrationReferenceBookManage: 'administration.referencebook.manage', // Done
  AdministrationReferenceBookRead: 'administration.referencebook.read', // Done
  // 2.4 Ролі та дозвіли
  AdministrationRoleAndPermissionManage:
    'administration.roleandpermission.manage', // Done
  AdministrationRoleAndPermissionRead: 'administration.roleandpermission.read', // Done
  // 2.5 Структурні підрозділи
  AdministrationStructuralSubdivisionManage:
    'administration.structuralsubdivision.manage', // Done
  AdministrationStructuralSubdivisionRead:
    'administration.structuralsubdivision.read', // Done
  // 2.6 Користувачи
  AdministrationUserManage: 'administration.user.manage',
  AdministrationUserRead: 'administration.user.read',
  // 2.7 Групи Користувачів
  AdministrationUserGroupManage: 'administration.usergroup.manage',
  AdministrationUserGroupRead: 'administration.usergroup.read',

  // 3 Комерційні споживачі
  // 3.2 Комерційні точки обліку
  CommercialConsumersCommercialMeteringPointWrite:
    'commercialconsumers.commercialmeteringpoint.write', // Done
  CommercialConsumersCommercialMeteringPointDelete:
    'commercialconsumers.commercialmeteringpoint.delete',
  CommercialConsumersCommercialMeteringPointRead:
    'commercialconsumers.commercialmeteringpoint.read', // Done
  // 3.3 Комерційні точки обліку запит на код
  CommercialConsumersCommercialMeteringPointCodeRequestWrite:
    'commercialconsumers.commercialmeteringpointcoderequest.write',
  CommercialConsumersCommercialMeteringPointCodeRequestDelete:
    'commercialconsumers.commercialmeteringpointcoderequest.delete',
  CommercialConsumersCommercialMeteringPointCodeRequestRead:
    'commercialconsumers.commercialmeteringpointcoderequest.read',
  // 3.4 Комерційні точки обліку переміщення
  CommercialConsumersCommercialMeteringPointOwnershipWrite:
    'commercialconsumers.commercialmeteringpointownership.write', // Done
  CommercialConsumersCommercialMeteringPointOwnershipDelete:
    'commercialconsumers.commercialmeteringpointownership.delete',
  CommercialConsumersCommercialMeteringPointOwnershipRead:
    'commercialconsumers.commercialmeteringpointownership.read', // Done
  // 3.5 Розрахунок підключеної потужності
  CommercialConsumersConnectedPowerCalculationManage:
    'commercialconsumers.connectedpowercalculation.manage', // Done
  // 3.6 Договори
  CommercialConsumersContractWrite: 'commercialconsumers.contract.write', // Done
  CommercialConsumersContractDelete: 'commercialconsumers.contract.delete',
  CommercialConsumersContractRead: 'commercialconsumers.contract.read', // Done
  // 3.7 Контрагенти
  CommercialConsumersCounterpartyWrite:
    'commercialconsumers.counterparty.write', // Done
  CommercialConsumersCounterpartyDelete:
    'commercialconsumers.counterparty.delete',
  CommercialConsumersCounterpartyRead: 'commercialconsumers.counterparty.read', // Done
  // 3.8 Редагування коду ЕІС
  CommercialConsumersEICManage: 'commercialconsumers.eic.manage', // Done
  // 3.9 Газоспоживаюче обладнання
  CommercialConsumersGasConsumingEquipmentWrite:
    'commercialconsumers.gasconsumingequipment.write', // Done
  CommercialConsumersGasConsumingEquipmentDelete:
    'commercialconsumers.gasconsumingequipment.delete',
  CommercialConsumersGasConsumingEquipmentRead:
    'commercialconsumers.gasconsumingequipment.read', // Done
  // 3.10 Вузол обліку газу (ВОГ)
  CommercialConsumersGasMeteringNodeWrite:
    'commercialconsumers.gasmeteringnode.write', // Done
  CommercialConsumersGasMeteringNodeDelete:
    'commercialconsumers.gasmeteringnode.delete',
  CommercialConsumersGasMeteringNodeRead:
    'commercialconsumers.gasmeteringnode.read', // Done
  // 3.11 Ділянки газопроводу
  CommercialConsumersGasPipelineSectionWrite:
    'commercialconsumers.gaspipelinesection.write',
  CommercialConsumersGasPipelineSectionDelete:
    'commercialconsumers.gaspipelinesection.delete',
  CommercialConsumersGasPipelineSectionRead:
    'commercialconsumers.gaspipelinesection.read', // Done
  // 3.12 Прилади обліку
  CommercialConsumersMeteringDeviceWrite:
    'commercialconsumers.meteringdevice.write', // Done
  CommercialConsumersMeteringDeviceDelete:
    'commercialconsumers.meteringdevice.delete', // Done
  CommercialConsumersMeteringDeviceRead:
    'commercialconsumers.meteringdevice.read', // Done
  // 3.13 Акт по Точки обліку ГРС
  CommercialConsumersMeteringPointDocumentWrite:
    'commercialconsumers.meteringpointdocument.write', // Done
  CommercialConsumersMeteringPointDocumentDelete:
    'commercialconsumers.meteringpointdocument.delete', // Done
  CommercialConsumersMeteringPointDocumentRead:
    'commercialconsumers.meteringpointdocument.read', // Done
  // 3.14 Пломби
  CommercialConsumersSealWrite: 'commercialconsumers.seal.write', // Done
  CommercialConsumersSealDelete: 'commercialconsumers.seal.delete', // Done
  CommercialConsumersSealRead: 'commercialconsumers.seal.read', // Done
  // 3.15 Документи контрагента
  CommercialConsumersCounterpartyDocumentWrite:
    'commercialconsumers.counterpartydocument.write',
  CommercialConsumersCounterpartyDocumentRead:
    'commercialconsumers.counterpartydocument.read',

  // 4 ГРС
  // 4.1 Газоспоживаюче обладнання
  GasDistributionStationGasConsumingEquipmentWrite:
    'gasdistributionstation.gasconsumingequipment.write', // Done
  GasDistributionStationGasConsumingEquipmentDelete:
    'gasdistributionstation.gasconsumingequipment.delete',
  GasDistributionStationGasConsumingEquipmentRead:
    'gasdistributionstation.gasconsumingequipment.read', // Done
  // 4.2 Вузол обліку газу (ВОГ)
  GasDistributionStationGasMeteringNodeWrite:
    'gasdistributionstation.gasmeteringnode.write', // Done
  GasDistributionStationGasMeteringNodeDelete:
    'gasdistributionstation.gasmeteringnode.delete',
  GasDistributionStationGasMeteringNodeRead:
    'gasdistributionstation.gasmeteringnode.read', // Done
  // 4.3 Маршрути
  GasDistributionStationGasRouteWrite: 'gasdistributionstation.gasroute.write',
  GasDistributionStationGasRouteDelete:
    'gasdistributionstation.gasroute.delete',
  GasDistributionStationGasRouteRead: 'gasdistributionstation.gasroute.read', // Done
  // 4.4 ГРС
  GasDistributionStationGDSRead: 'gasdistributionstation.gds.read', // Done
  GasDistributionStationGDSDelete: 'gasdistributionstation.gds.delete',
  GasDistributionStationGDSWrite: 'gasdistributionstation.gds.write', // Done
  // 4.5 ГРС Проектна потужність (out of MVP)
  GasDistributionStationGDSDesignCapacityWrite:
    'gasdistributionstation.gdsdesigncapacity.write',
  GasDistributionStationGDSDesignCapacityDelete:
    'gasdistributionstation.gdsdesigncapacity.delete',
  GasDistributionStationGDSDesignCapacityRead:
    'gasdistributionstation.gdsdesigncapacity.read',
  // 4.6 Прилади обліку
  GasDistributionStationMeteringDeviceWrite:
    'gasdistributionstation.meteringdevice.write', // Done
  GasDistributionStationMeteringDeviceDelete:
    'gasdistributionstation.meteringdevice.delete', // Done
  GasDistributionStationMeteringDeviceRead:
    'gasdistributionstation.meteringdevice.read', // Done
  // 4.7 Точки обліку ГРС
  GasDistributionStationMeteringPointWrite:
    'gasdistributionstation.meteringpoint.write', // Done
  GasDistributionStationMeteringPointDelete:
    'gasdistributionstation.meteringpoint.delete',
  GasDistributionStationMeteringPointRead:
    'gasdistributionstation.meteringpoint.read', // Done
  // 4.8 Акт по Точки обліку ГРС
  GasDistributionStationMeteringPointActWrite:
    'gasdistributionstation.meteringpointact.write', // Done
  GasDistributionStationMeteringPointActDelete:
    'gasdistributionstation.meteringpointact.delete',
  GasDistributionStationMeteringPointActRead:
    'gasdistributionstation.meteringpointact.read', // Done
  // 4.9 Оперативний маршрут транспортування газу (Діючі маршрути)
  GasDistributionStationOperatingGasRouteWrite:
    'gasdistributionstation.operatinggasroute.write', // Done
  GasDistributionStationOperatingGasRouteDelete:
    'gasdistributionstation.operatinggasroute.delete',
  GasDistributionStationOperatingGasRouteRead:
    'gasdistributionstation.operatinggasroute.read', // Done
  // 4.10 Пломби
  GasDistributionStationSealWrite: 'gasdistributionstation.seal.write', // Done
  GasDistributionStationSealDelete: 'gasdistributionstation.seal.delete', // Done
  GasDistributionStationSealRead: 'gasdistributionstation.seal.read', // Done
  // 4.11 Сектори
  GasDistributionStationSectorWrite: 'gasdistributionstation.sector.write', // Done
  GasDistributionStationSectorDelete: 'gasdistributionstation.sector.delete',
  GasDistributionStationSectorRead: 'gasdistributionstation.sector.read', // Done
  // 4.12 Перемички
  GasDistributionStationSectorBridgeWrite:
    'gasdistributionstation.sectorbridge.write', // Done
  GasDistributionStationSectorBridgeDelete:
    'gasdistributionstation.sectorbridge.delete',
  GasDistributionStationSectorBridgeRead:
    'gasdistributionstation.sectorbridge.read', // Done
  // 4.13 Акти по Перемичкам
  GasDistributionStationSectorBridgeActWrite:
    'gasdistributionstation.sectorbridgeact.write', // Done
  GasDistributionStationSectorBridgeActDelete:
    'gasdistributionstation.sectorbridgeact.delete',
  GasDistributionStationSectorBridgeActRead:
    'gasdistributionstation.sectorbridgeact.read', // Done
  // 4.14 Нитки ГРС
  GasDistributionStationThreadWrite: 'gasdistributionstation.thread.write', // Done
  GasDistributionStationThreadDelete: 'gasdistributionstation.thread.delete',
  GasDistributionStationThreadRead: 'gasdistributionstation.thread.read', // Done

  // 5 Оперативний облік
  // 5.1 Споживачі без номінації
  OperationalAccountingConsumersWithoutNominationRead:
    'operationalaccounting.consumerswithoutnomination.read', // Done
  OperationalAccountingConsumersWithoutNominationWrite:
    'operationalaccounting.consumerswithoutnomination.write', // Done
  OperationalAccountingConsumersWithoutNominationDelete:
    'operationalaccounting.consumerswithoutnomination.delete',
  // 5.2 Експорт
  OperationalAccountingExportRead: 'operationalaccounting.export.read', // Done
  OperationalAccountingExportWrite: 'operationalaccounting.export.write',
  OperationalAccountingExportDelete: 'operationalaccounting.export.delete',
  // 5.3 Оперативна витрата
  OperationalAccountingOperationalExpenseRead:
    'operationalaccounting.operationalexpense.read', // Done
  OperationalAccountingOperationalExpenseWrite:
    'operationalaccounting.operationalexpense.write',
  OperationalAccountingOperationalExpenseDelete:
    'operationalaccounting.operationalexpense.delete',
  // 5.4 Оперативне надходження
  OperationalAccountingOperationalIncomeRead:
    'operationalaccounting.operationalincome.read', // Done
  OperationalAccountingOperationalIncomeWrite:
    'operationalaccounting.operationalincome.write',
  OperationalAccountingOperationalIncomeDelete:
    'operationalaccounting.operationalincome.delete',
};
