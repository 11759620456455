import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { ReferenceBook } from '@/features/organizations/types';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

export const projectKindsApi = createApi({
  reducerPath: 'projectKindsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/project-kinds`,
  }),
  tagTypes: ['ProjectKind'],
  endpoints: builder => ({
    getProjectKinds: builder.query<ReferenceBook[], void>({
      query: () => ({
        url: '',
      }),
      providesTags: ['ProjectKind'],
    }),
    getProjectKind: builder.query<ReferenceBook, string>({
      query: id => ({
        url: `/${id}`,
      }),
    }),
    createProjectKind: builder.mutation<void, ReferenceBook>({
      query: body => ({
        url: '',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ProjectKind'],
    }),
    updateProjectKind: builder.mutation<void, ReferenceBook>({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ProjectKind'],
    }),
    deleteProjectKind: builder.mutation<void, string>({
      query: id => ({
        url: `/project-kinds/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ProjectKind'],
    }),
  }),
});

export const {
  useGetProjectKindsQuery,
  useGetProjectKindQuery,
  useCreateProjectKindMutation,
  useUpdateProjectKindMutation,
  useDeleteProjectKindMutation,
} = projectKindsApi;
