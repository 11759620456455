import { useEffect } from 'react';

import { isNil } from 'lodash-es';

import { StepDataStorage } from '@components/ActsAndDocumentsForm/context/StepDataStorage';

const searchTextStore = new StepDataStorage(
  sessionStorage,
  'gsb_search_text_filter',
);

export const cleanSearchTextStore = () => searchTextStore.removeStoreItem();

const SEARCH_TEXT_FILTER_NAME = 'searchValue';

export const useSearchTextFilterStore = (
  filterName: string,
  searchValue?: string,
  searchOnChange?: (value: string) => void,
) => {
  useEffect(() => {
    if (isNil(searchOnChange)) {
      return;
    }

    const store = searchTextStore.getParsedStepsData();
    const savedFilters = store[filterName];

    if (savedFilters && savedFilters[SEARCH_TEXT_FILTER_NAME]) {
      searchOnChange(savedFilters[SEARCH_TEXT_FILTER_NAME]);
    }
  }, [filterName, searchOnChange]);

  useEffect(() => {
    if (isNil(searchValue)) {
      return;
    }

    const store = searchTextStore.getParsedStepsData();

    if (store[filterName]) {
      store[filterName][SEARCH_TEXT_FILTER_NAME] = searchValue;
    } else {
      store[filterName] = {
        [SEARCH_TEXT_FILTER_NAME]: searchValue,
      };
    }

    searchTextStore.setStoreItem(store);
  }, [filterName, searchValue]);
};
