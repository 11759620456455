import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import {
  CommercialMeteringPointTechnicalCalculation,
  CommercialMeteringPointTechnicalCalculationPayload,
  TechnicalCalculation,
} from './types';

export const commercialMeteringPointTechnicalCalculationApi = createApi({
  reducerPath: 'commercialMeteringPointTechnicalCalculationApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/counterparty-technical-calculations`,
  }),
  tagTypes: [
    'CommercialMeteringPointTechnicalCalculation',
    'TechnicalCalculation',
  ],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getCommercialMeteringPointTechnicalCalculations: builder.query<
      CommercialMeteringPointTechnicalCalculation,
      CommercialMeteringPointTechnicalCalculationPayload
    >({
      query: data => ({
        url: '/query',
        method: 'POST',
        data,
      }),
      providesTags: () => [
        'TechnicalCalculation',
        'CommercialMeteringPointTechnicalCalculation',
      ],
    }),
    getCommercialMeteringPointTechnicalCalculationById: builder.query<
      CommercialMeteringPointTechnicalCalculation,
      string
    >({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: () => [
        'TechnicalCalculation',
        'CommercialMeteringPointTechnicalCalculation',
      ],
    }),
    createCommercialMeteringPointTechnicalCalculation: builder.mutation<
      TechnicalCalculation,
      CommercialMeteringPointTechnicalCalculation
    >({
      query: data => ({
        url: '/',
        method: 'POST',
        data: data,
      }),
      invalidatesTags: result => [
        'CommercialMeteringPointTechnicalCalculation',
        { type: 'TechnicalCalculation', id: result.id },
      ],
    }),
    updateCommercialMeteringPointTechnicalCalculationById: builder.mutation<
      TechnicalCalculation,
      TechnicalCalculation
    >({
      query: data => ({
        url: `/${data.id}`,
        method: 'PUT',
        data: data,
      }),
      invalidatesTags: result => [
        'CommercialMeteringPointTechnicalCalculation',
        { type: 'TechnicalCalculation', id: result.id },
      ],
    }),
    deleteCommercialMeteringPointTechnicalCalculationById: builder.mutation<
      TechnicalCalculation,
      string
    >({
      query: id => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: result => [
        'CommercialMeteringPointTechnicalCalculation',
        { type: 'TechnicalCalculation', id: result.id },
      ],
    }),
  }),
});

export const {
  useGetCommercialMeteringPointTechnicalCalculationsQuery,
  useCreateCommercialMeteringPointTechnicalCalculationMutation,
  useGetCommercialMeteringPointTechnicalCalculationByIdQuery,
  useUpdateCommercialMeteringPointTechnicalCalculationByIdMutation,
  useDeleteCommercialMeteringPointTechnicalCalculationByIdMutation,
} = commercialMeteringPointTechnicalCalculationApi;
