import { useParams } from 'react-router-dom';

import { DocumentsSection } from '@/components/ActsAndDocumentsForm';
import { useFetchBalanceAct } from '@/components/ActsAndDocumentsForm/hooks/useFetchBalanceAct';
import PageTitle from '@/components/PageTitle';
import { useGetLossHistoryQuery } from '@/features/balanceActs';
import { useGetCommercialMeteringPointByIdQuery } from '@/features/commercialMeteringPoint/commercialMeteringPointsApi';
import { usePreserveBackgroundNavigate } from '@/hooks';
import { useBreadcrumbsNavConfig } from '@/pages/MeteringPointActDetails/hooks/useBreadcrumbsNavConfig';
import { useWatchPageLoading } from '@/providers/PageProvider';
import { getUseQueryHookState } from '@/utils';

import { CircularProgress, Stack } from '@mui/joy';

import { ActOwner } from '@components/ActsAndDocumentsForm';
import { DateChip } from '@components/ActsAndDocumentsForm';
import Breadcrumbs from '@components/Breadcrumbs';
import { PageDetailsContainer } from '@components/PageLayout/styled';
import { InfoBoxRow } from '@components/ui/InfoBox';

import BridgeActs from '@assets/BridgeActs.svg?react';

import { GeneralInformation } from './components/GeneralInformation';
import { PipelineSectionsTable } from './components/PipelineSectionsTable';

const CommercialMeteringPointBalanceActDetails = () => {
  const navigate = usePreserveBackgroundNavigate();
  const { navConfig } = useBreadcrumbsNavConfig();

  const { commercialMeteringPointId, balanceActId } = useParams<{
    commercialMeteringPointId?: string;
    balanceActId: string;
  }>();

  const { balanceAct, ...balanceActQuery } = useFetchBalanceAct();
  const {
    data: { records: lossHistory, technologicalLossErrorMessage } = {},
    ...lossHistoryQuery
  } = useGetLossHistoryQuery(balanceActId);

  const { data: commercialMeteringPoint, ...commercialMeteringPointByIdQuery } =
    useGetCommercialMeteringPointByIdQuery(commercialMeteringPointId);

  const isLoading = getUseQueryHookState('isLoading', [
    commercialMeteringPointByIdQuery,
    balanceActQuery,
    lossHistoryQuery,
  ]);

  const isError = getUseQueryHookState('isError', [
    commercialMeteringPointByIdQuery,
    balanceActQuery,
    lossHistoryQuery,
  ]);

  const maxGasConsumption = lossHistory?.[0]?.maxDailyTechnologicalLoss ?? 0;

  useWatchPageLoading(isLoading);

  if (isError) {
    return <div>Помилка завантаження</div>;
  }

  if (isLoading || !balanceAct) {
    return (
      <Stack alignItems='center' justifyContent='center' height='100vh'>
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <PageDetailsContainer>
      <Breadcrumbs nav={navConfig} />

      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='flex-start'
      >
        <PageTitle
          title={`Акт балансової належності №${balanceAct.number}`}
          status={<DateChip startDate={balanceAct.date} />}
          icon={BridgeActs}
          onArrowBack={() => navigate('../..', { relative: 'path' })}
        />
      </Stack>
      <InfoBoxRow alignItems='stretch' marginBottom={1}>
        <GeneralInformation
          maxGasConsumption={maxGasConsumption}
          isLoading={isLoading}
          lossHistory={lossHistory}
          technologicalLossErrorMessage={technologicalLossErrorMessage}
        />
        <ActOwner
          isLoading={isLoading}
          meteringPoint={commercialMeteringPoint}
        />
      </InfoBoxRow>
      <InfoBoxRow marginBottom={1}>
        <DocumentsSection
          isLoading={isLoading}
          documents={balanceAct.documents}
        />
      </InfoBoxRow>
      <PipelineSectionsTable
        sections={balanceAct.gasPipelineSections}
        isLoading={isLoading}
      />
    </PageDetailsContainer>
  );
};

export default CommercialMeteringPointBalanceActDetails;
