import OrganizationsDropdown from '@components/Header/components/UserPanel/components/OrganizationsDropdown';
import ProfileDropdown from '@components/Header/components/UserPanel/components/ProfileDropdown';
import { Container } from '@components/Header/components/UserPanel/styled';

const UserPanel = () => {
  return (
    <Container>
      <OrganizationsDropdown />
      <ProfileDropdown />
    </Container>
  );
};

export default UserPanel;
