import LogoutIcon from '@mui/icons-material/Logout';
import { styled } from '@mui/joy';
import MuiAvatar from '@mui/joy/Avatar';
import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';

export const Avatar = styled(MuiAvatar)(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
  fontWeight: 500,
  fontSize: theme.fontSize.xs,
  background: theme.palette.primary[500],
  color: theme.palette.common.white,
  '&:hover': {
    background: theme.palette.primary[600],
  },
}));

export const LargeAvatar = styled(Avatar)(({ theme }) => ({
  width: 60,
  height: 60,
  fontSize: theme.fontSize.md,
}));

export const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '0 8px',
});

export const Box = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  background: theme.palette.neutral[100],
}));

export const Icon = styled(LogoutIcon)(({ theme }) => ({
  width: 20,
  height: 20,
  fill: theme.palette.neutral[500],
}));

export const Wrapper = styled(Stack)({
  position: 'relative',
});

export const Paper = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  top: `calc(100% + ${theme.spacing(1)})`,
  right: 0,
  width: '430px',
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  background: theme.palette.common.white,
  boxShadow:
    'var(--joy-shadowRing, 0 0 #000),0px 2px 8px -2px rgba(var(--joy-shadowChannel, 21 21 21) / var(--joy-shadowOpacity, 0.08)),0px 6px 12px -2px rgba(var(--joy-shadowChannel, 21 21 21) / var(--joy-shadowOpacity, 0.08))',
}));

export const OrgItem = styled(Button, {
  shouldForwardProp: prop => prop !== 'active',
})(({ theme }) => ({
  padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
  gap: theme.spacing(1),
  borderRadius: '6px',
  justifyContent: 'flex-start',
  cursor: 'pointer',
  fontWeight: 400,
}));
