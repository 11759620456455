import { useCallback } from 'react';

import {
  NavigateOptions,
  To,
  useLocation,
  useNavigate,
} from 'react-router-dom';

export const usePreserveBackgroundNavigate = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return useCallback(
    (to: To, options?: NavigateOptions) => {
      const currentBackground = (location.state as any)?.backgroundLocation;

      const mergedState = {
        ...(options?.state || {}),
      };

      if (currentBackground) {
        mergedState.backgroundLocation = currentBackground;
      }

      navigate(to, {
        ...options,
        state: mergedState,
      });
    },
    [location, navigate],
  );
};
