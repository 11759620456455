export enum LogicalOperators {
  OR = '|',
  AND = ',',
}

/**
 * Equal	=	"FieldName = Value"
 * NotEqual	!=	"FieldName !=Value"
 * LessThan	<	"FieldName < Value"
 * GreaterThan	>	"FieldName > Value"
 * GreaterThanOrEqual	>=	"FieldName >=Value"
 * LessThanOrEqual	<=	"FieldName <=Value"
 * Contains - Like	=*	"FieldName =*Value"
 * NotContains - NotLike	!*	"FieldName !*Value"
 * StartsWith	^	"FieldName ^ Value"
 * NotStartsWith	!^	"FieldName !^ Value"
 * EndsWith	$	"FieldName $ Value"
 * NotEndsWith	!$	"FieldName !$ Value"
 */

export enum Conditions {
  Equal = '=',
  NotEqual = '!=',
  LessThan = '<',
  GreaterThan = '>',
  GreaterThanOrEqual = '>=',
  LessThanOrEqual = '<=',
  ContainsLike = '=*',
  NotContains = '!*',
  StartsWith = '^',
  NotStartsWith = '!^',
  EndsWith = '$',
  NotEndsWith = '!$',
}
