import { isArray, isEmpty } from 'lodash-es';

import {
  GridifyFilterType,
  GridifyStrategy,
} from '@/utils/buildFilterQuery/types';

export const hasFilterItem = ([, filterItem]: [string, any]): boolean =>
  typeof filterItem === 'boolean' || !isEmpty(filterItem) || !!filterItem;

export const shouldSerializeArrayLikeFilterItem =
  (type: GridifyFilterType): GridifyStrategy =>
  filterItem =>
    isArray(filterItem) &&
    !!filterItem.findLast(el => el?.gridifyType === type);
