import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME_REFERENCE_BOOK } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import {
  AnnualOrderedCapacity,
  AnnualOrderedCapacityCalculateByContractFiler,
  AnnualOrderedCapacityCounterpartiesParams,
  AnnualOrderedCapacityCounterparty,
  AnnualOrderedCapacityPayload,
  AnnualOrderedCapacityQuery,
  AnnualOrderedCapacityUpdateByApplicationPayload,
  CalculateAnnualOrderedCapacityByOrganizationFilter,
  CalculateAnnualOrderedCapacityFiler,
  CapacityType,
  GetAnnualOrderedCapacitiesQueryPayload,
} from './types';

export const annualOrderedCapacitiesApi = createApi({
  reducerPath: 'annualOrderedCapacitiesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/annual-ordered-capacities`,
  }),
  keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
  tagTypes: [
    'AnnualOrderedCapacityQuery',
    'CapacityType',
    'AnnualOrderedCapacity',
    'AnnualOrderedCapacityCounterparties',
  ],
  endpoints: builder => ({
    getAnnualOrderedCapacities: builder.query<
      { items: AnnualOrderedCapacityQuery[]; inProgress: boolean },
      GetAnnualOrderedCapacitiesQueryPayload
    >({
      query: data => ({
        url: '/query',
        method: 'POST',
        data,
      }),
      providesTags: () => ['AnnualOrderedCapacityQuery'],
    }),
    getOrderedCapacityTypes: builder.query<CapacityType[], void>({
      query: () => ({
        url: '/types',
      }),
    }),
    getAnnualOrderedCapacityById: builder.query<AnnualOrderedCapacity, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: () => ['AnnualOrderedCapacity'],
    }),
    deleteAnnualOrderedCapacityById: builder.mutation<void, string>({
      query: id => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AnnualOrderedCapacityQuery'],
    }),
    annualOrderedCapacityRecalculateSingle: builder.mutation<
      string,
      CalculateAnnualOrderedCapacityFiler
    >({
      query: data => ({
        url: '/recalculate-single',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['AnnualOrderedCapacity'],
    }),
    updateByApplication: builder.mutation<
      string,
      AnnualOrderedCapacityUpdateByApplicationPayload
    >({
      query: data => ({
        url: '/update-by-application',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['AnnualOrderedCapacity'],
    }),
    annualOrderedCapacityCalculateByContract: builder.mutation<
      string,
      AnnualOrderedCapacityCalculateByContractFiler
    >({
      query: data => ({
        url: '/calculate-by-contract',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['AnnualOrderedCapacityQuery'],
    }),
    calculateByApplication: builder.mutation<any, AnnualOrderedCapacityPayload>(
      {
        query: data => ({
          url: `/calculate-by-application`,
          method: 'POST',
          data,
        }),
        invalidatesTags: ['AnnualOrderedCapacity'],
      },
    ),
    calculateAnnualOrderedCapacityByOrganization: builder.mutation<
      void,
      CalculateAnnualOrderedCapacityByOrganizationFilter
    >({
      query: data => ({
        url: '/calculate-by-organization',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['AnnualOrderedCapacity'],
    }),
    getAnnualOrderedCapacityCounterparties: builder.query<
      AnnualOrderedCapacityCounterparty[],
      AnnualOrderedCapacityCounterpartiesParams | void
    >({
      query: params => ({
        url: '/counterparty-filters',
        method: 'GET',
        params,
      }),
      providesTags: () => ['AnnualOrderedCapacityCounterparties'],
    }),
    isExistsCalculationByOrganization: builder.query<boolean, { year: number }>(
      {
        query: data => ({
          url: '/is-exists-calculation-by-organization',
          method: 'POST',
          data,
        }),
      },
    ),
    isExistsCalculationByInitialOrder: builder.query<
      boolean,
      {
        year: number;
        periodStart: number;
        periodEnd: number;
        contractId: string;
      }
    >({
      query: data => ({
        url: '/is-exists-calculation-by-initial-order',
        method: 'POST',
        data,
      }),
    }),
    isExistsCalculationByCommercialMeteringPoints: builder.query<
      { commercialMeteringPoints: string[] },
      {
        year: number;
        contractId: string;
        commercialMeteringPoints: string[];
      }
    >({
      query: data => ({
        url: '/is-exists-calculation-by-commercial-metering-points',
        method: 'POST',
        data,
      }),
    }),
    getCommercialMeteringPointFilters: builder.query<
      { id: string; name: string }[],
      { periodStart: number; year: number; contractId: string }
    >({
      query: params => ({
        url: '/commercial-metering-point-filters',
        params,
      }),
    }),
  }),
});

export const {
  useGetAnnualOrderedCapacitiesQuery,
  useGetAnnualOrderedCapacityByIdQuery,
  useGetOrderedCapacityTypesQuery,
  useDeleteAnnualOrderedCapacityByIdMutation,
  useAnnualOrderedCapacityRecalculateSingleMutation,
  useAnnualOrderedCapacityCalculateByContractMutation,
  useCalculateByApplicationMutation,
  useCalculateAnnualOrderedCapacityByOrganizationMutation,
  useGetAnnualOrderedCapacityCounterpartiesQuery,
  useLazyIsExistsCalculationByOrganizationQuery,
  useGetCommercialMeteringPointFiltersQuery,
  useLazyIsExistsCalculationByInitialOrderQuery,
  useLazyIsExistsCalculationByCommercialMeteringPointsQuery,
  useUpdateByApplicationMutation,
} = annualOrderedCapacitiesApi;
