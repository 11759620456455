import { ReactNode } from 'react';

import Typography from '@mui/joy/Typography';

import LogoIcon from '@assets/logo-dark.svg?react';

import { Content, TextWrapper, Title } from './styled';

interface WelcomeMessageProps {
  icon: ReactNode;
  title: string;
  subtitle?: string;
  description: string;
  hideTitleEndDecorator?: boolean;
  width?: string;
}

const WelcomeMessage = ({
  icon,
  title,
  subtitle,
  hideTitleEndDecorator,
  description,
  width,
}: WelcomeMessageProps) => {
  return (
    <Content sx={{ maxWidth: width || '400px' }}>
      {icon}
      <TextWrapper>
        {!!subtitle && (
          <Typography
            level='title-sm'
            fontWeight={600}
            textColor='text.tertiary'
          >
            {subtitle}
          </Typography>
        )}
        <Title
          level='h4'
          fontWeight={600}
          endDecorator={hideTitleEndDecorator ? null : <LogoIcon />}
        >
          {title}
        </Title>
        <Typography level='body-sm' textColor='text.tertiary'>
          {description}
        </Typography>
      </TextWrapper>
    </Content>
  );
};

export default WelcomeMessage;
