import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import { FiltersGrid, Pagination } from '@/features/types';
import {
  infiniteQueryOptions,
  normalizeInfiniteQueryData,
} from '@/features/utils';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { GasThread, GasThreadRequestBody, GasThreadViewModel } from './types';

export const gasThreadApi = createApi({
  reducerPath: 'gasThreadApi',
  baseQuery: axiosBaseQuery({
    baseUrl: Config.VITE_API_URL + '/gas-threads',
  }),
  tagTypes: ['GasThread', 'GasThreads'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getGasThreads: builder.query<GasThreadViewModel[], FiltersGrid | void>({
      query: params => ({
        url: '/query',
        params,
      }),
      providesTags: () => ['GasThreads'],
    }),
    getGasThreadsI: builder.infiniteQuery<
      GasThreadViewModel[],
      FiltersGrid | void,
      Pagination
    >({
      infiniteQueryOptions,
      query: ({ queryArg, pageParam }) => ({
        url: '/query',
        params: { ...queryArg, ...pageParam },
      }),
      providesTags: () => ['GasThreads'],
    }),
    getGasThreadById: builder.query<GasThread, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: () => ['GasThread'],
    }),
    createGasThread: builder.mutation<string, GasThreadRequestBody>({
      query: data => ({
        url: '/',
        method: 'POST',
        data,
      }),
      invalidatesTags: id => ['GasThreads', { type: 'GasThread', id }],
    }),
    updateGasThread: builder.mutation<GasThread, GasThreadRequestBody>({
      query: data => ({
        url: `/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: result => [
        'GasThread',
        { type: 'GasThread', id: result.id },
      ],
    }),
  }),
});

export const {
  useGetGasThreadsQuery,
  useLazyGetGasThreadsQuery,
  useLazyGetGasThreadByIdQuery,
  useGetGasThreadByIdQuery,
  useCreateGasThreadMutation,
  useUpdateGasThreadMutation,
} = gasThreadApi;

export const useGetGasThreadsInfiniteQuery = normalizeInfiniteQueryData(
  gasThreadApi.useGetGasThreadsIInfiniteQuery,
);
