import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME_REFERENCE_BOOK } from '@/features/constants';
import { FiltersGrid } from '@/features/types';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { MeasurementRange } from './types';

export const measurementRangesApi = createApi({
  tagTypes: ['MeasurementRange'],
  reducerPath: 'measurementRangesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/measurement-ranges`,
  }),
  keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
  endpoints: builder => ({
    getMeasurementRanges: builder.query<MeasurementRange[], FiltersGrid>({
      query: (params = { page: 1, pageSize: 100 }) => ({
        url: '/query',
        params,
      }),
    }),
    getMeasurementRangeById: builder.query<MeasurementRange, string>({
      query: id => ({
        url: `/${id}`,
      }),
    }),
  }),
});

export const { useGetMeasurementRangesQuery, useGetMeasurementRangeByIdQuery } =
  measurementRangesApi;
