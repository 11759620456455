import { ReactNode } from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Skeleton } from '@mui/joy';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';

import CopyText from '@components/ui/CopyText';

import { DialogTitle } from './styled';

export interface DialogTitleBasicProps {
  icon?: ReactNode;
  title?: ReactNode;
  subtitle?: string;
  copy?: string | number;
  children?: ReactNode;
  loading?: boolean;
  titleColor?: string;
}

export const titleStyles = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const DialogTitleBasic = ({
  icon,
  subtitle,
  title,
  copy,
  children,
  loading,
  titleColor = 'neutral.800',
}: DialogTitleBasicProps) => {
  return (
    <DialogTitle>
      <Stack>{icon}</Stack>

      <Stack
        direction='row'
        alignItems='center'
        gap={1}
        sx={{ maxWidth: '100%' }}
      >
        <Typography
          level='title-md'
          fontWeight={600}
          sx={{ color: 'text.icon', whiteSpace: 'nowrap' }}
        >
          {subtitle}
        </Typography>
        <ChevronRightIcon sx={{ width: 16, height: 16, color: '#616778' }} />

        <Typography level='title-md' textColor={titleColor} sx={titleStyles}>
          <Skeleton
            variant='text'
            loading={!!loading}
            level='title-lg'
            sx={{ width: '392px', borderRadius: '24px' }}
          >
            {!loading && title}
          </Skeleton>
        </Typography>
        {copy && <CopyText value={copy} />}
        {children}
      </Stack>
    </DialogTitle>
  );
};

export default DialogTitleBasic;
