import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME_REFERENCE_BOOK } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { MeteringDeviceGroups, MeteringDeviceGroupsFilter } from './types';

export const meteringDeviceGroupsApi = createApi({
  reducerPath: 'meteringDeviceGroupsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/metering-device-groups`,
  }),
  tagTypes: ['MeteringDeviceGroups', 'MeteringDeviceGroup'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
  endpoints: builder => ({
    getMeteringDeviceGroups: builder.query<MeteringDeviceGroups[], void>({
      query: () => ({
        url: '/',
      }),
    }),
    getMeteringDeviceGroupsByNodeId: builder.query<
      MeteringDeviceGroups[],
      MeteringDeviceGroupsFilter
    >({
      query: params => ({
        url: '/query',
        method: 'GET',
        params,
      }),
    }),
    getMeteringDeviceGroupsById: builder.query<MeteringDeviceGroups, string>({
      query: id => ({
        url: `/${id}`,
      }),
    }),
  }),
});

export const {
  useGetMeteringDeviceGroupsQuery,
  useGetMeteringDeviceGroupsByIdQuery,
  useGetMeteringDeviceGroupsByNodeIdQuery,
} = meteringDeviceGroupsApi;
