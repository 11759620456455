import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME_REFERENCE_BOOK } from '@/features/constants';
import { MeteringPointActItemsParams } from '@/features/meteringPointActs/types/MeteringPointActItemsParams';
import {
  infiniteQueryOptions,
  normalizeInfiniteQueryData,
} from '@/features/utils';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { FiltersGrid, Pagination } from '../types';
import {
  MeteringPointAct,
  MeteringPointActDevice,
  MeteringPointActEquipment,
  MeteringPointActGrid,
  MeteringPointActModem,
  MeteringPointActPipelineSection,
  MeteringPointActSeal,
} from './types';

const tagTypes = [
  'MeteringPointAct',
  'MeteringPointActDevice',
  'MeteringPointActEquipment',
  'MeteringPointActModem',
  'MeteringPointActPipelineSection',
  'MeteringPointActSeal',
];

export const meteringPointActApi = createApi({
  reducerPath: 'meteringPointActApi',
  baseQuery: axiosBaseQuery({
    baseUrl: Config.VITE_API_URL + '/metering-point-acts',
  }),
  tagTypes,
  keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
  endpoints: builder => ({
    getMeteringPointActs: builder.query<
      MeteringPointActGrid[],
      FiltersGrid | void
    >({
      query: (params = {}) => {
        return {
          url: `/query`,
          params,
        };
      },
      providesTags: () => ['MeteringPointAct', 'MeteringPointActs'],
    }),
    getMeteringPointActsI: builder.infiniteQuery<
      MeteringPointActGrid[],
      FiltersGrid | void,
      Pagination
    >({
      infiniteQueryOptions,
      query: ({ queryArg, pageParam }) => {
        return {
          url: `/query`,
          params: { ...queryArg, ...pageParam },
        };
      },
      providesTags: () => ['MeteringPointAct', 'MeteringPointActs'],
    }),
    getMeteringPointActById: builder.query<MeteringPointAct, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: () => ['MeteringPointAct'],
    }),
    createMeteringPointAct: builder.mutation<MeteringPointAct, FormData>({
      query: data => ({
        url: '/',
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data,
      }),
      invalidatesTags: result => [
        'MeteringPointAct',
        { type: 'MeteringPointAct', id: result.id },
      ],
    }),
    updateMeteringPointAct: builder.mutation<MeteringPointAct, FormData>({
      query: data => ({
        url: `/${data.get('id')}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: tagTypes,
    }),
    deleteMeteringPointActById: builder.mutation<MeteringPointAct, string>({
      query: id => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, id) => [
        'MeteringPointAct',
        { type: 'MeteringPointAct', id },
      ],
    }),
    getMeteringPointActDevices: builder.query<
      MeteringPointActDevice[],
      MeteringPointActItemsParams
    >({
      query: params => ({
        url: `/devices`,
        params,
      }),
      providesTags: () => ['MeteringPointActDevice'],
    }),
    getMeteringPointActEquipment: builder.query<
      MeteringPointActEquipment[],
      MeteringPointActItemsParams
    >({
      query: params => ({
        url: `/equipment`,
        params,
      }),
      providesTags: () => ['MeteringPointActEquipment'],
    }),
    getMeteringPointActModems: builder.query<
      MeteringPointActModem[],
      MeteringPointActItemsParams
    >({
      query: params => ({
        url: `/modems`,
        params,
      }),
      providesTags: () => ['MeteringPointActModem'],
    }),
    getMeteringPointActPipelineSections: builder.query<
      MeteringPointActPipelineSection[],
      MeteringPointActItemsParams
    >({
      query: params => ({
        url: `/pipeline-sections`,
        params,
      }),
      providesTags: () => ['MeteringPointActPipelineSection'],
    }),
    getMeteringPointActSeals: builder.query<
      MeteringPointActSeal[],
      MeteringPointActItemsParams
    >({
      query: params => ({
        url: `/seals`,
        params,
      }),
      providesTags: () => ['MeteringPointActSeal'],
    }),
  }),
});

export const {
  useGetMeteringPointActsQuery,
  useGetMeteringPointActByIdQuery,
  useCreateMeteringPointActMutation,
  useUpdateMeteringPointActMutation,
  useDeleteMeteringPointActByIdMutation,
  useGetMeteringPointActDevicesQuery,
  useGetMeteringPointActModemsQuery,
  useGetMeteringPointActEquipmentQuery,
  useGetMeteringPointActPipelineSectionsQuery,
  useGetMeteringPointActSealsQuery,
} = meteringPointActApi;

export const useGetMeteringPointActsInfiniteQuery = normalizeInfiniteQueryData(
  meteringPointActApi.useGetMeteringPointActsIInfiniteQuery,
);
