import { useState } from 'react';

import { FormFieldPreview } from '@/components/FormikField';
import AlertMessage from '@/components/ui/AlertMessage';
import { AlertMessageType } from '@/components/ui/AlertMessage';
import { InfoBox } from '@/components/ui/InfoBox';
import { TechnologicalLossHistoryModel } from '@/features/balanceActs/types';

import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';

import { InformationSkeleton } from '@components/ui/skeletons';

import HistoryIcon from '@assets/history.svg?react';

import LossHistoryModal from '../LossHistoryModal';

interface GeneralInformationProps {
  maxGasConsumption: number;
  isLoading: boolean;
  lossHistory?: TechnologicalLossHistoryModel[];
  technologicalLossErrorMessage?: string | null;
}

export const GeneralInformation = ({
  maxGasConsumption,
  isLoading,
  technologicalLossErrorMessage,
  lossHistory = [],
}: GeneralInformationProps) => {
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const formattedValue = new Intl.NumberFormat('uk-UA').format(
    maxGasConsumption,
  );

  return (
    <>
      <InformationSkeleton loading={isLoading}>
        <InfoBox label='Загальна інформація'>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <FormFieldPreview label='Максимальне ВТВ по Акту (м³/доба)'>
              {technologicalLossErrorMessage ? (
                <AlertMessage type={AlertMessageType.Error}>
                  {technologicalLossErrorMessage}
                </AlertMessage>
              ) : (
                formattedValue
              )}
            </FormFieldPreview>
            {lossHistory.length > 0 && (
              <Button
                variant='solid'
                startDecorator={<HistoryIcon />}
                onClick={() => setIsHistoryOpen(true)}
                sx={{
                  backgroundColor: 'primary.50',
                  color: 'primary.500',
                  fontSize: theme => theme.fontSize.md,
                  '&:hover': {
                    backgroundColor: 'primary.50',
                  },
                }}
              >
                Історія розрахунків
              </Button>
            )}
          </Stack>
        </InfoBox>
      </InformationSkeleton>

      <LossHistoryModal
        open={isHistoryOpen}
        onClose={() => setIsHistoryOpen(false)}
        data={lossHistory}
      />
    </>
  );
};
