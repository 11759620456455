import { FC, PropsWithChildren } from 'react';

import Stack, { StackProps } from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';

type InfoBoxProps = StackProps & { label?: string };

export const InfoBox = ({
  label,
  children,
  gap = 2,
  ...props
}: InfoBoxProps) => {
  return (
    <Stack
      sx={{
        border: theme => `1px solid ${theme.palette.neutral[300]}`,
        borderRadius: theme => theme.spacing(1),
        padding: 2,
        gap,
      }}
      {...props}
    >
      {label && (
        <Typography
          level='title-sm'
          fontWeight={600}
          textColor={'text.secondary'}
        >
          {label}
        </Typography>
      )}
      {children}
    </Stack>
  );
};

export const InfoBoxRow = ({ children, ...props }: StackProps) => {
  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='flex-start'
      sx={{
        gap: props.gap || 2,
        '& > *': {
          flex: 1,
        },
      }}
      {...props}
    >
      {children}
    </Stack>
  );
};

export const InformationContainer: FC<PropsWithChildren> = ({ children }) => (
  <Stack overflow='auto'>{children}</Stack>
);
