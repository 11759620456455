import { forwardRef } from 'react';

import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';

export const DetailsNavLink = forwardRef<HTMLAnchorElement>(
  ({ state, ...rest }: NavLinkProps, ref) => {
    const location = useLocation();

    const backgroundLocation = (location.state as any)?.backgroundLocation;

    const mergedState = {
      ...(state || {}),
    };

    if (backgroundLocation) {
      mergedState.backgroundLocation = backgroundLocation;
    }

    return <NavLink ref={ref} state={mergedState} {...rest} />;
  },
);
