import { formatDate } from '@/forms/utils/formatDate';

import { styled } from '@mui/joy';
import Typography from '@mui/joy/Typography';

import CalendarIcon from '@assets/calendar.svg?react';

export const StyledDataChip = styled(Typography)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  background: theme.palette.neutral[100],
  borderRadius: theme.spacing(1),
  fontWeight: 600,
}));

export const DateChip = ({ startDate }: { startDate: string }) => (
  <StyledDataChip level='body-sm'>
    <CalendarIcon />
    {formatDate(startDate)}
  </StyledDataChip>
);
