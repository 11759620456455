import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import {
  GasRoute,
  GasRouteFilters,
  GasRouteRequestBody,
} from '@/features/gasRoutes/types';
import {
  infiniteQueryOptions,
  normalizeInfiniteQueryData,
} from '@/features/utils';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { FiltersGrid, Pagination } from '../types';

export const gasRoutesApi = createApi({
  reducerPath: 'gasRoutesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/gas-routes`,
  }),
  tagTypes: ['GasRoutes', 'GasRoute'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getGasRoutes: builder.query<GasRoute[], GasRouteFilters | void>({
      query: (data = {}) => ({
        url: `/query`,
        method: 'POST',
        data,
      }),
      providesTags: () => ['GasRoutes'],
    }),
    getGasRoutesGridify: builder.query<GasRoute[], FiltersGrid | void>({
      query: (params = {}) => {
        return {
          url: `/query`,
          params,
        };
      },
      providesTags: () => ['GasRoutes'],
    }),
    getGasRoutesGridifyI: builder.infiniteQuery<
      GasRoute[],
      FiltersGrid | void,
      Pagination
    >({
      infiniteQueryOptions,
      query: ({ queryArg, pageParam }) => {
        return {
          url: `/query`,
          params: { ...queryArg, ...pageParam },
        };
      },
      providesTags: () => ['GasRoutes'],
    }),
    getGasRouteById: builder.query<GasRoute, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: (_, __, id) => [{ type: 'GasRoute', id }],
    }),
    createGasRoute: builder.mutation<string, GasRouteRequestBody>({
      query: data => ({
        url: '/',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['GasRoutes'],
    }),
    updateGasRoute: builder.mutation<GasRoute, GasRouteRequestBody>({
      query: data => ({
        url: `/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (_, __, payload) => [
        'GasRoutes',
        { type: 'GasRoute', id: payload.id },
      ],
    }),
    deleteGasRoute: builder.mutation<GasRoute, string>({
      query: id => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: result => [
        'GasRoutes',
        { type: 'GasRoute', id: result.id },
      ],
    }),
  }),
});

export const {
  useGetGasRoutesQuery,
  useGetGasRouteByIdQuery,
  useCreateGasRouteMutation,
  useUpdateGasRouteMutation,
  useDeleteGasRouteMutation,
  useGetGasRoutesGridifyQuery,
  useLazyGetGasRoutesGridifyQuery,
} = gasRoutesApi;

export const useGetGasRoutesGridifyInfiniteQuery = normalizeInfiniteQueryData(
  gasRoutesApi.useGetGasRoutesGridifyIInfiniteQuery,
);
