import React from 'react';

import { RouteObject } from 'react-router/dist/lib/context';

const ActualExpensesReadings = React.lazy(
  () => import('@/pages/ActualExpensesMeteringPointDetails/views/Readings'),
);
const ActualExpensesAdjustments = React.lazy(
  () => import('@/pages/ActualIncomeDetails/views/Adjustments'),
);
const ActualExpensesTechnicalCalculations = React.lazy(
  () =>
    import(
      '@/pages/ActualExpensesMeteringPointDetails/views/TechnicalCalculations'
    ),
);
const ActualExpensesDailyExpenses = React.lazy(
  () =>
    import('@/pages/ActualExpensesMeteringPointDetails/views/DailyExpenses'),
);

export const ActualExpensesDetailsVies = {
  READINGS: '',
  TECHNICAL_CALCULATIONS: 'technical-calculations',
  ADJUSTMENT: 'adjustment',
  DAILY_EXPENSES: 'daily-expenses',
};

export const actualExpensesDetailsRoutes: RouteObject[] = [
  {
    element: <ActualExpensesReadings />,
    index: true,
    path: ActualExpensesDetailsVies.READINGS,
  },
  {
    element: <ActualExpensesTechnicalCalculations />,
    path: ActualExpensesDetailsVies.TECHNICAL_CALCULATIONS,
  },
  {
    element: <ActualExpensesAdjustments />,
    path: ActualExpensesDetailsVies.ADJUSTMENT,
  },
  {
    element: <ActualExpensesDailyExpenses />,
    path: ActualExpensesDetailsVies.DAILY_EXPENSES,
  },
];
