import { isBoolean, isString } from 'lodash-es';

import { SerializeCondition } from '@/utils/buildFilterQuery/types';
import { hasFilterItem } from '@/utils/buildFilterQuery/utils';

import { Conditions, LogicalOperators } from './constants';
import {
  isArrayFilter,
  isComparisonFilter,
  isDateFilter,
  isDateTimeFilter,
  isIdFilter,
  isObjectLikeFilter,
  isRadioIsCommonFilter,
  serializeArray,
  serializeComparison,
  serializeDate,
  serializeDateTime,
  serializeId,
  serializeObjectLike,
  serializeRadioIsCommon,
} from './serializes';

const serializeConditions: SerializeCondition[] = [
  {
    shouldSerialize: isDateFilter,
    serialize: serializeDate,
  },
  {
    shouldSerialize: isDateTimeFilter,
    serialize: serializeDateTime,
  },
  {
    shouldSerialize: isIdFilter,
    serialize: serializeId,
  },
  {
    shouldSerialize: filterItem => isString(filterItem),
    serialize: (filterId, filterItem) =>
      `${filterId}${Conditions.ContainsLike}${filterItem}/i`,
  },
  {
    shouldSerialize: filterItem => isBoolean(filterItem),
    serialize: (filterId, filterItem) =>
      `${filterId}${Conditions.Equal}${filterItem}`,
  },
  {
    shouldSerialize: isComparisonFilter,
    serialize: serializeComparison,
  },
  {
    shouldSerialize: isRadioIsCommonFilter,
    serialize: serializeRadioIsCommon,
  },
  {
    shouldSerialize: isObjectLikeFilter,
    serialize: serializeObjectLike,
  },
  {
    shouldSerialize: isArrayFilter,
    serialize: serializeArray,
  },
];

const serializeFilterItems = ([filterId, filterItem]: [
  string,
  any,
]): string => {
  for (const { shouldSerialize, serialize } of serializeConditions) {
    if (shouldSerialize(filterItem, filterId)) {
      return serialize(filterId, filterItem);
    }
  }

  return '';
};

export const buildFilterQuery = <F extends Record<string, any>>(
  filters: F,
): string =>
  Object.entries(filters)
    .filter(hasFilterItem)
    .map(serializeFilterItems)
    .filter(Boolean)
    .join(LogicalOperators.AND);
