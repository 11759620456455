import { ReactNode } from 'react';

import { Skeleton } from '@mui/joy';
import Chip, { ChipProps } from '@mui/joy/Chip';

import { titleStyles } from './Dialog/DialogTitleBasic';

const stylesMap = {
  green: { color: 'success.700', bgcolor: 'success.100' },
  yellow: { color: 'warning.700', bgcolor: 'warning.100' },
  red: { color: 'danger.700', bgcolor: 'danger.100' },
  grey: { color: 'neutral.700', bgcolor: 'neutral.200' },
  purple: { color: 'primary.500', bgcolor: 'primary.50' },
};

export interface StatusChipProps extends Omit<ChipProps, 'color' | 'title'> {
  color: string; // 'green' | 'yellow' | 'red' | 'grey';
  title: ReactNode;
  loading?: boolean;
}

const StatusChip = ({
  color,
  title,
  loading = false,
  sx = {},
  ...props
}: StatusChipProps) => {
  return (
    <Chip
      sx={{
        ...stylesMap[color],
        fontWeight: 500,
        ...titleStyles,
        width: '100%',
        ...sx,
      }}
      size='sm'
      slotProps={{ action: { sx: { '&, &:hover': { background: 'none' } } } }}
      {...props}
    >
      <Skeleton
        loading={!title && loading}
        variant='rectangular'
        sx={{ height: '15px', width: '95px', borderRadius: '8px' }}
      >
        {title}
      </Skeleton>
    </Chip>
  );
};

export default StatusChip;
