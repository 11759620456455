import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { FiltersGrid } from '../types';
import {
  ActBalanceOwnershipReadModel,
  ActBalanceOwnershipReferenceBooks,
  DocumentsAndActsViewModel,
  TechnologicalLossHistoryResponse,
} from './types';

export const balanceActsApi = createApi({
  reducerPath: 'balanceActsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/balance-of-ownership-acts`,
  }),
  tagTypes: ['BalanceActs', 'BalanceAct'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getBalanceActs: builder.query<DocumentsAndActsViewModel[], FiltersGrid>({
      query: params => ({
        url: '/query',
        data: params,
      }),
      providesTags: () => ['BalanceActs'],
    }),
    getBalanceActById: builder.query<ActBalanceOwnershipReadModel, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: result => [{ type: 'BalanceAct', id: result?.id }],
    }),
    createBalanceAct: builder.mutation<ActBalanceOwnershipReadModel, FormData>({
      query: data => ({
        url: '/',
        method: 'POST',
        data,
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
      invalidatesTags: ['BalanceActs'],
    }),
    updateBalanceAct: builder.mutation<ActBalanceOwnershipReadModel, FormData>({
      query: data => ({
        url: `/${data.get('id')}`,
        method: 'PUT',
        data,
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
      invalidatesTags: (_, __, data) => [
        'BalanceActs',
        { type: 'BalanceAct', id: data.get('id') as string },
      ],
    }),
    deleteBalanceAct: builder.mutation<void, string>({
      query: id => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, id) => [
        'BalanceActs',
        { type: 'BalanceAct', id },
      ],
    }),
    getBalanceActReferenceBooks: builder.query<
      ActBalanceOwnershipReferenceBooks,
      void
    >({
      query: () => ({
        url: '/reference-books',
      }),
    }),
    getLossHistory: builder.query<TechnologicalLossHistoryResponse, string>({
      query: id => ({
        url: `/loss-history/${id}`,
      }),
    }),
  }),
});

export const {
  useGetBalanceActsQuery,
  useGetBalanceActByIdQuery,
  useCreateBalanceActMutation,
  useUpdateBalanceActMutation,
  useDeleteBalanceActMutation,
  useGetBalanceActReferenceBooksQuery,
  useGetLossHistoryQuery,
} = balanceActsApi;
