import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME_REFERENCE_BOOK } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { EquipmentGroup, EquipmentGroupRequestBody } from './types';

export const equipmentGroupApi = createApi({
  reducerPath: 'equipmentGroupApi',
  baseQuery: axiosBaseQuery({
    baseUrl: Config.VITE_API_URL + '/equipment-groups',
  }),
  tagTypes: ['EquipmentGroup', 'EquipmentGroups'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
  endpoints: builder => ({
    getEquipmentGroups: builder.query<EquipmentGroup[], void>({
      query: () => ({
        url: '/',
      }),
      providesTags: () => ['EquipmentGroup', 'EquipmentGroups'],
    }),
    getEquipmentGroupById: builder.query<EquipmentGroup, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: () => ['EquipmentGroup'],
    }),
    createEquipmentGroup: builder.mutation<
      EquipmentGroup,
      EquipmentGroupRequestBody
    >({
      query: data => ({
        url: '/',
        method: 'POST',
        data,
      }),
      invalidatesTags: result => [
        'EquipmentGroup',
        { type: 'EquipmentGroup', id: result.id },
      ],
    }),
    updateEquipmentGroup: builder.mutation<
      EquipmentGroup,
      EquipmentGroupRequestBody
    >({
      query: data => ({
        url: `/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: result => [
        'EquipmentGroup',
        { type: 'EquipmentGroup', id: result.id },
      ],
    }),
  }),
});

export const { useGetEquipmentGroupsQuery } = equipmentGroupApi;
