import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { MeteringModem, MeteringModemsFilter } from './types';

export const meteringModemApi = createApi({
  reducerPath: 'meteringModemApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/modems`,
  }),
  tagTypes: ['MeteringModem', 'MeteringModems'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getMeteringModems: builder.query<MeteringModem[], MeteringModemsFilter>({
      query: data => ({
        url: '/query',
        method: 'POST',
        data,
      }),
      providesTags: () => ['MeteringModems', 'MeteringModem'],
    }),
    createMeteringModem: builder.mutation<MeteringModem, MeteringModem>({
      query: data => ({
        url: '/',
        method: 'POST',
        data: data,
      }),
      invalidatesTags: result => [
        'MeteringModem',
        { type: 'MeteringModem', id: result.id },
      ],
    }),
    getMeteringModemById: builder.query<MeteringModem, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: () => ['MeteringModem', 'MeteringModems'],
    }),
    updateMeteringModemById: builder.mutation<MeteringModem, MeteringModem>({
      query: data => ({
        url: `/${data.id}`,
        method: 'PUT',
        data: data,
      }),
      invalidatesTags: result => [
        'MeteringModem',
        { type: 'MeteringModem', id: result.id },
      ],
    }),
    deleteMeteringModemById: builder.mutation<MeteringModem, string>({
      query: id => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, id) => [
        'MeteringModem',
        { type: 'MeteringModem', id },
      ],
    }),
  }),
});

export const {
  useGetMeteringModemsQuery,
  useCreateMeteringModemMutation,
  useGetMeteringModemByIdQuery,
  useUpdateMeteringModemByIdMutation,
  useDeleteMeteringModemByIdMutation,
} = meteringModemApi;
