import { useCallback, useMemo } from 'react';

import { intersectionBy } from 'lodash-es';
import map from 'lodash-es/map';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

import { StepDataStorage } from '@components/ActsAndDocumentsForm/context/StepDataStorage';
import { FilterData, FilterItem } from '@components/Filter/types';

const filterStore = new StepDataStorage(sessionStorage, 'gsb_filters');

export const cleanFilterStore = () => filterStore.removeStoreItem();

const isDateFilter = (filterItem: FilterItem): boolean => 'date' in filterItem;

const convertForDateFilter = (
  filterItem: FilterItem,
  savedItems: FilterItem[],
) => {
  const commonItem = find(savedItems, ['value', filterItem.value]);

  if (commonItem) {
    filterItem.date = commonItem.date;
  }
};

export const useFilterStore = (filterName: string) => {
  const hasSavedFilters = useMemo(() => {
    const store = filterStore.getParsedStepsData();

    return !isEmpty(store[filterName]);
  }, [filterName]);

  const saveFilters = useCallback(
    (filterId: string, filters: FilterItem[]) => {
      const store = filterStore.getParsedStepsData();

      if (store[filterName]) {
        store[filterName][filterId] = filters;
      } else {
        store[filterName] = {
          [filterId]: filters,
        };
      }

      filterStore.setStoreItem(store);
    },
    [filterName],
  );

  const convertFilterData = useCallback(
    (data: FilterData[]): FilterData[] => {
      if (!hasSavedFilters) {
        return data;
      }

      const store = filterStore.getParsedStepsData();
      const savedFilters = store[filterName];

      for (const filterId in savedFilters) {
        const filterItem = find(data, ['filterId', filterId]);
        if (filterItem) {
          const commonItems = intersectionBy(
            filterItem.items,
            savedFilters[filterId],
            'value',
          );

          map(commonItems, commonItem => {
            commonItem.checked = true;

            if (isDateFilter(commonItem)) {
              convertForDateFilter(commonItem, savedFilters[filterId]);
            }

            return commonItem;
          });
        }
      }

      return data;
    },
    [filterName, hasSavedFilters],
  );

  const cleanSavedFilters = useCallback((filterName: string) => {
    const store = filterStore.getParsedStepsData();
    const savedFilters = store[filterName];
    if (savedFilters) {
      delete store[filterName];

      filterStore.setStoreItem(store);
    }
  }, []);

  return {
    saveFilters,
    convertFilterData,
    hasSavedFilters,
    cleanSavedFilters,
  };
};
