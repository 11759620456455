import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import {
  BridgeAct,
  BridgeActRequestPayload,
  SectorBridgeActViewModel,
} from '@/features/bridgeActs/types';
import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import { FiltersGrid, Pagination } from '@/features/types';
import {
  infiniteQueryOptions,
  normalizeInfiniteQueryData,
} from '@/features/utils';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

export const bridgeActsApi = createApi({
  reducerPath: 'bridgeActsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/sector-bridge-acts`,
  }),
  tagTypes: ['BridgeActs', 'BridgeAct'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getBridgeActs: builder.query<SectorBridgeActViewModel[], FiltersGrid>({
      query: params => ({
        url: `/query`,
        params,
      }),
      providesTags: () => ['BridgeActs'],
    }),
    getBridgeActsGridify: builder.infiniteQuery<
      SectorBridgeActViewModel[],
      FiltersGrid,
      Pagination
    >({
      infiniteQueryOptions,
      query: ({ queryArg, pageParam }) => ({
        url: `/query`,
        params: { ...queryArg, ...pageParam },
      }),
      providesTags: () => ['BridgeActs'],
    }),
    getBridgeActById: builder.query<BridgeAct, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: result => [{ type: 'BridgeAct', id: result.id }],
    }),
    createBridgeAct: builder.mutation<BridgeAct, BridgeActRequestPayload>({
      query: data => ({
        url: ``,
        method: 'POST',
        data,
      }),
      invalidatesTags: result => [
        'BridgeActs',
        { type: 'BridgeAct', id: result.id },
      ],
    }),
    updateBridgeAct: builder.mutation<BridgeAct, BridgeActRequestPayload>({
      query: data => ({
        url: `/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: result => [
        'BridgeActs',
        { type: 'BridgeAct', id: result.id },
      ],
    }),
  }),
});

export const {
  useGetBridgeActByIdQuery,
  useCreateBridgeActMutation,
  useUpdateBridgeActMutation,
} = bridgeActsApi;

export const useGetBridgeActsGridifyInfiniteQuery = normalizeInfiniteQueryData(
  bridgeActsApi.useGetBridgeActsGridifyInfiniteQuery,
);
