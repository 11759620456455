import { ReactNode, useState } from 'react';

import { addDefaultValueGetter } from '@/forms/utils/formatCell';

import { LinearProgress } from '@mui/joy';
import {
  DataGridProps,
  GridColDef,
  GridEventListener,
  GridSortModel,
} from '@mui/x-data-grid';
import { DataGridProProps, useGridApiRef } from '@mui/x-data-grid-pro';

import {
  COLUMN_HEADER_HEIGHT,
  COLUMN_HEIGHT,
  ColumnHeaderSortIcon,
  DefaultEmptyMessage,
  EmptyMessage as EmptyMessageComponent,
  StyledDataGrid,
  StyledDataGridPro,
} from '../shared/grid';

export const EmptyMessage = EmptyMessageComponent;

export interface TableProps<T> {
  rows: T[];
  autoRowHeight?: boolean;
  rowHeight?: number;
  columns: GridColDef[];
  onRowClick?: GridEventListener<'rowClick'>;
  loading?: boolean;
  loadingData?: boolean;
  emptyMessage?: ReactNode;
  columnHeaderHeight?: number;
  fixedHeight?: boolean;
  noLineStyles?: boolean;
  getRowId?: (row: T) => string | number;
  footer?: ReactNode;
  sortModel?: DataGridProps['sortModel'];
  disableColumnSorting?: boolean;
  getRowClassName?: DataGridProps['getRowClassName'];
  sx?: DataGridProps['sx'] | DataGridProProps['sx'];
  disableVirtualization?: boolean;
  onSortModelChange?: (sortModel: GridSortModel) => void;
  onRowsScrollEnd?: DataGridProProps['onRowsScrollEnd'];
}

const Table = <T,>({
  rows = [],
  columns = [],
  onRowClick,
  autoRowHeight,
  rowHeight,
  loading,
  loadingData,
  emptyMessage = DefaultEmptyMessage,
  columnHeaderHeight,
  noLineStyles,
  getRowId,
  footer = null,
  sortModel: initialSortModel,
  onSortModelChange,
  disableColumnSorting = false,
  getRowClassName,
  disableVirtualization,
  sx,
  onRowsScrollEnd,
}: TableProps<T>) => {
  const [currentSortModel, setCurrentSortModel] = useState(initialSortModel);

  const handleSortModelChange = (newModel: GridSortModel) => {
    setCurrentSortModel(newModel.length ? newModel : initialSortModel);
    onSortModelChange?.(newModel);
  };

  const enhancedColumns = addDefaultValueGetter(columns);

  const apiRef = useGridApiRef();

  const Component = onRowsScrollEnd ? StyledDataGridPro : StyledDataGrid;

  const props = onRowsScrollEnd
    ? {
        apiRef,
        onRowsScrollEnd,
      }
    : {};

  return (
    <>
      <Component
        {...props}
        sx={sx}
        getRowClassName={getRowClassName}
        initialState={{
          sorting: {
            sortModel: initialSortModel,
          },
        }}
        getRowId={getRowId}
        noLineStyles={noLineStyles}
        rows={rows}
        disableRowSelectionOnClick
        disableColumnMenu
        disableColumnResize
        columns={enhancedColumns}
        hideFooter={!footer || !rows?.length}
        hideFooterPagination
        columnHeaderHeight={columnHeaderHeight ?? COLUMN_HEADER_HEIGHT}
        rowHeight={
          rowHeight ||
          (!loading && (!rows?.length || autoRowHeight)
            ? undefined
            : COLUMN_HEIGHT)
        }
        getRowHeight={() =>
          rowHeight ||
          (!loading && (!rows?.length || autoRowHeight)
            ? 'auto'
            : COLUMN_HEIGHT)
        }
        onRowClick={(data, event, _) => {
          if (window.getSelection().type === 'Range') {
            return;
          }
          onRowClick(data, event, _);
        }}
        slots={{
          columnHeaderSortIcon: ColumnHeaderSortIcon,
          noRowsOverlay: () => emptyMessage,
          footer: () => footer,
        }}
        loading={loading}
        slotProps={{
          loadingOverlay: {
            variant: 'skeleton',
            noRowsVariant: 'skeleton',
          },
        }}
        autoRowHeight={autoRowHeight}
        sortModel={currentSortModel}
        onSortModelChange={handleSortModelChange}
        footer={!!footer}
        disableColumnSorting={disableColumnSorting}
        disableVirtualization={disableVirtualization}
      />
      {loadingData && (
        <LinearProgress
          sx={{
            position: 'absolute',
            bottom: 0,
            zIndex: 10,
            width: '100%',
            marginLeft: '-24px',
          }}
        />
      )}
    </>
  );
};

export default Table;
