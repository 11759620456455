import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import {
  Adjustment,
  CommercialMeteringPointAdjustment,
  CommercialMeteringPointAdjustmentQueryPayload,
} from './types';

export const commercialMeteringPointAdjustmentApi = createApi({
  reducerPath: 'commercialMeteringPointAdjustmentApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/counterparty-adjustments`,
  }),
  tagTypes: ['CommercialMeteringPointAdjustment', 'Adjustment'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getCommercialMeteringPointAdjustment: builder.query<
      CommercialMeteringPointAdjustment,
      CommercialMeteringPointAdjustmentQueryPayload
    >({
      query: data => ({
        url: '/query',
        method: 'POST',
        data,
      }),
      providesTags: () => ['CommercialMeteringPointAdjustment', 'Adjustment'],
    }),
    getCommercialMeteringPointAdjustmentById: builder.query<
      CommercialMeteringPointAdjustment,
      string
    >({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: () => ['Adjustment', 'CommercialMeteringPointAdjustment'],
    }),
    createCommercialMeteringPointAdjustment: builder.mutation<
      Adjustment,
      CommercialMeteringPointAdjustment
    >({
      query: data => ({
        url: '/',
        method: 'POST',
        data: data,
      }),
      invalidatesTags: result => [
        'CommercialMeteringPointAdjustment',
        { type: 'Adjustment', id: result.id },
      ],
    }),
    updateCommercialMeteringPointAdjustmentById: builder.mutation<
      Adjustment,
      Adjustment
    >({
      query: data => ({
        url: `/${data.id}`,
        method: 'PUT',
        data: data,
      }),
      invalidatesTags: result => [
        'CommercialMeteringPointAdjustment',
        { type: 'Adjustment', id: result.id },
      ],
    }),
    deleteCommercialMeteringPointAdjustmentById: builder.mutation<
      Adjustment,
      string
    >({
      query: id => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: result => [
        'CommercialMeteringPointAdjustment',
        { type: 'Adjustment', id: result.id },
      ],
    }),
  }),
});

export const {
  useGetCommercialMeteringPointAdjustmentQuery,
  useCreateCommercialMeteringPointAdjustmentMutation,
  useGetCommercialMeteringPointAdjustmentByIdQuery,
  useUpdateCommercialMeteringPointAdjustmentByIdMutation,
  useDeleteCommercialMeteringPointAdjustmentByIdMutation,
} = commercialMeteringPointAdjustmentApi;
