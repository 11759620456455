import { Suspense } from 'react';

import Config from '@config';
import { ClickScrollPlugin, OverlayScrollbars } from 'overlayscrollbars';
import 'overlayscrollbars/overlayscrollbars.css';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import AuthProvider from '@/providers/AuthProvider';
import MaterialProvider from '@/providers/MaterialProvider';

import { LicenseInfo } from '@mui/x-license';

import ErrorBoundary from '@components/ErrorBoundary';
import { NotificationProvider } from '@components/Notification/NotificationProvider';

import { APP_REFRESH_ID } from './app/constants';
import store from './app/store';
import { getRoutes } from './route';

LicenseInfo.setLicenseKey(Config.VITE_MUI_LICENSE);

OverlayScrollbars.plugin(ClickScrollPlugin);

localStorage.setItem(APP_REFRESH_ID, Date.now().toString());

createRoot(document.getElementById('root')!).render(
  <ErrorBoundary>
    <Provider store={store}>
      <MaterialProvider>
        <AuthProvider>
          <Suspense>
            <NotificationProvider>
              <RouterProvider router={getRoutes()} />
            </NotificationProvider>
          </Suspense>
        </AuthProvider>
      </MaterialProvider>
    </Provider>
  </ErrorBoundary>,
);
