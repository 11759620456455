import { FC } from 'react';

import { NavLinkProps } from 'react-router-dom';

import { DetailsNavLink } from '@/components/DetailsNavLink';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { styled } from '@mui/joy';
import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';

const StyledDocumentLink = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

type DocumentLinkProps = {
  name: string;
  url: string;
};

export const DocumentLink: FC<DocumentLinkProps> = ({ name, url }) => (
  <StyledDocumentLink>
    <Button
      to={url}
      target='_blank'
      onClick={e => e.stopPropagation()}
      component={DetailsNavLink as FC<NavLinkProps>}
      sx={{
        '&:hover': {
          backgroundColor: 'transparent',
        },
      }}
      startDecorator={
        <Stack
          alignItems='center'
          justifyContent='center'
          sx={{
            borderRadius: '100%',
            backgroundColor: 'primary.50',
            height: '40px',
            width: '40px',
          }}
        >
          <InsertDriveFileIcon color='primary' fontSize='medium' />
        </Stack>
      }
      variant='plain'
    >
      {name}
    </Button>
  </StyledDocumentLink>
);
