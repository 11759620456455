import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME_REFERENCE_BOOK } from '@/features/constants';
import {
  GasPipelineSectionReadSlim,
  GetGasPipelinesQueryParams,
} from '@/features/gasPipelineSections/types';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

export const gasPipelineSectionsApi = createApi({
  reducerPath: 'gasPipelineSectionsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: Config.VITE_API_URL,
  }),
  keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
  tagTypes: ['GasPipelineSections'],
  endpoints: builder => ({
    getGasPipelineSections: builder.query<
      GasPipelineSectionReadSlim[],
      GetGasPipelinesQueryParams
    >({
      query: ({ commercialMeteringPointId }) => ({
        url: `/gas-pipeline-sectios?commercialMeteringPointId=${commercialMeteringPointId}`,
      }),
      providesTags: () => ['GasPipelineSections'],
    }),
  }),
});

export const { useGetGasPipelineSectionsQuery } = gasPipelineSectionsApi;
