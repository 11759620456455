import { useCallback } from 'react';

import { useOrganizationContext } from '@/providers/OrganizationProvider';
import { usePermissionsContext } from '@/providers/PermissionProvider/hooks/usePermissionsContext';

import { UserPermissions } from '@components/Permissions/userPermission';

// TODO: improve this method
export const useGetOrganizationRedirectHref = () => {
  const { isMainOffice } = useOrganizationContext();
  const { hasPermission } = usePermissionsContext();

  const getOrganizationRedirectHref = useCallback(
    (id: string) => {
      if (hasPermission(UserPermissions.AdministrationOrganizationRead)) {
        return `/${id}`;
      }

      if (isMainOffice) {
        return `/main-organization/${id}`;
      }

      if (
        hasPermission(UserPermissions.AdministrationStructuralSubdivisionRead)
      ) {
        return `/${id}/subdivisions`;
      }

      if (hasPermission(UserPermissions.AdministrationEmployeeRead)) {
        return `/${id}/employees`;
      }

      if (hasPermission(UserPermissions.GasDistributionStationGDSRead)) {
        return `/${id}/gas-distribution-stations`;
      }

      if (hasPermission(UserPermissions.GasDistributionStationThreadRead)) {
        return `/${id}/gas-threads`;
      }

      return '/';
    },
    [hasPermission, isMainOffice],
  );

  return { getOrganizationRedirectHref };
};
