import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { ReferenceBook } from '@/features/organizations/types';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

export const technicalConditionsActTypesApi = createApi({
  reducerPath: 'technicalConditionsActTypesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/technical-conditions-act-types`,
  }),
  tagTypes: ['TechnicalConditionsActType'],
  endpoints: builder => ({
    getTechnicalConditionsActTypes: builder.query<ReferenceBook[], void>({
      query: () => ({
        url: '',
      }),
      providesTags: ['TechnicalConditionsActType'],
    }),
    getTechnicalConditionsActType: builder.query<ReferenceBook, string>({
      query: id => ({
        url: `/${id}`,
      }),
    }),
    createTechnicalConditionsActType: builder.mutation<void, ReferenceBook>({
      query: body => ({
        url: '',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['TechnicalConditionsActType'],
    }),
    updateTechnicalConditionsActType: builder.mutation<void, ReferenceBook>({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['TechnicalConditionsActType'],
    }),
    deleteTechnicalConditionsActType: builder.mutation<void, string>({
      query: id => ({
        url: `/technical-conditions-act-types/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TechnicalConditionsActType'],
    }),
  }),
});

export const {
  useGetTechnicalConditionsActTypesQuery,
  useGetTechnicalConditionsActTypeQuery,
  useCreateTechnicalConditionsActTypeMutation,
  useUpdateTechnicalConditionsActTypeMutation,
  useDeleteTechnicalConditionsActTypeMutation,
} = technicalConditionsActTypesApi;
