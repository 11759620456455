import { useEffect } from 'react';

import { useRouteError } from 'react-router-dom';

import LogoIcon from '@/assets/logo-dark.svg?react';
import WelcomeMessage from '@/pages/Onboarding/components/WelcomeMessage';

import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import { alpha, hexToRgb } from '@mui/material';

import { HeaderWrapper } from '@components/Header';
import ProfileDropdown from '@components/Header/components/UserPanel/components/ProfileDropdown';
import { Container } from '@components/Header/components/UserPanel/styled';

import CircleIcon from './circle-icon.svg?react';

const ROUTER_ERRORS = [
  'Failed to fetch dynamically imported module',
  'Importing a module script failed',
];

export const handleReloadOnError = (error: unknown) => {
  const err = error as Error;

  if (err?.message && ROUTER_ERRORS.some(msg => err.message.includes(msg))) {
    window.location.reload();
  }
};

interface ErrorViewProps {
  hideHeader?: boolean;
  title?: string;
  description?: string;
}

export const ErrorView = ({
  hideHeader,
  title = 'Ой, щось пішло не так!',
  description = 'Спробуйте перезавантажити сторінку або зверніться до адміністратора',
}: ErrorViewProps) => (
  <>
    {!hideHeader && (
      <HeaderWrapper
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          justifyContent: 'space-between',
          boxShadow: 'none',
          borderBottom: `1px solid ${alpha(hexToRgb('#636B74'), 0.3)}`,
        }}
      >
        <div style={{ width: '56px' }}></div>
        <LogoIcon height={24} />
        <Container>
          <ProfileDropdown />
        </Container>
      </HeaderWrapper>
    )}
    <Stack
      sx={{ height: '100vh' }}
      justifyContent='center'
      alignItems='center'
      gap={4}
    >
      <WelcomeMessage
        width='500px'
        icon={<CircleIcon />}
        hideTitleEndDecorator
        title={title}
        description={description}
      />
      <Button size='sm' onClick={() => window.location.reload()}>
        Перезавантажити
      </Button>
    </Stack>
  </>
);

const Error = () => {
  const error = useRouteError();

  useEffect(() => {
    handleReloadOnError(error);
  }, [error]);

  return <ErrorView />;
};

export default Error;
