import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import {
  CounterpartiesExpenseQueryModel,
  CounterpartyData,
  CreateExpensePayload,
  GenerateExpensePayload,
  MeteringPointData,
  MeteringPointExpense,
  MeteringPointQuery,
  OperatingExpensesMeteringPointInfo,
  UpdateExpensePayload,
} from './types';

export const operationalExpenseApi = createApi({
  reducerPath: 'operationalExpenseApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/counterparty-operational-expenses`,
  }),
  tagTypes: [
    'OperatingExpenses',
    'OperatingExpense',
    'OperatingExpenseMeteringPoints',
    'OperatingExpensesMeteringPointInfo',
  ],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getOperatingExpenses: builder.query<
      CounterpartyData[],
      CounterpartiesExpenseQueryModel
    >({
      query: filters => ({
        url: '/counterparties/query',
        method: 'POST',
        data: filters,
      }),
    }),
    getOperatingExpensesMeteringPoints: builder.query<
      MeteringPointData,
      MeteringPointQuery
    >({
      query: filters => ({
        url: '/metering-points/query',
        method: 'POST',
        data: filters,
      }),
      providesTags: () => ['OperatingExpenseMeteringPoints'],
    }),
    getOperatingExpensesMeteringPointInfo: builder.query<
      OperatingExpensesMeteringPointInfo,
      MeteringPointQuery
    >({
      query: params => ({
        url: '/metering-points/info',
        params,
      }),
      providesTags: () => ['OperatingExpensesMeteringPointInfo'],
    }),
    getMeteringPointExpenses: builder.query<MeteringPointExpense, string>({
      query: id => ({
        url: `/metering-points/expense/${id}`,
      }),
    }),
    createDailyExpense: builder.mutation<
      MeteringPointExpense,
      CreateExpensePayload
    >({
      query: data => ({
        url: '/metering-points/expense',
        method: 'POST',
        data,
      }),
      invalidatesTags: (_, __, payload) => [
        'OperatingExpenseMeteringPoints',
        {
          type: 'OperatingExpense',
          id: `${payload.counterpartyId}-${payload.contractId}-${payload.structuralSubdivisionId}`,
        },
      ],
    }),
    generateExpense: builder.mutation<
      MeteringPointExpense,
      GenerateExpensePayload
    >({
      query: data => ({
        url: '/metering-points/expense/generate',
        method: 'POST',
        data,
      }),
    }),
    updateDailyExpense: builder.mutation<
      MeteringPointExpense,
      UpdateExpensePayload
    >({
      query: data => ({
        url: `/metering-points/expense/${data.id}`,
        method: 'PUT',
        data: {
          id: data.id,
          dailyExpenses: data.dailyExpenses,
          ignoreUnauthorizedConsumption: data.ignoreUnauthorizedConsumption,
        },
      }),
      invalidatesTags: (_, __, payload) => [
        'OperatingExpenseMeteringPoints',
        'OperatingExpenses',
        {
          type: 'OperatingExpense',
          id: `${payload.counterpartyId}-${payload.contractId}-${payload.structuralSubdivisionId}`,
        },
      ],
    }),
  }),
});

export const {
  useGetOperatingExpensesQuery,
  useGetOperatingExpensesMeteringPointsQuery,
  useGenerateExpenseMutation,
  useGetMeteringPointExpensesQuery,
  useLazyGetMeteringPointExpensesQuery,
  useCreateDailyExpenseMutation,
  useUpdateDailyExpenseMutation,
  useGetOperatingExpensesMeteringPointInfoQuery,
} = operationalExpenseApi;
