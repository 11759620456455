import { serializeDate } from '@/utils/buildFilterQuery/serializes/serializeDate';
import {
  GridifySerialize,
  GridifyStrategy,
} from '@/utils/buildFilterQuery/types';
import { shouldSerializeArrayLikeFilterItem } from '@/utils/buildFilterQuery/utils';

export const isDateTimeFilter: GridifyStrategy =
  shouldSerializeArrayLikeFilterItem('datetime');

export const serializeDateTime: GridifySerialize = (filterId, filterItem) => {
  return serializeDate(filterId, filterItem, { isoString: true });
};
