import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import {
  MeteringDevice,
  MeteringDeviceQueryParams,
  MeteringDeviceStatusHistory,
} from './types';

const normalizeMeteringDevicesPayloadBeforeMutation = (
  values: MeteringDevice,
) => {
  const payload: MeteringDevice = { ...values };

  payload.maintenanceEndDate = values.maintenanceEndDate || null;

  return payload;
};

export const meteringDevicesApi = createApi({
  reducerPath: 'meteringDeviceApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/metering-devices`,
  }),
  tagTypes: ['MeteringDevice', 'MeteringDevices'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getMeteringDevices: builder.query<
      MeteringDevice[],
      MeteringDeviceQueryParams
    >({
      query: data => ({
        url: '/query',
        method: 'POST',
        data,
      }),
      providesTags: () => ['MeteringDevices', 'MeteringDevice'],
    }),
    createMeteringDevice: builder.mutation<MeteringDevice, MeteringDevice>({
      query: data => ({
        url: '/',
        method: 'POST',
        data: normalizeMeteringDevicesPayloadBeforeMutation(data),
      }),
      invalidatesTags: result => [
        'MeteringDevice',
        { type: 'MeteringDevice', id: result.id },
      ],
    }),
    getMeteringDeviceById: builder.query<MeteringDevice, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: () => ['MeteringDevice', 'MeteringDevice'],
    }),
    updateMeteringDeviceById: builder.mutation<MeteringDevice, MeteringDevice>({
      query: data => ({
        url: `/${data.id}`,
        method: 'PUT',
        data: normalizeMeteringDevicesPayloadBeforeMutation(data),
      }),
      invalidatesTags: result => [
        'MeteringDevice',
        { type: 'MeteringDevice', id: result.id },
      ],
    }),
    deleteMeteringDeviceById: builder.mutation<MeteringDevice, string>({
      query: id => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, id) => [
        'MeteringDevice',
        { type: 'MeteringDevice', id },
      ],
    }),
    getMeteringDeviceByIdStatusHistory: builder.query<
      MeteringDeviceStatusHistory[],
      string
    >({
      query: id => ({
        url: `/${id}/status-history`,
      }),
    }),
  }),
});

export const {
  useGetMeteringDevicesQuery,
  useGetMeteringDeviceByIdQuery,
  useCreateMeteringDeviceMutation,
  useUpdateMeteringDeviceByIdMutation,
  useGetMeteringDeviceByIdStatusHistoryQuery,
  useDeleteMeteringDeviceByIdMutation,
} = meteringDevicesApi;
