import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import { Pagination } from '@/features/types';
import {
  infiniteQueryOptions,
  normalizeInfiniteQueryData,
} from '@/features/utils';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { MeteringSeal, MeteringSealQueryParams, SealViewModel } from './types';

export const meteringSealApi = createApi({
  reducerPath: 'meteringSealApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/seals`,
  }),
  tagTypes: ['MeteringSeals', 'MeteringSeal'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getMeteringSeal: builder.query<SealViewModel[], MeteringSealQueryParams>({
      query: params => ({
        url: '/query',
        params,
      }),
      providesTags: () => ['MeteringSeals', 'MeteringSeal'],
    }),
    getMeteringSealI: builder.infiniteQuery<
      SealViewModel[],
      MeteringSealQueryParams,
      Pagination
    >({
      infiniteQueryOptions,
      query: ({ queryArg, pageParam }) => ({
        url: '/query',
        params: { ...queryArg, ...pageParam },
      }),
      providesTags: () => ['MeteringSeals', 'MeteringSeal'],
    }),
    getMeteringSealById: builder.query<MeteringSeal, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: () => ['MeteringSeal', 'MeteringSeals'],
    }),
    createMeteringSeal: builder.mutation<MeteringSeal, MeteringSeal>({
      query: data => ({
        url: '/',
        method: 'POST',
        data: data,
      }),
      invalidatesTags: result => [
        'MeteringSeal',
        { type: 'MeteringSeal', id: result.id },
      ],
    }),
    deleteMeteringSealById: builder.mutation<MeteringSeal, string>({
      query: id => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, id) => [
        'MeteringSeals',
        { type: 'MeteringSeal', id },
      ],
    }),
  }),
});

export const {
  useGetMeteringSealQuery,
  useGetMeteringSealByIdQuery,
  useCreateMeteringSealMutation,
  useDeleteMeteringSealByIdMutation,
} = meteringSealApi;

export const useGetMeteringSealInfiniteQuery = normalizeInfiniteQueryData(
  meteringSealApi.useGetMeteringSealIInfiniteQuery,
);
