import { ComponentProps, FC, ReactNode } from 'react';

import { styled } from '@mui/joy';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';

import { TimeChip } from '@components/ActsAndDocumentsForm';

import { DateChip } from './DateChip';

const StyledActFormTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: theme.fontSize.xl,
  fontWeight: 600,
  color: theme.palette.neutral[800],

  gap: theme.spacing(1),
}));

const NUMBER_SYMBOL = '№';

type MeteringPointActTitleMessageProps = {
  icon?: ReactNode;
  actNumber?: string;
  startDate?: string;
  startTime?: string;
  level?: ComponentProps<typeof Typography>['level'];
  text?: string;
};

export const MeteringPointActTitleMessage: FC<
  MeteringPointActTitleMessageProps
> = ({
  icon,
  actNumber,
  startDate,
  startTime,
  level = 'body-md',
  text = 'Акт по точці обліку',
}) => (
  <Stack direction='row' gap={1} component='span'>
    <StyledActFormTitle level={level} as='span'>
      {icon && icon}
      {text}
      {actNumber && (
        <span>
          {NUMBER_SYMBOL}
          {actNumber}
        </span>
      )}
    </StyledActFormTitle>
    {startDate && <DateChip startDate={startDate} />}
    {startTime && <TimeChip startTime={startTime} />}
  </Stack>
);
