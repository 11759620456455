import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import { Pagination } from '@/features/types';
import {
  infiniteQueryOptions,
  normalizeInfiniteQueryData,
} from '@/features/utils';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import {
  GasMeteringNodeViewModel,
  MeteringNode,
  MeteringNodeFilter,
} from './types';

export const meteringNodeApi = createApi({
  reducerPath: 'meteringNodeApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/gas-metering-nodes`,
  }),
  tagTypes: ['MeteringNode', 'MeteringNodes'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getMeteringNodes: builder.query<
      GasMeteringNodeViewModel[],
      MeteringNodeFilter
    >({
      query: params => ({
        url: '/query',
        params,
      }),
      providesTags: () => ['MeteringNode', 'MeteringNodes'],
    }),
    getMeteringNodesI: builder.infiniteQuery<
      GasMeteringNodeViewModel[],
      MeteringNodeFilter,
      Pagination
    >({
      infiniteQueryOptions,
      query: ({ queryArg, pageParam }) => ({
        url: '/query',
        params: { ...queryArg, ...pageParam },
      }),
      providesTags: () => ['MeteringNode', 'MeteringNodes'],
    }),
    getMeteringNodeById: builder.query<MeteringNode, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: () => ['MeteringNode', 'MeteringNodes'],
    }),
    createMeteringNode: builder.mutation<MeteringNode, MeteringNode>({
      query: data => ({
        url: '/',
        method: 'POST',
        data: data,
      }),
      invalidatesTags: result => [
        'MeteringNode',
        { type: 'MeteringNode', id: result.id },
      ],
    }),
    updateMeteringNodeById: builder.mutation<MeteringNode, MeteringNode>({
      query: data => ({
        url: `/${data.id}`,
        method: 'PUT',
        data: data,
      }),
      invalidatesTags: result => [
        'MeteringNode',
        { type: 'MeteringNode', id: result.id },
      ],
    }),
  }),
});

export const {
  useGetMeteringNodesQuery,
  useCreateMeteringNodeMutation,
  useGetMeteringNodeByIdQuery,
  useUpdateMeteringNodeByIdMutation,
} = meteringNodeApi;

export const useGetMeteringNodesInfiniteQuery = normalizeInfiniteQueryData(
  meteringNodeApi.useGetMeteringNodesIInfiniteQuery,
);
