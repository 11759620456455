import { useState } from 'react';

import Dialog from '@/components/ui/Dialog';

import Button from '@mui/joy/Button';

import InfoMessage from '@components/ui/InfoMessage';

export const ConfirmDialog = ({ error, title, subtitle, actions }: any) => {
  return (
    <Dialog
      open
      actions={
        <>
          <Button size='sm' variant='plain' onClick={actions.cancel.fn}>
            {actions.cancel.caption}
          </Button>
          <Button size='sm' onClick={actions.submit.fn}>
            {actions.submit.caption}
          </Button>
        </>
      }
      sx={{
        '.MuiPaper-root': {
          maxWidth: '480px',
          width: '100%',
        },
      }}
    >
      <InfoMessage error={error} title={title} subtitle={subtitle} />
    </Dialog>
  );
};

const useConfirmDialog = () => {
  const [data, setData] = useState<any>();

  return {
    isOpen: !!data,
    confirm: (fn: () => void, config: any = {}) => {
      setData({
        ...config,
        actions: {
          submit: {
            caption: config.submit || 'Закрити',
            fn: () => {
              fn();
              setData(undefined);
            },
          },
          cancel: {
            caption: config.cancel || 'Повернутися',
            fn: () => {
              setData(undefined);
            },
          },
        },
      });
    },
    ConfirmDialog: ({
      title,
      subtitle,
      hideSubtitle,
    }: {
      title?: string;
      subtitle?: string;
      hideSubtitle?: boolean;
    }) => {
      if (!data) {
        return null;
      }
      const config = Object.assign(data, {
        title: title || data.title || 'Ви впевнені, що хочете закрити вікно?',
        subtitle: !hideSubtitle
          ? subtitle ||
            data.subtitle ||
            'Скасування заповнення форми призведе до втрати внесених даних.'
          : null,
      });
      return <ConfirmDialog {...config} />;
    },
  };
};

export default useConfirmDialog;
