import { buildFilterQuery } from '@/utils/buildFilterQuery';

export const buildOrganizationsQueryParams = ({
  page = 1,
  pageSize = 100,
  sortModel = undefined,
  filter = {},
} = {}) => ({
  page,
  pageSize,
  orderBy: sortModel?.length
    ? Object.values(sortModel[0]).join(' ')
    : undefined,
  filter: buildFilterQuery(filter),
  includeMainOrganization: true,
});
