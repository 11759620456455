import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import {
  infiniteQueryOptions,
  normalizeInfiniteQueryData,
} from '@/features/utils';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { FiltersGrid, Pagination } from '../types';
import {
  MeteringPoint,
  MeteringPointGrid,
  MeteringPointRequestBody,
  MeteringPointStatusHistory,
} from './types';

export const meteringPointApi = createApi({
  reducerPath: 'meteringPointApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/metering-points`,
  }),
  tagTypes: ['MeteringPoint', 'MeteringPoints'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getMeteringPoints: builder.query<MeteringPointGrid[], FiltersGrid | void>({
      query: (params = {}) => {
        return {
          url: `/query`,
          params,
        };
      },
      providesTags: () => ['MeteringPoint', 'MeteringPoints'],
    }),
    getMeteringPointsI: builder.infiniteQuery<
      MeteringPointGrid[],
      FiltersGrid | void,
      Pagination
    >({
      infiniteQueryOptions,
      query: ({ queryArg, pageParam }) => {
        return {
          url: `/query`,
          params: { ...queryArg, ...pageParam },
        };
      },
      providesTags: () => ['MeteringPoint', 'MeteringPoints'],
    }),
    getMeteringPointById: builder.query<MeteringPoint, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: () => ['MeteringPoint'],
    }),
    createMeteringPoint: builder.mutation<
      MeteringPoint,
      MeteringPointRequestBody
    >({
      query: data => ({
        url: '',
        method: 'POST',
        data,
      }),
      invalidatesTags: result => [
        'MeteringPoint',
        { type: 'MeteringPoint', id: result.id },
      ],
    }),
    updateMeteringPoint: builder.mutation<
      MeteringPoint,
      MeteringPointRequestBody
    >({
      query: data => ({
        url: `/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: result => [
        'MeteringPoint',
        { type: 'MeteringPoint', id: result.id },
      ],
    }),
    getMeteringPointByIdStatusHistory: builder.query<
      MeteringPointStatusHistory[],
      string
    >({
      query: id => ({
        url: `/${id}/status-history`,
      }),
    }),
  }),
});

export const {
  useGetMeteringPointsQuery,
  useLazyGetMeteringPointsQuery,
  useLazyGetMeteringPointByIdQuery,
  useGetMeteringPointByIdQuery,
  useCreateMeteringPointMutation,
  useUpdateMeteringPointMutation,
  useGetMeteringPointByIdStatusHistoryQuery,
} = meteringPointApi;

export const useGetMeteringPointsInfiniteQuery = normalizeInfiniteQueryData(
  meteringPointApi.useGetMeteringPointsIInfiniteQuery,
);
