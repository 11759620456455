import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import {
  GasMeteringNodeActualIncomeIndicator,
  MeteringDeviceActualIncomeIndicator,
  MeteringPointActualIncomeCalculationModel,
  MeteringPointActualIncomeCalculationResultModel,
  MeteringPointActualIncomeCreateModel,
  MeteringPointActualIncomeIndicatorQueryModel,
  MeteringPointActualIncomeUpdateModel,
} from './types';

export const meteringPointActualIncomeIndicatorsApi = createApi({
  reducerPath: 'meteringPointActualIncomeIndicatorsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/metering-point-actual-income-indicators`,
  }),
  tagTypes: ['MeteringPointActualIncomeIndicators'],
  endpoints: builder => ({
    queryIndicators: builder.query<
      GasMeteringNodeActualIncomeIndicator[],
      MeteringPointActualIncomeIndicatorQueryModel
    >({
      query: data => ({
        url: '/query',
        method: 'POST',
        data,
      }),
      providesTags: () => ['MeteringPointActualIncomeIndicators'],
    }),
    calculateResult: builder.mutation<
      MeteringPointActualIncomeCalculationResultModel,
      MeteringPointActualIncomeCalculationModel
    >({
      query: data => ({
        url: '/calculate-result',
        method: 'POST',
        data,
      }),
    }),
    createIndicator: builder.mutation<
      MeteringDeviceActualIncomeIndicator,
      MeteringPointActualIncomeCreateModel
    >({
      query: data => ({
        url: '/',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['MeteringPointActualIncomeIndicators'],
    }),
    updateIndicator: builder.mutation<
      MeteringDeviceActualIncomeIndicator,
      MeteringPointActualIncomeUpdateModel
    >({
      query: ({ id, ...data }) => ({
        url: `/${id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['MeteringPointActualIncomeIndicators'],
    }),
  }),
});

export const {
  useQueryIndicatorsQuery,
  useCalculateResultMutation,
  useCreateIndicatorMutation,
  useUpdateIndicatorMutation,
} = meteringPointActualIncomeIndicatorsApi;
