import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import {
  ActualExpensesCounterparties,
  ActualExpensesCounterpartiesFilters,
  ActualExpensesCounterpartiesValidateModel,
  ActualExpensesMeteringPointDailyExpensesResponse,
  ActualExpensesMeteringPointResponse,
  ActualExpensesMeteringPointsDailyExpensesByDateParams,
  ActualExpensesMeteringPointsDocuments,
  ActualExpensesMeteringPointsFilter,
  ActualExpensesMeteringPointsInfo,
  ActualExpensesMeteringPointsInfoFilter,
  ActualExpensesMeteringPointsParams,
  CleanActualExpensesMeteringPointDailyExpensesPayload,
  DailyExpensesByDate,
  MeteringPointsDailyExpensesFillPayload,
  SaveActualExpensesMeteringPointDailyExpensesByDatePayload,
  UpdateActualExpensesMeteringPointDailyExpensesByDatePayload,
  VerifyDocumentDailyExpensesResponse,
} from './types';

export const actualExpensesApi = createApi({
  reducerPath: 'actualExpensesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: Config.VITE_API_URL + '/counterparty-actual-expenses',
  }),
  tagTypes: [
    'ActualExpensesCounterparties',
    'ActualExpensesMeteringPoints',
    'ActualExpensesMeteringPointsInfo',
    'ActualExpensesMeteringPointsDocuments',
    'ActualExpensesMeteringPointDailyExpenses',
  ],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getActualExpensesCounterparties: builder.query<
      ActualExpensesCounterparties[],
      ActualExpensesCounterpartiesFilters | void
    >({
      query: (data = {}) => ({
        url: '/counterparties/query',
        method: 'POST',
        data,
      }),
      providesTags: () => ['ActualExpensesCounterparties'],
    }),
    validateActualExpenses: builder.mutation<
      void,
      ActualExpensesCounterpartiesValidateModel
    >({
      query: data => ({
        url: '/validate',
        method: 'POST',
        data,
      }),
    }),
    getActualExpensesMeteringPoints: builder.query<
      ActualExpensesMeteringPointResponse,
      ActualExpensesMeteringPointsFilter | void
    >({
      query: (data = {}) => ({
        url: '/metering-points/query',
        method: 'POST',
        data,
      }),
      providesTags: () => ['ActualExpensesMeteringPoints'],
    }),
    getActualExpensesMeteringPointsInfo: builder.query<
      ActualExpensesMeteringPointsInfo,
      ActualExpensesMeteringPointsInfoFilter
    >({
      query: params => ({
        url: '/metering-points/info',
        method: 'GET',
        params,
      }),
      providesTags: () => ['ActualExpensesMeteringPointsInfo'],
    }),
    getActualExpensesMeteringPointsDocuments: builder.query<
      ActualExpensesMeteringPointsDocuments,
      ActualExpensesMeteringPointsParams
    >({
      query: params => ({
        url: '/documents',
        method: 'GET',
        params,
      }),
      providesTags: () => ['ActualExpensesMeteringPointsDocuments'],
    }),
    getActualExpensesMeteringPointDailyExpenses: builder.query<
      ActualExpensesMeteringPointDailyExpensesResponse,
      ActualExpensesMeteringPointsParams
    >({
      query: params => ({
        url: '/metering-points/daily-expenses/query',
        method: 'GET',
        params,
      }),
      providesTags: () => ['ActualExpensesMeteringPointDailyExpenses'],
    }),
    getActualExpensesMeteringPointDailyExpensesByDate: builder.query<
      DailyExpensesByDate,
      ActualExpensesMeteringPointsDailyExpensesByDateParams
    >({
      query: params => ({
        url: '/metering-points/daily-expenses',
        method: 'GET',
        params,
      }),
    }),
    saveActualExpensesMeteringPointDailyExpensesByDate: builder.mutation<
      string,
      SaveActualExpensesMeteringPointDailyExpensesByDatePayload
    >({
      query: data => ({
        url: '/metering-points/daily-expenses',
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'ActualExpensesMeteringPointDailyExpenses',
        'ActualExpensesMeteringPointsDocuments',
      ],
    }),
    updateActualExpensesMeteringPointDailyExpensesByDate: builder.mutation<
      string,
      UpdateActualExpensesMeteringPointDailyExpensesByDatePayload
    >({
      query: ({ id, consumption }) => ({
        url: `/metering-points/daily-expenses/${id}`,
        method: 'PUT',
        data: { consumption },
      }),
      invalidatesTags: [
        'ActualExpensesMeteringPointDailyExpenses',
        'ActualExpensesMeteringPointsDocuments',
      ],
    }),
    checkFillMeteringPointsDailyExpenses: builder.query<
      boolean,
      MeteringPointsDailyExpensesFillPayload
    >({
      query: params => ({
        url: `/metering-points/daily-expenses/fill-accessible`,
        params,
      }),
    }),
    validateMeteringPointsDailyExpensesFillAccessible: builder.mutation<
      string,
      MeteringPointsDailyExpensesFillPayload
    >({
      query: data => ({
        url: `/metering-points/daily-expenses/validate`,
        method: 'POST',
        data,
      }),
    }),
    fillMeteringPointsDailyExpenses: builder.mutation<
      string,
      MeteringPointsDailyExpensesFillPayload
    >({
      query: data => ({
        url: `/metering-points/daily-expenses/fill-by-operational-expenses`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'ActualExpensesMeteringPointDailyExpenses',
        'ActualExpensesMeteringPointsDocuments',
      ],
    }),
    cleanActualExpensesMeteringPointDailyExpenses: builder.mutation<
      string,
      CleanActualExpensesMeteringPointDailyExpensesPayload
    >({
      query: params => ({
        url: `/metering-points/daily-expenses/clean`,
        method: 'POST',
        params,
      }),
      invalidatesTags: [
        'ActualExpensesMeteringPointDailyExpenses',
        'ActualExpensesMeteringPointsDocuments',
      ],
    }),
    verifyActualExpensesMeteringPointDailyExpenses: builder.query<
      VerifyDocumentDailyExpensesResponse,
      { id: string }
    >({
      query: ({ id }) => ({
        url: `/documents/${id}/verify`,
      }),
    }),
    confirmActualExpensesMeteringPointDailyExpenses: builder.mutation<
      VerifyDocumentDailyExpensesResponse,
      { id: string; ignoreImbalance: boolean }
    >({
      query: ({ id, ignoreImbalance }) => ({
        url: `/documents/${id}/confirm`,
        method: 'PUT',
        params: { ignoreImbalance },
      }),
      invalidatesTags: ['ActualExpensesMeteringPointsDocuments'],
    }),
  }),
});

export const {
  useGetActualExpensesCounterpartiesQuery,
  useValidateActualExpensesMutation,
  useGetActualExpensesMeteringPointsQuery,
  useGetActualExpensesMeteringPointsInfoQuery,
  useGetActualExpensesMeteringPointsDocumentsQuery,
  useGetActualExpensesMeteringPointDailyExpensesQuery,
  useGetActualExpensesMeteringPointDailyExpensesByDateQuery,
  useSaveActualExpensesMeteringPointDailyExpensesByDateMutation,
  useUpdateActualExpensesMeteringPointDailyExpensesByDateMutation,
  useValidateMeteringPointsDailyExpensesFillAccessibleMutation,
  useCheckFillMeteringPointsDailyExpensesQuery,
  useFillMeteringPointsDailyExpensesMutation,
  useCleanActualExpensesMeteringPointDailyExpensesMutation,
  useVerifyActualExpensesMeteringPointDailyExpensesQuery,
  useLazyVerifyActualExpensesMeteringPointDailyExpensesQuery,
  useConfirmActualExpensesMeteringPointDailyExpensesMutation,
} = actualExpensesApi;
