import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import {
  MainOrganization,
  MainOrganizationRequestBody,
} from '@/features/mainOrganization/types';
import { extendWithRoles } from '@/features/roles/utils';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

const api = createApi({
  reducerPath: 'mainOrganizationApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/main-organizations`,
  }),
  tagTypes: ['MainOrganization', 'MainOrganizations'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getMainOrganizations: builder.query<MainOrganization[], void>({
      query: () => ({
        url: '/query',
        method: 'POST',
        data: {},
      }),
      providesTags: () => ['MainOrganization', 'MainOrganizations'],
    }),
    getMainOrganization: builder.query<MainOrganization[], void>({
      query: () => ({
        url: '/',
      }),
      providesTags: () => ['MainOrganization', 'MainOrganizations'],
    }),
    getMainOrganizationById: builder.query<MainOrganization, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: () => ['MainOrganization'],
    }),
    createMainOrganization: builder.mutation<
      MainOrganization,
      MainOrganizationRequestBody
    >({
      query: data => ({
        url: ``,
        method: 'POST',
        data,
      }),
      invalidatesTags: result => [
        'MainOrganization',
        { type: 'MainOrganization', id: result.id },
      ],
    }),
    updateMainOrganizationById: builder.mutation<
      MainOrganization,
      MainOrganizationRequestBody
    >({
      query: data => ({
        url: `/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: result => [
        'MainOrganization',
        { type: 'MainOrganization', id: result.id },
      ],
    }),
  }),
});

export const mainOrganizationApi = extendWithRoles(api as any);

export const {
  useGetMainOrganizationByIdQuery,
  useGetMainOrganizationsQuery,
  useGetMainOrganizationQuery,
  useCreateMainOrganizationMutation,
  useUpdateMainOrganizationByIdMutation,
} = api;
