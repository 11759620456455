import { PropsWithChildren, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/joy/IconButton';
import Stack, { StackProps } from '@mui/joy/Stack';
import SvgIcon from '@mui/joy/SvgIcon';
import Typography from '@mui/joy/Typography';

import AlertIcon from '@assets/alert.svg?react';
import InfoIcon from '@assets/info.svg?react';

export enum AlertMessageType {
  Danger = 'Danger',
  Info = 'Info',
  Error = 'Error',
  Warning = 'Warning',
}

const configHashMap = {
  [AlertMessageType.Danger]: {
    icon: <AlertIcon />,
    styles: {
      bgcolor: 'danger.100',
      color: 'danger.700',
    },
  },
  [AlertMessageType.Error]: {
    icon: (
      <SvgIcon
        component={InfoIcon}
        sx={{
          path: { fill: theme => theme.palette.danger[700] },
        }}
      />
    ),
    styles: {
      bgcolor: 'danger.100',
      color: 'danger.700',
    },
  },
  [AlertMessageType.Info]: {
    icon: (
      <SvgIcon
        component={InfoIcon}
        sx={{
          path: { fill: theme => theme.palette.neutral[700] },
        }}
      />
    ),
    styles: {
      bgcolor: 'neutral.100',
      color: 'neutral.700',
    },
  },
  [AlertMessageType.Warning]: {
    icon: (
      <SvgIcon
        component={InfoIcon}
        sx={{
          path: { fill: theme => theme.palette.warning[700] },
        }}
      />
    ),
    styles: {
      bgcolor: 'warning.100',
      color: 'warning.700',
    },
  },
};

interface AlertMessageProps extends PropsWithChildren {
  type?: AlertMessageType;
  isSelfClosed?: boolean;
  sx?: StackProps['sx'];
}

const AlertMessage = ({
  type = AlertMessageType.Danger,
  children,
  isSelfClosed,
  sx,
}: AlertMessageProps) => {
  const [isOpen, setOpen] = useState(true);

  if (!isOpen) {
    return null;
  }

  return (
    <Stack
      direction='row'
      sx={{
        padding: theme => theme.spacing(1.5),
        borderRadius: theme => theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'space-between',
        svg: {
          flexShrink: 0,
          width: 24,
          height: 24,
        },
        'svg[data-testid="CloseIcon"]': { width: 20, height: 20 },
        ...configHashMap[type].styles,
        ...sx,
        gap: theme => theme.spacing(1.25),
      }}
      level='title-sm'
      component={Typography}
    >
      <Stack component='span' alignSelf='flex-start'>
        {configHashMap[type].icon}
      </Stack>
      <Typography flexGrow={1} sx={{ wordBreak: 'break-word' }}>
        {children}
      </Typography>
      {isSelfClosed && (
        <IconButton
          aria-label='close'
          onClick={() => setOpen(false)}
          sx={{
            p: 0.5,
            minWidth: 'auto',
            minHeight: 'auto',
            alignSelf: 'flex-start',
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Stack>
  );
};

export default AlertMessage;
