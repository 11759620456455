import { every, isArray, isObject } from 'lodash-es';

import { LogicalOperators } from '@/utils/buildFilterQuery/constants';
import {
  GridifySerialize,
  GridifyStrategy,
} from '@/utils/buildFilterQuery/types';

export const isObjectLikeFilter: GridifyStrategy = filterItem =>
  isArray(filterItem) && every(filterItem, isObject);

export const serializeObjectLike: GridifySerialize = (filterId, filterItem) =>
  `(${filterItem.map(item => `${filterId}=${item.value}`).join(LogicalOperators.OR)})`;
