import { ComponentType } from 'react';

import SortAscIcon from '@/assets/sort-asc.svg?react';

import { styled } from '@mui/joy';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { DataGridProps } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { DataGridProProps } from '@mui/x-data-grid-pro';

const getStyledGrid = <
  T extends ComponentType<DataGridProps | DataGridProProps>,
>(
  Component: T,
) =>
  styled(Component)<{
    noLineStyles?: boolean;
    autoRowHeight?: boolean;
    footer?: boolean;
  }>(({ theme, noLineStyles, autoRowHeight, footer }) => ({
    border: 0,
    '&.MuiDataGrid-root--noToolbar .MuiDataGrid-columnHeader': {
      background: theme.palette.neutral[50],
      '&:nth-of-type(2)': {
        borderTopLeftRadius: theme.spacing(1),
      },
      '&.MuiDataGrid-columnHeader--last': {
        borderTopRightRadius: theme.spacing(1),
      },
    },
    '.MuiDataGrid-columnHeader:focus': {
      outline: 0,
    },
    '.MuiDataGrid-columnHeaderTitleContainer': {
      display: 'flex',
      gap: theme.spacing(1),
    },
    '.MuiDataGrid-columnHeaderTitleContainerContent ': {
      fontSize: 16,
      color: theme.palette.neutral[700],
    },
    '.MuiDataGrid-cell': {
      border: 0,
      '&:nth-of-type(2)': {
        borderRadius: `${theme.spacing(1)} 0 0 ${theme.spacing(1)}`,
      },
      '&:last-of-type': {
        borderRadius: `0 ${theme.spacing(1)} ${theme.spacing(1)} 0`,
      },
      '&:focus': {
        outline: 0,
      },
      '&.MuiDataGrid-cellSkeleton': {
        '&:first-of-type': {
          borderRadius: `${theme.spacing(1)} 0 0 ${theme.spacing(1)}`,
        },
        '&:nth-of-type(2)': {
          borderRadius: 0,
        },
      },
    },
    '.MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '.MuiDataGrid-row:nth-of-type(even) .MuiDataGrid-cell': !noLineStyles && {
      background: theme.palette.neutral[100],
    },
    '.MuiDataGrid-row:not(.MuiDataGrid-rowSkeleton):hover': {
      background: 'none',
      '.MuiDataGrid-cell': {
        background: noLineStyles ? 'none' : theme.palette.neutral[200],
      },
    },
    '.MuiDataGrid-filler div': {
      borderTop: 'none',
    },
    '.MuiDataGrid-footerContainer': {
      borderRadius: theme.spacing(1),
      background: theme.palette.neutral[200],
      minHeight: '40px',
    },
    '.MuiDataGrid-row .MuiDataGrid-cell': autoRowHeight && {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    '.MuiDataGrid-scrollbarFiller': {
      border: 'none !important',
    },
    '.MuiDataGrid-main ': {
      flexGrow: footer ? 0 : 1,
    },
    [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
      outline: 'none',
    },
  }));

export const StyledDataGrid = getStyledGrid(DataGrid);
export const StyledDataGridPro = getStyledGrid(DataGridPro);

export const SvgPlaceholder = styled(SortAscIcon)({
  path: {
    fill: '#9499AA',
  },
});

export const GroupedCellStack = styled(Stack)({
  flex: 1,
  marginLeft: '-8px',
  marginRight: '-8px',
  '.MuiDataGrid-main': {
    flexGrow: 0,
  },
  '.MuiDataGrid-columnHeader': {
    borderBottom: 'none !important',
  },
  '.MuiDataGrid-row .MuiDataGrid-cell:nth-of-type(2)': {
    paddingLeft: '30px',
  },
  '& > .MuiStack-root': {
    height: 'auto',
  },
});

export const GroupedCellCaption = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1),
  background: theme.palette.neutral[100],
  borderRadius: theme.spacing(1),
  cursor: 'pointer',
  color: theme.palette.neutral[700],
  fontWeight: 400,
  fontSize: theme.fontSize.sm,
}));
