import { useCallback, useRef, useState } from 'react';

import { useGetOrganizationsInfiniteQuery } from '@/features/organizations/organizationsApi';
import { buildOrganizationsQueryParams } from '@/features/organizations/utils';
import useClickOutside from '@/hooks/useClickOutside';
import useConfirmDialog from '@/hooks/useConfirmPrompt';
import { useOrganizationContext } from '@/providers/OrganizationProvider';

import ChevronLeft from '@mui/icons-material/ChevronLeft';
import Button from '@mui/joy/Button';
import Chip from '@mui/joy/Chip';
import Stack from '@mui/joy/Stack';
import SvgIcon from '@mui/joy/SvgIcon';
import Typography from '@mui/joy/Typography';

import { useGetOrganizationRedirectHref } from '@components/Header/components/UserPanel/hooks/useGetOrganizationRedirectHref';
import {
  OrgItem,
  Paper,
  Wrapper,
} from '@components/Header/components/UserPanel/styled';
import { cleanAllStorages } from '@components/Header/components/UserPanel/utils/cleanAllStorages';

import Org from '@assets/Org.svg?react';

const OrganizationsDropdown = () => {
  const ref = useRef(null);
  const { organization, checkMainOffice } = useOrganizationContext();
  const [isOpen, setIsOpen] = useState(false);

  const { confirm, ConfirmDialog } = useConfirmDialog();

  const { data = [] } = useGetOrganizationsInfiniteQuery(
    buildOrganizationsQueryParams(),
    {
      infiniteQueryOptions: { pageSize: 100 },
    },
  );

  useClickOutside(ref, () => setIsOpen(false));

  const { getOrganizationRedirectHref } = useGetOrganizationRedirectHref();

  const changeOrganization = useCallback(
    (id: string) => {
      location.href = getOrganizationRedirectHref(id);
    },
    [getOrganizationRedirectHref],
  );

  const handleContextOrganizationChange = (id: string) => () => {
    confirm(
      () => {
        setIsOpen(false);

        cleanAllStorages();

        changeOrganization(id);
      },
      {
        submit: 'Продовжити',
        cancel: 'Скасувати',
      },
    );
  };

  return (
    <>
      <Wrapper ref={ref}>
        <Button
          variant='plain'
          color='neutral'
          sx={{ cursor: 'pointer', border: 0 }}
          startDecorator={<Org style={{ width: 24, height: 24 }} />}
          endDecorator={
            <ChevronLeft
              sx={{
                width: 20,
                height: 20,
                rotate: isOpen ? '90deg' : '-90deg',
              }}
            />
          }
          onClick={() => setIsOpen(v => !v)}
        >
          {organization?.name}
        </Button>
        {isOpen && (
          <Paper gap={2}>
            <Stack gap={1}>
              <Typography
                component='div'
                fontWeight={600}
                level='body-xs'
                textColor='neutral.800'
              >
                Поточна організація
              </Typography>

              <Stack
                sx={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 1,
                  bgcolor: 'neutral.100',
                  borderRadius: '8px',
                  p: 2,
                }}
              >
                <SvgIcon
                  inheritViewBox
                  component={Org}
                  sx={{
                    width: 20,
                    height: 20,
                    path: { fill: theme => theme.palette.primary[700] },
                  }}
                />
                <Typography
                  level='title-sm'
                  noWrap
                  textColor='primary.700'
                  lineHeight='24px'
                >
                  {organization?.name}
                </Typography>
                {checkMainOffice(organization) && (
                  <Chip size='sm' variant='solid' color='primary'>
                    Головний офіс
                  </Chip>
                )}
              </Stack>
            </Stack>

            {data.length > 1 && (
              <Stack>
                <Typography
                  component='div'
                  fontWeight={600}
                  level='body-xs'
                  textColor='neutral.800'
                  // TODO hide temporarily
                  // endDecorator={<Chip color='neutral'>{data.length}</Chip>}
                  sx={{ mb: 1 }}
                >
                  Мої організації
                </Typography>
                <Stack gap={0.5}>
                  {data
                    .filter(el => el.id !== organization?.id)
                    .map(el => (
                      <OrgItem
                        as='div'
                        key={el.id}
                        variant='plain'
                        color='neutral'
                        onClick={handleContextOrganizationChange(el.id)}
                      >
                        <SvgIcon
                          inheritViewBox
                          component={Org}
                          sx={{
                            path: { fill: theme => theme.palette.neutral[400] },
                          }}
                        />
                        <Typography
                          level='body-sm'
                          textColor='neutral.700'
                          sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {el.name}{' '}
                        </Typography>
                        {checkMainOffice(el) && (
                          <Chip size='sm' variant='solid' color='primary'>
                            Головний офіс
                          </Chip>
                        )}
                      </OrgItem>
                    ))}
                </Stack>
              </Stack>
            )}
          </Paper>
        )}
      </Wrapper>
      <ConfirmDialog
        title={`Вийти з ${organization?.name}?`}
        subtitle='Зміна організації призведе до втрати незбережених даних'
      />
    </>
  );
};

export default OrganizationsDropdown;
