import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { ReferenceBook } from '@/features/organizations/types';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

export const projectDocumentationAvailabilitiesApi = createApi({
  reducerPath: 'projectDocumentationAvailabilitiesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/project-documentation-availabilities`,
  }),
  tagTypes: ['ProjectDocumentationAvailability'],
  endpoints: builder => ({
    getProjectDocumentationAvailabilities: builder.query<ReferenceBook[], void>(
      {
        query: () => ({
          url: '',
        }),
        providesTags: ['ProjectDocumentationAvailability'],
      },
    ),
    getProjectDocumentationAvailability: builder.query<ReferenceBook, string>({
      query: id => ({
        url: `/${id}`,
      }),
    }),
    createProjectDocumentationAvailability: builder.mutation<
      void,
      ReferenceBook
    >({
      query: body => ({
        url: '',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ProjectDocumentationAvailability'],
    }),
    updateProjectDocumentationAvailability: builder.mutation<
      void,
      ReferenceBook
    >({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ProjectDocumentationAvailability'],
    }),
    deleteProjectDocumentationAvailability: builder.mutation<void, string>({
      query: id => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ProjectDocumentationAvailability'],
    }),
  }),
});

export const {
  useGetProjectDocumentationAvailabilitiesQuery,
  useGetProjectDocumentationAvailabilityQuery,
  useCreateProjectDocumentationAvailabilityMutation,
  useUpdateProjectDocumentationAvailabilityMutation,
  useDeleteProjectDocumentationAvailabilityMutation,
} = projectDocumentationAvailabilitiesApi;
