import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import {
  ConsumerInput,
  FormNine,
  FormNineFilters,
} from '@/features/formNine/types';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

export const formNineApi = createApi({
  reducerPath: 'formNineApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/consumers-without-nomination`,
  }),
  tagTypes: ['FormNines', 'FormNine'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getFormNines: builder.query<FormNine[], FormNineFilters | void>({
      query: filters => ({
        url: `/query`,
        method: 'POST',
        data: filters || {},
      }),
      providesTags: () => ['FormNines'],
    }),
    getFormNineById: builder.query<FormNine, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: result => [{ type: 'FormNine', id: result.id }],
    }),
    createFormNine: builder.mutation<string, FormData>({
      query: data => ({
        url: ``,
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data,
      }),
      invalidatesTags: id => ['FormNines', { type: 'FormNine', id }],
    }),
    updateFormNineById: builder.mutation<string, FormData>({
      query: data => ({
        url: `/${data.get('id')}`,
        method: 'PUT',
        headers: { 'Content-Type': 'multipart/form-data' },
        data,
      }),
      invalidatesTags: id => ['FormNines', { type: 'FormNine', id }],
    }),
    deleteFormNineById: builder.mutation<FormNine, string>({
      query: id => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: result => [
        'FormNines',
        { type: 'FormNine', id: result.id },
      ],
    }),
    deleteAllErrorsFormNineById: builder.mutation<FormNine, string>({
      query: id => ({
        url: `/${id}/all-errors`,
        method: 'DELETE',
      }),
      invalidatesTags: result => [
        'FormNines',
        { type: 'FormNine', id: result.id },
      ],
    }),
    approveFormNine: builder.mutation<FormNine, string>({
      query: id => ({
        url: `/${id}/approve`,
        method: 'PUT',
      }),
      invalidatesTags: result => [{ type: 'FormNine', id: result.id }],
    }),
    updateFormNineRecords: builder.mutation<FormNine, ConsumerInput>({
      query: data => ({
        url: `/records`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: result => [{ type: 'FormNine', id: result.id }],
    }),
    deleteFormNineRecords: builder.mutation<FormNine, string>({
      query: id => ({
        url: `/records`,
        method: 'DELETE',
        data: { records: [id] },
      }),
      invalidatesTags: result => [{ type: 'FormNine', id: result.id }],
    }),
  }),
});

export const {
  useGetFormNinesQuery,
  useGetFormNineByIdQuery,
  useCreateFormNineMutation,
  useUpdateFormNineByIdMutation,
  useApproveFormNineMutation,
  useUpdateFormNineRecordsMutation,
  useDeleteFormNineRecordsMutation,
  useDeleteAllErrorsFormNineByIdMutation,
} = formNineApi;
