import { isArray } from 'lodash-es';

import { LogicalOperators } from '@/utils/buildFilterQuery/constants';
import {
  GridifySerialize,
  GridifyStrategy,
} from '@/utils/buildFilterQuery/types';

export const isArrayFilter: GridifyStrategy = filterItem =>
  isArray(filterItem) && filterItem.some(Boolean);

export const serializeArray: GridifySerialize = (filterId, filterItem) =>
  `(${filterItem.map(v => `${filterId}=${v}`).join(LogicalOperators.OR)})`;
