import { FC, useCallback, useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import ErrorRounded from '@mui/icons-material/ErrorRounded';
import InfoRounded from '@mui/icons-material/InfoRounded';
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { IconButton } from '@mui/joy';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import Snackbar from '@mui/joy/Snackbar';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { DefaultColorPalette } from '@mui/joy/styles/types/colorSystem';

export type NotificationType = DefaultColorPalette;

const NotificationIcons: Record<NotificationType, typeof ErrorRounded> = {
  danger: InfoRounded,
  success: PlaylistAddCheckCircleRoundedIcon,
  warning: WarningAmberIcon,
  neutral: InfoRounded,
  primary: InfoRounded,
};

type NotificationProps = {
  autoHideDuration: number;
  type: NotificationType;
  message: string | string[];
  show?: boolean;
  notificationTitle?: string;
  id?: number;
  icon?: any;
  button?: any;
  onClose: (id: number) => void;
};

const renderMessages = (messages: string[]) => (
  <List
    sx={{
      pl: '32px',
      fontSize: '14px',
      padding: 0,
    }}
  >
    {messages.map((message, i) => (
      <ListItem key={i} sx={{ padding: 0 }}>
        {message}
      </ListItem>
    ))}
  </List>
);

export const Notification: FC<NotificationProps> = ({
  autoHideDuration = 30000,
  type,
  message,
  notificationTitle = 'Зверніть увагу!',
  id,
  icon,
  button = null,
  onClose,
}) => {
  const [open, setOpen] = useState(true);
  const Icon = icon || NotificationIcons[type];

  const closeAction = useCallback(() => {
    setOpen(false);
    onClose(id);
  }, [id, onClose]);

  useEffect(() => {
    setTimeout(() => {
      closeAction();
    }, autoHideDuration);
  }, [autoHideDuration, closeAction]);

  return (
    <Snackbar
      autoHideDuration={autoHideDuration}
      variant='soft'
      color={type}
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      sx={{
        flexDirection: 'row',
        alignItems: 'flex-start',
        gap: 1,
        p: 1.5,
        pr: 0.75,
        position: 'relative',
        '&.MuiSnackbar-colorPrimary': {
          bgcolor: 'primary.50',
          '.MuiIconButton-colorPrimary': {
            bgcolor: 'primary.50',
            '&:hover': {
              bgcolor: 'primary.100',
            },
          },
        },
      }}
    >
      <Icon />
      <Stack sx={{ flex: 1, position: 'relative', top: '-2px' }}>
        <Typography
          level='body-md'
          fontWeight='bold'
          sx={{ color: 'inherit', whiteSpace: 'nowrap' }}
        >
          {notificationTitle}
        </Typography>
        <Stack
          sx={{
            fontSize: '14px',
          }}
        >
          {Array.isArray(message) ? renderMessages(message) : message}
        </Stack>
      </Stack>
      {button}
      <IconButton
        onClick={() => closeAction()}
        size='sm'
        variant='soft'
        color={type}
        sx={{ position: 'relative', top: '-6px', marginLeft: 'auto' }}
      >
        <CloseIcon />
      </IconButton>
    </Snackbar>
  );
};
