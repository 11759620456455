import { createContext, useCallback, useState } from 'react';

import Stack from '@mui/joy/Stack';

import { Notification, NotificationType } from './Notification';

type NotificationPushArgs = {
  message: string | string[];
  title?: string;
  type: NotificationType;
  icon?: any;
  button?: any;
  options?: Partial<{
    duration: number;
    id?: number;
  }>;
};

type NotificationContextType = {
  push: (args: NotificationPushArgs) => void;
};

export const NotificationContext = createContext<NotificationContextType>({
  push: () => {},
});

type NotificationStore = {
  message: string | string[];
  title?: string;
  type: NotificationType;
  duration?: number;
  id: number;
  icon?: any;
  button?: any;
};

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotification] = useState<NotificationStore[]>([]);

  const push = useCallback(
    ({ message, title, type, options, icon, button }: NotificationPushArgs) => {
      setNotification(prev => {
        return [
          ...prev,
          {
            id: options?.id || Date.now(),
            icon,
            button,
            message,
            title,
            type,
            duration: options?.duration,
          },
        ];
      });
    },
    [],
  );

  const removeNotification = (id: number) => {
    setNotification(prev => prev.filter(nt => nt.id !== id));
  };

  return (
    <NotificationContext.Provider value={{ push }}>
      {children}
      <Stack
        spacing={1}
        sx={{
          position: 'fixed',
          bottom: 12,
          right: 12,
          maxWidth: '400px',
          zIndex: 9999,
        }}
      >
        {notifications.map(nt => (
          <Notification
            key={nt.id}
            id={nt.id}
            icon={nt.icon}
            button={nt.button}
            message={nt.message}
            notificationTitle={nt.title}
            type={nt.type}
            autoHideDuration={nt.duration}
            onClose={removeNotification}
          />
        ))}
      </Stack>
    </NotificationContext.Provider>
  );
};
