import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@/features/constants';

// TODO only for mocks, remove in future
export const generateId = state =>
  `ТИС${(state.data.length + 1).toString().padStart(6, '0')}`;

export const infiniteQueryOptions = {
  initialPageParam: { page: DEFAULT_PAGE, pageSize: DEFAULT_PAGE_SIZE },
  getNextPageParam: (lastPage, _, lastPageParam) => {
    if (!lastPage.length || lastPage.length < lastPageParam.pageSize) {
      return;
    }
    return {
      page: lastPageParam.page + 1,
      pageSize: lastPageParam.pageSize,
    };
  },
};

export const normalizeInfiniteQueryData =
  (hook: any) => (params?: any, options?: any) => {
    const payload = hook(params, options);

    return { ...payload, data: (payload.data?.pages || []).flat() };
  };
