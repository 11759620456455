import { FC } from 'react';

import { RequirePermission } from '@/providers/PermissionProvider/components/RequirePermission';
import { usePermissionsContext } from '@/providers/PermissionProvider/hooks/usePermissionsContext';

import Button from '@mui/joy/Button';

import { DialogActions } from '@components/ui/Dialog/styled';
import { DialogType } from '@components/ui/Dialog/types';

import { UserPermissionType } from '../Permissions/types';

type FormFooterProps = {
  isLoading?: boolean;
  isPreviewMode?: boolean;
  isCreateMode?: boolean;
  isPreviewModeWithoutUpdate?: boolean;
  setFormType?: (type: DialogType) => void;
  handleCancel?: () => void;
  handleClose?: () => void;
  submitForm: () => void;
  textSubmit?: string;
  isEditButtonDisabled?: boolean;
  editPermission?: UserPermissionType;
  permission?: UserPermissionType;
};
export const FormFooter: FC<FormFooterProps> = ({
  setFormType,
  handleCancel,
  isLoading,
  submitForm,
  isPreviewMode,
  isCreateMode,
  isPreviewModeWithoutUpdate,
  handleClose,
  textSubmit,
  isEditButtonDisabled,
  editPermission,
  permission,
}) => {
  const textButton = textSubmit
    ? textSubmit
    : isCreateMode
      ? 'Створити'
      : 'Зберегти';

  const { hasPermission } = usePermissionsContext();

  const hasEditPermission = hasPermission(editPermission);

  const getDialogActions = () => {
    if (isPreviewMode) {
      return (
        <RequirePermission skip={!editPermission} name={editPermission}>
          <Button
            size='sm'
            onClick={() => setFormType?.(DialogType.Edit)}
            disabled={
              isEditButtonDisabled || (editPermission && !hasEditPermission)
            }
          >
            Редагувати
          </Button>
        </RequirePermission>
      );
    }
    if (isPreviewModeWithoutUpdate) {
      return (
        <Button size='sm' onClick={handleClose}>
          Закрити
        </Button>
      );
    }
    return (
      <>
        {handleCancel && (
          <Button
            size='sm'
            variant='plain'
            onClick={handleCancel}
            disabled={isLoading}
          >
            Скасувати
          </Button>
        )}

        <RequirePermission name={permission} skip={!permission}>
          <Button size='sm' onClick={submitForm} disabled={isLoading}>
            {textButton}
          </Button>
        </RequirePermission>
      </>
    );
  };
  return <DialogActions>{getDialogActions()}</DialogActions>;
};
