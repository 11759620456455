import { useMemo } from 'react';

import { DialogType } from '@components/ui/Dialog/types';

import AddIcon from '@assets/add.svg?react';
import EditIcon from '@assets/edit-outlined.svg?react';
import HomeIcon from '@assets/home2-filled.svg?react';

import DialogTitleBasic, { DialogTitleBasicProps } from './DialogTitleBasic';

const titleHash: Record<string, string> = {
  [DialogType.Create]: 'Створення',
  [DialogType.Edit]: 'Редагування',
};

type DialogTitleCommonProps = DialogTitleBasicProps & {
  type?: DialogType | string;
  showSubtitle?: boolean;
  loading?: boolean;
};

const DialogTitle = ({
  icon = <HomeIcon />,
  type = DialogType.Create,
  title,
  subtitle,
  copy,
  showSubtitle = false,
  loading = false,
}: DialogTitleCommonProps) => {
  const iconHash = useMemo(
    () => ({
      [DialogType.Create]: <AddIcon />,
      [DialogType.Preview]: icon,
      [DialogType.Edit]: <EditIcon />,
    }),
    [icon],
  );

  const dialogSubtitle =
    type === DialogType.Preview
      ? subtitle
      : showSubtitle
        ? subtitle
        : titleHash[type];

  return (
    <DialogTitleBasic
      icon={iconHash[type] || icon}
      title={title}
      subtitle={dialogSubtitle}
      copy={type !== DialogType.Create ? copy : undefined}
      loading={loading}
    />
  );
};

export default DialogTitle;
